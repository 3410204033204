@include exports('components/checklists'){

	/***
	* Checklists:
	*/

	/**
	 *
	 *Basic checklist:
	 *
	 *`.checklist > label.checklist__label > input.checklist__input + .checklist__item`
	 *
	 *A checklist is almost a form component, but not quite. Semantically, we can't actually put a bunch of checkboxes inside a multiple
	 * select, so this is a styled div to mimic the select. Used in the trademark class browse list.
	 *
	 * 	@example
	 *		div.card
	 *			div.checklist
	 *				input#example-input-1.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-1") Apples
	 *				input#example-input-2.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-2") Oranges
	 *				input#example-input-3.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-3") Kittens
	 *				input#example-input-4.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-4") Puppies
	 *				input#example-input-5.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-5") Coke
	 *				input#example-input-6.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-6") Pepsi
	 *				input#example-input-7.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-7") Tile
	 *				input#example-input-8.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-8") Linoleum
	 *				input#example-input-9.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-9") Jason
	 *				input#example-input-10.checkradio__input(type="checkbox")
 	 *				label.checkradio.checkradio--checkbox(for="example-input-10") Freddy
	 *
	 */

	.checklist{
		position: relative;
		width: 100%;
		max-height: ms(12);
		padding: .533rem; // matches padding of a select list
		overflow-y: scroll;
		border: 1px solid gc(dl-grey);
		border-radius: $input-border-radius;
		cursor: default;

		input.checklist__input{
			margin-right: ms(-5);
			margin-bottom: 0;
		}

		label.checklist__label{

			.checklist__item{
				color: gc(dl-black);
			}
		}
	}
}
