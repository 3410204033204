@include exports('components/topbars'){
	 /***
	 * Topbar:
	 *
	 */

	/**
	 *
	 *Basic version 1 top navigation:
	 *
	 *`.topbar > ...`
	 *
	 *Topbar is used in v1 of the app.
	 *
	 * 	@example
	 *		div#topbar.topbar.topbar--example
	 *			div.topbar__menutoggle
	 *				svg.icon
	 *					use(xlink:href='images/sprites.svg#icon-burger--white')
	 *			div.topbar__logo
	 *				svg.icon.u-color__dl-grey
	 *					use(xlink:href='#icon-dragonlaw')
	 *			div.topbar__orgswitcher
	 *				span.dropdowntext(data-dropdown='topbar-org-dropdown') Cool org name
	 *				ul#topbar-org-dropdown.dropdown.dropdown--selectable(data-dropdown-content)
	 *					li.dropdown__item.is-active
	 *						|Cool org name
	 *						span.dropdown__tools settings
	 *
	 *					li.dropdown__item
	 *						|Another org
	 *						span.dropdown__tools settings
	 *
	 *					li.dropdown__item
	 *						|Some other org with a long name
	 *						span.dropdown__tools settings
	 *
	 *			div.topbar__usermenu
	 *					div.navitem(data-dropdown='topbar-user-dropdown')
	 *						div.dropdowntext
	 *							div.navitem__icon.navitem__icon--enclosed
	 *								svg.icon.icon--small
	 *									use(xlink:href='images/sprites.svg#icon-user--color')
	 *							span.navitem__text User name
	 *
	 *				ul#topbar-user-dropdown.dropdown.dropdown--bottomright(data-dropdown-content)
	 *					li.dropdown__item
	 *						|Personal profile
	 *					li.dropdown__item
	 *						|Sign out
	 *
	 */




	.topbar{
		display: flex;
		position: fixed;
		z-index: 20;
		left: 0;
		align-items: center;
		width: 100%;
		height: $topbar-height;
		background: $topbar-bgcolor;
		color: $topbar-textcolor;
		font-size: ms(0);
	}

	// set pos:rel just for the styledown example
	.topbar--example{
		position: relative;
	}


	.topbar__menutoggle{
		display: block;
		width: map-get($glb-sidebar-width, compact);
		height: $topbar-height;
		margin-left: -4px;
		padding: ms(1);
		float: left;
		transition: transform map-get($transition-duration, default) $ease-in-out-quad;
		text-align: center;

		@media #{$large-up}{
			display: none;
		}

		body.has-expanded-left-sidebar & {
			// margin-left: -8px;
			transform: rotate(90deg);
		}

		svg{
			pointer-events: none;
		}
	}

	.topbar__logo{
		display: none;
		width: map-get($glb-sidebar-width, compact);
		height: $topbar-height;
		margin: -3px 0 0 1px;
		padding: ms(-4);
		float: left;

		.icon{
			width: 100%;
		}

		@media #{$large-up}{
			display: block;
		}


	}

	.topbar__orgswitcher{
		flex: 1;
		margin-left: ms(-6);
		float: left;
		font-weight: bold;

		&:hover{
			color: gc(dl-cream);
		}

		.no-flexbox &{
			margin-top: 14px;
			margin-top: calc(#{ms(-2)} + 5px);
		}

		.topbar__orgname{
			max-width: ms(7);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@media #{$medium-up}{
				max-width: 100%;
				overflow: visible;
				text-overflow: clip;
				white-space: normal;
			}
		}
	}


	.topbar__usermenu{
		padding: 0 ms(2);
		float: right;

		.navitem{
			.no-flexbox &{
				margin-top: 5px;
			}


		}

		.navitem__text{
			display: none;

			@media #{$medium-up}{
				display: inline;
			}
		}
	}

	.topbar__misc{
		display: inline-block;
		margin-left: ms(0);
	}
}
