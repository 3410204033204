// These are styles for the doctype output temnplate not the doctype-output module,
// last time i checked, they were too small for their own file so were in doctype_block_form

.doctype-output__dropzone{
	margin: 0 0 ms(1);

	ol{
		margin: 0;
	}

	li{
		list-style: none;
	}
}
// Output Template blocks

.doctype-output-block{
	margin-bottom: ms(0);
	padding: 0 ms(0) ms(0);
	list-style: none;
	border: 1px solid gc(dl-grey);
	background: #fff;
	box-shadow: 0 1px 1px rgba(gc(dl-black), .1);
	cursor: drag;
	cursor: move;

	&.is-minimised{
		padding-bottom: 0;
	}

	&.is-minimised > ol{
		display: none;
	}

	&.is-missing{
		border: 1px solid gc(dl-ruby);
		box-shadow: 0 1px 1px rgba(gc(dl-ruby), .3);
		color: gc(dl-grey);
	}
}



//  metadata and actions at the bottom
.doctype-output-block__toolbar{
	@include trans(fast);
	margin-bottom: ms(-3);
	padding: ms(-3) 0;
	border-bottom: 1px solid gc(dl-grey);
	opacity: .2;
	color: gc(dl-grey);
	font-size: ms(0);

	.doctype-output-block:hover &{
		opacity: 1;
	}
}

.doctype-output-block__settings-menu__item{
	padding: ms(0);
	border-bottom: 1px solid gc(dl-grey);

	input{
		margin: 0;
	}
}

// Delete + settings icons + minimise
.doctype-output-block__minimise-button,
.doctype-output-block__settings-button,
.doctype-output-block__delete-button{
	@include icon-square(tiny);
	margin: 0 0 0 ms(0);
	float: right;
	cursor: pointer;
}

.doctype-output-block__minimise-button{
	@include transition(all, #{map-get($transition-duration, default)});
	.is-minimised &{
		@include transform(rotateX(180deg));
		margin-top: ms(-4);

	}
}

.doctype-output-block__delete-button{
	.icon-cross{
		vertical-align: bottom;
	}
}

.doctype-output-block__settings-button{
	.icon-cog{
		vertical-align: bottom;
	}
}
