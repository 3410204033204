@include exports('components/loadings'){
	/**
	 *
	 *Loading:
	 *
	 *`.loading > .loading__spinner + .loading__text`
	 *
	 *Small component that lays out the loading spinner and some accompanying text (usually the word "loading").
	 *
	 * 	@example
	 *		div.loading
	 *			.loading__spinner
	 *				.spinner
	 *			.loading__text Get a load of this
	 *
	 */
	.loading{
		padding: ms(1);
	}

	.loading--inline{
		display: inline-block;
		padding: 0;

		background: red; //@debug
	}

	.loading__spinner,
	.loading__text{
		vertical-align: middle;
	}

	.loading__spinner{
		display: none;

		html.cssanimations &{
			display: inline-block;
		}
	}


	.loading__text{
		display: inline-block;
		margin-left: ms(0);
		color: gc(dl-basalt);
		font-size: ms(2);
		user-select: none;
	}
}
