@include exports('components/progressbar'){
	/***
	* Progress Bars:
	*/

	/**
	 *
	 *Basic Progress bar:
	 *
	 *`.progressbar.is-50`
	 *
	 *Shows the percent complete or left of something in 1% increments
	 * use the is-[0-100] state to set the percentage.
	 *
	 * You can use ultility color classes to change the color of the bar
	 *
	 *
	 * 	@example
	 *		div.card
	 *			h4 Basic progressbar
	 *			div.progressbar.is-35
	 *				div.progressbar__bar
	 *		div.card
 	 *			h4 Progressbar color set with utility color class
	 *			div.progressbar.is-35
	 *				div.progressbar__bar.u-colorbg__dl-canary
	 *
	 */



	.progressbar{
		margin-bottom: ms(1);
		overflow: hidden;
		border-radius: map-get($border-radius, default);
		background: gc(dl-grey);
	}

	.progressbar__bar{
		content: '';
		display: block;
		width: 0;
		height: ms(-3);
		border-radius: 0 map-get($border-radius, default) map-get($border-radius, default) 0;
		// background: $glb-primary-color;
	}

	.progressbar{
		// set percentage stops @ 5% increments for the progress bar
		@for $i from 0 through 100 {

			&.is-#{$i} .progressbar__bar {
				width: $i * 1%;
				// @include linear-gradient(to top, gc(dl-grannysmith) $i * 1% , gc(dl-grey) $i * 1% , $fallback:  gc(dl-grey));
			}
		}
	}


	/**
	 *
	 *Gauges:
	 *
	 *`.progressbar.progressbar--gauge`
	 *
	 *A gauge changes color from red to green as the progress bar increases in fullness.
	 * It's useful for visually showing when something is running out.
	 *
	 * 	@example
	 *		div.card
	 *			h4 100 progress
	 *			div.progressbar.progressbar--gauge.is-100
	 * 				div.progressbar__bar
 	 *			h4 75 progress
 	 *			div.progressbar.progressbar--gauge.is-75
	 * 				div.progressbar__bar
	 *			h4 50 progress
	 *			div.progressbar.progressbar--gauge.is-50
	 * 				div.progressbar__bar
 	 *			h4 25 progress
 	 *			div.progressbar.progressbar--gauge.is-25
	 * 				div.progressbar__bar
  	 *			h4 10 progress
  	 *			div.progressbar.progressbar--gauge.is-10
	 * 				div.progressbar__bar
  	 *			h4 1 progress
  	 *			div.progressbar.progressbar--gauge.is-1
	 * 				div.progressbar__bar
	 *
	 */

	.progressbar--gauge{
		// set percentage stops @ 5% increments for the progress bar
		@for $i from 0 through 100 {
			&.is-#{$i} .progressbar__bar {
				@if($i >= 50){
					background: mix(gc(dl-grannysmith), gc(dl-canary), ($i - 50) * 2%);

				} @else{
					background: mix(gc(dl-canary), gc(dl-ruby), ($i) * 2%);
				}
			}
		}
	}


}
