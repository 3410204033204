// This file contains default varibales and any getter methods/mixins they have
// It also includes any non-default foundation settings as they relate to components


// 01 Global
// 02 Colors
// 03 Sizes
// 04 Type
// 05 Media Queries
// 06 Buttons
// 07 Forms
// 08 Transition speeds
// 09 Dropdowns
// 10 Tooltips
// 11 Topbar
// 12 Hopscotch tour bubbles
// 13 Foundation Grid
// 12 Cards column divider





//
// 01 Global
//


// Global foundation settings.
// We tend to NOT include default CSS unless we need to
$include-html-classes: false;
$include-print-styles: false;
$include-html-global-classes: true;

$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;



//
// 02 Colors
//

// Define global color variables
$glb-colors: (

	// Blacks, greys, whites - dark to light (roughly)
	dl-superblack				: #333,
	dl-black      				: #47473e,
	dl-darkgrey					: #4d4d4d,
	dl-mediumgrey				: #666,
	dl-charcoal   				: #80807f,
	dl-silver					: #999,
	dl-basalt      				: #a09f9f,
	dl-lightgrey				: #a6a2a1,
	dl-grey        				: #cccbcb,
	dl-slate					: #e5e5e5,
	dl-ash         				: #f2f2f0,
	dl-ivory					: #f4f4f4,
	dl-beige					: #f6f6f6,
	dl-cream       				: #f7f7f3,
	dl-white       				: #fff,

	// Colors
	dl-canary      				: #f7cf80,
	dl-grannysmith 				: #86bf40,
	dl-olive					: #a6b280,
	dl-sky         				: #82b5e2,
	dl-mariana					: #3374ba,
	dl-cobalt     				: #467fb0,
	dl-steel      				: #667389,
	dl-carmine					: #ec7684,
	dl-ruby       				: #de5b6b,
	dl-rust        				: #ba4c59,

	// New DL Branding v2.5
	dl-turquoise				: #6fc7cc,
	dl-teal						: #4cbac0,
	dl-jade						: #f3fcfb,
	dl-iron						: #aaa,
	dl-graphite					: #e1e1dd,
);


//get the color from the color map
@function glb-color($color){
	$c: map-get($glb-colors , $color);
	@if $c == null{
		@warn  $color "isn't a color map";
	}
	@return $c;
}

// glb-color takes too long to write
@function gc($color){
	@return glb-color($color);
}


$brand-colors: (
	ecyt-primary				: #0b296f,
	eiger-primary				: #eb8923,
	// hgm-primary				: #390,
	stephenson-harwood-primary	: #11ab92,
	googleRed   				: #db4437,
	xeroblue					: #08b6e1,
);

//get the color from the color map
@function brand-color($color){
	$c: map-get($brand-colors , $color);
	@if $c == null{
		@warn  $color "isn't a color map";
	}
	@return $c;
}

// glb-color takes too long to write
@function bc($color){
	@return brand-color($color);
}


// Dragon Law App primary and secondary colors

$glb-primary-color: glb-color(dl-teal);
$glb-primary-light-color: lighten($glb-primary-color, 35%);
$glb-secondary-color: glb-color(dl-iron);
$glb-tertiary-color: glb-color(dl-mediumgrey);

//
// $glb-primary-hover-color: glb-color(dl-turquoise);


// Foundation settings for color

// $primary-color:  glb-color(dl-charcoal);
// $secondary-color: glb-color(dl-grey);
// $header-font-color: glb-color(dl-black);
$anchor-font-color: $glb-primary-color;
$anchor-font-color-hover: lighten($glb-primary-color, 9%);
$body-font-color: glb-color(dl-black);

// Some global color variables so we don't have to set them in multiple components/modules

$glb-layout-color: glb-color(dl-ash);

//
// 03 Sizes
//

// Base sizes for modular scale
$glb-base-size: 17.5; // set on body ie. 1REM
$rem-base: $glb-base-size; // This is the Foundation default html and body font-size for the base rem value.
$glb-base-alt-size: 12;  // 12/15 == 0.8REM
$em-base: #{$glb-base-size}px;

// Modular scale sizes.
// See :
// http://modularscale.com/scale/?px1=15&px2=12&ra1=1.618&ra2=0
// for our scale

$ms-ratio: $golden;
$ms-base: 1rem .8rem;





//Icons
$icon-size: (
	micro   : ms(0),
	tiny    : ms(1),
	small   : ms(2),
	medium  : ms(4),
	large   : ms(6),
	jumbo   : ms(8),
	giant   : ms(9),
);


@mixin icon-square($alias:tiny){
	$size: map-get($icon-size, $alias);
	@if $size {
		width: $size;
		height: $size;
	} @else{
		width: $alias;
		height: $alias;
	}
}

// main content width
$max-leftnav-width: 280px;
// $max-content-width: 1024px;
$max-content-width: 100%; //Used in admin app
$max-left-content-width: 100%;
$side-nav-width: 280px;


// sidebar widths
$glb-sidebar-width: (
	tiny    	 : ms(4),
	compact 	 : ms(7) + .8rem,
	full    	 : ms(13) - .8rem,
	jumbo    	 : ms(14),
);

$glb-sidebar-search-height: map_get($icon-size, small) + ms(1);


//assumes a viewbox defined by the icon standard document
//viewBox="0 0 612 792" or something in that ballpark.

$icon-stroke: (
	hairline : 21,
	thin     : 31,
	default  : 41,
	thick    : 51,
);



// Corner radiuses
$corner-radius: (
	small: 3px,
	default: 5px,
	large: 1rem,
);

$border-radius: $corner-radius;
$global-radius: map-get($corner-radius, small);



// Inputs

$input-height: ms(5);



// Card padding and margins

$card-vert-padding: ms(2);
$card-horz-padding: ms(3);
$card-bottom-margin: ms(1);



// Slat padding

$slat-vert-padding: ms(1);
$slat-horz-padding: ms(0);



//
// 04 Type
//

$include-html-type-classes: true; // Include foundation type styles

// font family definitions
$family-arial: Arial, "Helvetica Neue", Helvetica, sans-serif;
$family-helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$family-mono: Monaco, MonoSpace;
$body-font-family: "Source Sans Pro", sans-serif;

// We use these to control header font styles
$header-font-family: $family-helvetica;
// $header-font-weight: $font-weight-normal !default;
// $header-font-style: normal !default;
$header-font-color: gc(dl-black);
// $header-line-height: 1.4 !default;
// $header-top-margin: .2rem !default;
// $header-bottom-margin: .5rem !default;
// $header-text-rendering: optimizeLegibility !default;

// We use these to control header font sizes
$h1-font-size: ms(4);
$h2-font-size: ms(3);
$h3-font-size: ms(2);
$h4-font-size: ms(1);
$h5-font-size: ms(0);
$h5-font-size: ms(-1);

// We use these to control header size reduction on small screens
// $h1-font-reduction: rem-calc(10) !default;
$h2-font-reduction: rem-calc(9);
// $h3-font-reduction: rem-calc(5) !default;
// $h4-font-reduction: rem-calc(5) !default;
// $h5-font-reduction: 0 !default;
// $h6-font-reduction: 0 !default;


// Paragraphs

// font-family: $paragraph-font-family;
// font-weight: $paragraph-font-weight;
// font-size: $paragraph-font-size;
// line-height: $paragraph-line-height;
$paragraph-margin-bottom: ms(1);
// text-rendering: $paragraph-text-rendering;


// We use these to style lists
// $list-font-family: $paragraph-font-family !default;
// $list-font-size: $paragraph-font-size !default;
// $list-line-height: $paragraph-line-height !default;
// $list-margin-bottom: $paragraph-margin-bottom !default;
// $list-style-position: outside !default;
$list-side-margin: ms(1);
// $list-ordered-side-margin: 1.4rem !default;
// $list-side-margin-no-bullet: 0 !default;
// $list-nested-margin: rem-calc(20) !default;
// $definition-list-header-weight: $font-weight-bold !default;
// $definition-list-header-margin-bottom: .3rem !default;
// $definition-list-margin-bottom: rem-calc(12) !default;

// We use these to control padding and margin
$microformat-padding:0;
$microformat-margin:0;

// We use these to control the border styles
$microformat-border-width: 0;
$microformat-border-style: none;
$microformat-border-color: glb-color(dl-grey);

// We use these to control full name font styles
$microformat-fullname-font-weight: normal;
$microformat-fullname-font-size: ms(1);

// We use this to control the summary font styles
$microformat-summary-font-weight: normal;


//
// 05 Media Queries
//

// Foundaiton Media Query Ranges
$small-range: (0, 40em);
$medium-range: (40.063em, 64em);
$large-range: (64.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em);


//  visibilty classes
$include-html-visibility-classes: true;

//Usage

// @media #{$small-up} { }
// @media #{$small-only} { }
// @media #{$medium-up} { }
// @media #{$medium-only} { }
// @media #{$large-up} { }
// @media #{$large-only} { }
// @media #{$xlarge-up} { }
// @media #{$xlarge-only} { }
// @media #{$xxlarge-up} { }
// @media #{$xxlarge-only} { }



// Media Queries

// $screen: "only screen" !default;
// $landscape: "#{$screen} and (orientation: landscape)" !default;
// $portrait: "#{$screen} and (orientation: portrait)" !default;
// $small-up: $screen !default;
// $small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
// $medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
// $medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
// $large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
// $large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
// $xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
// $xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
// $xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
// $xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;







//
// 06 Buttons
//

$button-font-weight: 300;

$glb-button-default-color: glb-color(dl-ash);
$button-bg-color: $glb-button-default-color;
$glb-button-primary-color: glb-color(dl-grannysmith);

$button-tny: .4rem;
$button-sml: .8rem;
$button-med: 1rem;
$button-lrg: 1rem;

$button-font-sml: ms(0);
$button-font-med: ms(1);
$button-font-lrg: ms(1);

$button-margin-bottom: 1rem;
$button-border-width: 0 0 2px 0;
$button-function-factor: 7%;

// We use this to set default opacity for disabled buttons.
$button-disabled-opacity: .7;





//
// 07 Forms
//

$big-form-height: ms(6); // height of .form__element--big & pre/postfix--big

$include-html-form-classes:true;

// We use this to set the base for lots of form spacing and positioning styles
// $form-spacing: 1rem;

// We use these to style the labels in different ways
// $form-label-pointer: pointer;
// $form-label-font-size: rem-calc($glb-base-size);
// $form-label-font-weight: normal;
// $form-label-bottom-margin: ms(-2);
// $form-label-font-color: #ffffff;
$form-label-bottom-margin: ms(-2);
// $input-font-family: inherit;
$input-font-color: glb-color(dl-black);
$input-font-size: ms(1);
$input-bg-color: #fff;
$input-focus-bg-color: #fff;
$input-border-color: gc(dl-grey);
$input-focus-border-color: $glb-primary-color;
// $input-border-style: solid;
$input-border-width: 1px;
$input-border-radius: 2px;

$input-disabled-bg: gc(dl-ash);
// $input-box-shadow: inset 0 ms(-10) ms(-8) rgba(gc(dl-charcoal) ,0.2);
$input-box-shadow: none;
$input-include-glowing-effect: false;


// We use these to style the prefix and postfix input elements
$input-prefix-bg: gc(dl-cream);
$input-prefix-border-color: gc(dl-grey);
$input-prefix-border-size: 1px;
// $input-prefix-border-type: solid;
// $input-prefix-overflow: hidden;
$input-prefix-font-color: gc(dl-charcoal);
// $input-prefix-font-color-alt: #fff;

$select-bg-color: #fff;




//
// 08 Transition speeds
//

$transition-duration: (
	fast: .1s,
	default: .3s,
	slow: .5s
);

$transition-speed: $transition-duration;

// returns a generic transition
@mixin trans($duration : default){
	transition: all map-get($transition-duration , $duration) $ease-in-out-quad;
}





//
// 09 Dropdowns
//

$include-html-dropdown-classes: false;
// We use these to controls height and width styles.
// $f-dropdown-max-width: 200px;
// $f-dropdown-height: auto;
// $f-dropdown-max-height: none;
$f-dropdown-margin-top: #{$glb-base-alt-size}px;

// We use this to control the background color
// $f-dropdown-bg: #fff;

// We use this to set the border styles for dropdowns.
// $f-dropdown-border-style: solid;
// $f-dropdown-border-width: 1px;
// $f-dropdown-border-color: darken(#fff, 20%);

// We use these to style the triangle pip.
// $f-dropdown-triangle-size: 6px;
// $f-dropdown-triangle-color: #fff;
// $f-dropdown-triangle-side-offset: 10px;

// We use these to control styles for the list elements.
// $f-dropdown-list-style: none;
// $f-dropdown-font-color: #555;
$f-dropdown-font-size: ms(-1);
// $f-dropdown-list-padding: rem-calc(5, 10);
// $f-dropdown-line-height: rem-calc(18);
// $f-dropdown-list-hover-bg: #eeeeee  ;
// $dropdown-mobile-default-float: 0;

// We use this to control the styles for when the dropdown has custom content.
// $f-dropdown-content-padding: rem-calc(20);


//
// 10 Tooltips
//

$include-html-tooltip-classes: true; // Include foundation Tooltips
// $has-tip-border-bottom: dotted 1px #ccc;
// $has-tip-font-weight: bold;
// $has-tip-font-color: #333;
// $has-tip-border-bottom-hover: dotted 1px darken($primary-color, 20%);
// $has-tip-font-color-hover: $primary-color;
// $has-tip-cursor-type: help;

// $tooltip-padding: rem-calc(12);
// $tooltip-bg: #333;
// $tooltip-font-size: rem-calc(14);
// $tooltip-font-weight: normal;
// $tooltip-font-color: #fff;
// $tooltip-line-height: 1.3;
// $tooltip-close-font-size: rem-calc(10);
// $tooltip-close-font-weight: normal;
// $tooltip-close-font-color: #777;
// $tooltip-font-size-sml: rem-calc(14);
// $tooltip-radius: $global-radius;
// $tooltip-pip-size: 5px;




//
// 11 Top Bar
//

$topbar-height: ms(6); // we set the height here so we can use it to push down the content
$topbar-bgcolor: gc(dl-black);
$topbar-textcolor: gc(dl-grey);

$topnav-height: ms(7);
$topnav-bgcolor: gc(dl-white);
$topnav-textcolor: gc(dl-iron);





//
// 12 Hopscotch tour bubbles
//

// See vendor / hopscotch

$anim-duration: .5s;
$distance: ms(3);
$anim-timing: ease-in-out;
$bubbleCornerRadius: map-get($border-radius, small);
$bubbleColor: #fff;
$bubbleBorderWidth: 0;
// $bubbleFontFamily: $family-arial;
// $bubblePadding: ms(3);
$borderColor: transparent;
$circleWidth: ms(5);
// $spriteUrl: url(../images/hopscotch-sprite-green.png);
// $bubbleZindex: 100;


//
// 13 Foundation Grid
//

$restricted-row-width: ms(19);  // sets width for row.restricted
$include-html-grid-classes: true;

//Foundation grid sizes
// $row-width: rem-calc(1000);
$row-width: 100%;
$column-gutter: rem-calc(15);


//
// 14 Card column devider
//

@mixin card-column($column){
	@if $column == 1{
		width: 100%;
	}

	@if $column == 2{
		width: 49%;
		margin: 1%;

		&:nth-child(odd){
			margin-left: 0;
		}

		&:nth-child(even){
			margin-right: 0;
		}
	}

	@if $column == 3
	{
		width: 32.6%;
		margin: 1% 0;

		&:nth-child(3n+2){
			margin-right: 1%;
			margin-left: 1%;
		}
	}

	@if $column == 4
	{
		width: 23%;
		margin: 1%;

		&:nth-child(odd){
			margin-left: 1%;
		}

		&:nth-child(even){
			margin-right: 1%;
		}
	}
}
