@include exports('components/logos'){
	/***
	 * Logos:
	 *
	 * Logos are images uploaded by orgs. They show up on the org profile page, and at the top of an org's documents.
	 */

	/**
	 *
	 *Basic logo:
	 *
	 *`.logo`
	 *
	 *Used to style uploaded logos. Similar rules to icons.
	 *
	 * 	@example
	 *		div.card
	 *			div.logo.u-color__dl-grey
	 *				svg.icon.icon--jumbo
	 *					use(xlink:href='#icon-dragonlaw')
	 *
	 */

	.logo{
		display: block;
		max-width: 100%;
		height: auto;
		clear: both;
		vertical-align: middle;
	}

	/**
	 *
	 *Org logo:
	 *
	 *`.logo.logo--org`
	 *
	 *Used to style uploaded logos in org profile. Has a larger max width than icon--jumbo, to accommodate possible landscape-format logos.
	 *
	 */

	.logo--org,
	.logo--org img{
		max-width: ms(12);
		max-height: ms(9);
	}

	/**
	 *
	 *Document logo:
	 *
	 *`.logo.logo--doc`
	 *
	 *Used to style uploaded logos in rendered document headers. Has some extra margin and padding.
	 *
	 */

	.logo--doc{
		margin-bottom: ms(1);
		padding-left: ms(0);
	}

	/**
	 *
	 * Front page partner logo
	 *
	 *`.logo.logo--front-page`
	 *
	 *Rules for partner logo on login screen.
	 *
	 *
	 * 	@example
	 *		div.card
	 *			div.logo.logo--front-page.u-color__dl-grey
	 *				svg.icon.icon--jumbo
	 *					use(xlink:href='#icon-dragonlaw')
	 *			div.logo--front-page__disclaimer This is the disclaimer
	 *
	 */

	.logo--front-page,
	.logo--front-page img{
		max-width: ms(11);
		margin: 0 auto;
		text-align: center;
	}

	.logo--front-page img{
		display: block;
		max-height: ms(11);
	}

	.logo--front-page__disclaimer{
		max-width: ms(13);
		margin: ms(2) auto 0;
		color: gc(dl-mediumgrey);
		font-size: ms(-1);
		text-align: center;
		text-shadow: 0 1px 0 rgba(gc(dl-white), .4);
		text-transform: uppercase;
	}

	.logo--signature{ //@TODO: document/change this
		max-height: 150px;
	}

	.logo--receipt{
		height: 50px;
	}
}
