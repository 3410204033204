/**
 *
 *Colors:
 *
 *`.u-color__[$glb-color]` sets the color of the element to any color from the $glb-colors array. Light text colors set on black for example only.
 *
 * 	@example
 *		div.u-color__dl-superblack .u-color__dl-superblack
 *		div.u-color__dl-black .u-color__dl-black
 *		div.u-color__dl-mediumgrey .u-color__dl-mediumgrey
 *		div.u-color__dl-charcoal .u-color__dl-charcoal
 *		div.u-color__dl-silver .u-color__dl-silver
 *		div.u-color__dl-basalt .u-color__dl-basalt
 *		div.u-color__dl-lightgrey .u-color__dl-lightgrey
 *		div.u-color__dl-grey.u-colorbg__dl-superblack .u-color__dl-grey
 *		div.u-color__dl-slate.u-colorbg__dl-superblack .u-color__dl-slate
 *		div.u-color__dl-ash.u-colorbg__dl-superblack .u-color__dl-ash
 *		div.u-color__dl-beige.u-colorbg__dl-superblack .u-color__dl-beige
 *		div.u-color__dl-cream.u-colorbg__dl-superblack .u-color__dl-cream
 *		div.u-color__dl-white.u-colorbg__dl-superblack .u-color__dl-white
 *		div.u-color__dl-canary .u-color__dl-canary
 *		div.u-color__dl-grannysmith .u-color__dl-grannysmith
 *		div.u-color__dl-olive .u-color__dl-olive
 *		div.u-color__dl-sky .u-color__dl-sky
 *		div.u-color__dl-mariana .u-color__dl-mariana
 *		div.u-color__dl-cobalt .u-color__dl-cobalt
 *		div.u-color__dl-steel .u-color__dl-steel
 *		div.u-color__dl-carmine .u-color__dl-carmine
 *		div.u-color__dl-ruby .u-color__dl-ruby
 *		div.u-color__dl-rust .u-color__dl-rust
 *
 *`.u-colorbg__[$glb-color]` sets the background color of the element to any color from the $glb-colors array. White text on dark backgrounds for example only.
 *
 * 	@example
 *		div.u-colorbg__dl-superblack.u-color__dl-white .u-colorbg__dl-superblack
 *		div.u-colorbg__dl-black.u-color__dl-white .u-colorbg__dl-black
 *		div.u-colorbg__dl-mediumgrey.u-color__dl-white .u-colorbg__dl-mediumgrey
 *		div.u-colorbg__dl-charcoal.u-color__dl-white .u-colorbg__dl-charcoal
 *		div.u-colorbg__dl-silver .u-colorbg__dl-silver
 *		div.u-colorbg__dl-basalt .u-colorbg__dl-basalt
 *		div.u-colorbg__dl-lightgrey .u-colorbg__dl-lightgrey
 *		div.u-colorbg__dl-grey .u-colorbg__dl-grey
 *		div.u-colorbg__dl-slate .u-colorbg__dl-slate
 *		div.u-colorbg__dl-ash .u-colorbg__dl-ash
 *		div.u-colorbg__dl-beige .u-colorbg__dl-beige
 *		div.u-colorbg__dl-cream .u-colorbg__dl-cream
 *		div.u-colorbg__dl-white .u-colorbg__dl-white
 *		div.u-colorbg__dl-canary .u-colorbg__dl-canary
 *		div.u-colorbg__dl-grannysmith .u-colorbg__dl-grannysmith
 *		div.u-colorbg__dl-olive .u-colorbg__dl-olive
 *		div.u-colorbg__dl-sky .u-colorbg__dl-sky
 *		div.u-colorbg__dl-mariana .u-colorbg__dl-mariana
 *		div.u-colorbg__dl-cobalt .u-colorbg__dl-cobalt
 *		div.u-colorbg__dl-steel .u-colorbg__dl-steel
 *		div.u-colorbg__dl-carmine .u-colorbg__dl-carmine
 *		div.u-colorbg__dl-ruby .u-colorbg__dl-ruby
 *		div.u-colorbg__dl-rust .u-colorbg__dl-rust
 *
 *`.u-colorborder__[$glb-color]` sets the border color of the element to any color from the $glb-colors array. Note that it doesn't set any othe parameters of a border,
 * so for it to work you need to ensure the element has a border in the first place.
 *
 * 	@example
 *		div.card.has-border.u-colorborder__dl-superblack .u-colorborder__dl-superblack
 *		div.card.has-border.u-colorborder__dl-black .u-colorborder__dl-black
 *		div.card.has-border.u-colorborder__dl-mediumgrey .u-colorborder__dl-mediumgrey
 *		div.card.has-border.u-colorborder__dl-charcoal .u-colorborder__dl-charcoal
 *		div.card.has-border.u-colorborder__dl-silver .u-colorborder__dl-silver
 *		div.card.has-border.u-colorborder__dl-basalt .u-colorborder__dl-basalt
 *		div.card.has-border.u-colorborder__dl-lightgrey .u-colorborder__dl-lightgrey
 *		div.card.has-border.u-colorborder__dl-grey .u-colorborder__dl-grey
 *		div.card.has-border.u-colorborder__dl-slate .u-colorborder__dl-slate
 *		div.card.has-border.u-colorborder__dl-ash .u-colorborder__dl-ash
 *		div.card.has-border.u-colorborder__dl-beige .u-colorborder__dl-beige
 *		div.card.has-border.u-colorborder__dl-cream .u-colorborder__dl-cream
 *		div.card.has-border.u-colorborder__dl-white .u-colorborder__dl-white
 *		div.card.has-border.u-colorborder__dl-canary .u-colorborder__dl-canary
 *		div.card.has-border.u-colorborder__dl-grannysmith .u-colorborder__dl-grannysmith
 *		div.card.has-border.u-colorborder__dl-olive .u-colorborder__dl-olive
 *		div.card.has-border.u-colorborder__dl-sky .u-colorborder__dl-sky
 *		div.card.has-border.u-colorborder__dl-mariana .u-colorborder__dl-mariana
 *		div.card.has-border.u-colorborder__dl-cobalt .u-colorborder__dl-cobalt
 *		div.card.has-border.u-colorborder__dl-steel .u-colorborder__dl-steel
 *		div.card.has-border.u-colorborder__dl-carmine .u-colorborder__dl-carmine
 *		div.card.has-border.u-colorborder__dl-ruby .u-colorborder__dl-ruby
 *		div.card.has-border.u-colorborder__dl-rust .u-colorborder__dl-rust
 *
 *`.u-colorfill__[$glb-color]` sets the fill of the element to any color from the $glb-colors array. Use for styleable svgs that have fills that aren't not set to 'currentColor.'
 *
 * 	@example
 *		div.u-box__mb2
 *			svg.icon.icon--medium.u-color__dl-primary.u-colorfill__dl-white
 *				use(xlink:href='#icon-circletick--color')
 *			span.u-box__ml3 color: u-color__primary, fill: u-colorfill__dl-white
 *		div.u-box__mb2
 *			svg.icon.icon--medium.u-color__dl-canary.u-colorfill__dl-white
 *				use(xlink:href='#icon-circletick--color')
 *			span.u-box__ml3 color: u-color__dl-canary, fill: u-colorfill__dl-white
 *		div.u-box__mb2
 *			svg.icon.icon--medium.u-color__dl-mariana.u-colorfill__dl-ruby
 *				use(xlink:href='#icon-circletick--color')
 *			span.u-box__ml3 color: u-color__mariana, fill: u-colorfill__dl-ruby
 **/


// set background color on anything
@each $color, $value in $glb-colors{
	.u-color__#{ #{$color} }{
		// scss-lint:disable ImportantRule
		color: $value !important;
		// scss-lint:enable ImportantRule
	}

	.u-colorbg__#{ #{$color} }{
		// scss-lint:disable ImportantRule
		background-color: $value !important;
		// scss-lint:enable ImportantRule
	}

	.u-colorborder__#{ #{$color} }{
		// scss-lint:disable ImportantRule
		border-color: $value !important;
		// scss-lint:enable ImportantRule
	}

	.u-colorfill__#{ #{$color} }{
		// scss-lint:disable ImportantRule
		fill: $value !important;
		// scss-lint:enable ImportantRule
	}
}

// .u-color__primary{
// 	// scss-lint:disable ImportantRule
// 	color: $glb-primary-color !important;
// 	// scss-lint:enable ImportantRule
// }

// .u-colorbg__primary{
// 	// scss-lint:disable ImportantRule
// 	background-color: $glb-primary-color !important;
// 	// scss-lint:enable ImportantRule
// }
