@include exports('components/navitems'){

	/***
	 *
	 *Nav items:
	 *
	 *`.navitem`, `.navitem.is-active`
	 *
	 *An item that displays in the sidebar nav in vesion 1 of the app, a navitem is made up of an icon and
	 *a text node, and has a rest, hover and active state. NB rest and hover
	 *versions of the icon are included in the markup.
	 *
	 * 	@example
	 *		div.navitem
	 *			div.navitem__icon
	 *				svg.icon.icon--medium
	 *					use(xlink:href='images/sprites.svg#icon-folder--mono')
	 *			div.navitem__icon.navitem__icon--active
 	 *				svg.icon.icon--medium
	 *					use(xlink:href='images/sprites.svg#icon-folder--color')
	 *			span.navitem__text Folders
 	 *
	 *		div.navitem.is-active
	 *			div.navitem__icon
	 *				svg.navitem__icon.icon.icon--medium
	 *					use(xlink:href='images/sprites.svg#icon-newdocument--mono')
	 *			div.navitem__icon.navitem__icon--active
 	 *				svg.icon.icon--medium
	 *					use(xlink:href='images/sprites.svg#icon-newdocument--color')
	 *			span.navitem__text New document (active)
	 *
	 *
	 */

	.navitem{
		position: relative;
		margin: ms(-2) 0;
		padding: ms(-2) 0;
		overflow: hidden;
		opacity: .8;
		color: currentcolor;
		white-space: nowrap; // Make sure we dont get stuff jumping lines when the sidebar is
		cursor: pointer;
		user-select: none;

		&:hover,
		&.is-active{
			opacity: 1;
		}

		&.is-active{
			font-weight: bold;
		}

	}


	// Icons
	.navitem__icon{
		display: inline-block;
		margin-left: ms(-2);
		transition: opacity map-get($transition-duration, default);
		opacity: 1;

		.navitem.is-active & {
			opacity: 0;
		}

		svg {
			pointer-events: none;
		}

		.navitem--left & { // Pad out top and bottom of icons for small and medium screens if navitem is in left sidebar...
			padding: ms(1) 0;
		}

		@media #{$large-up} { // ...and remove that padding for large screens
			.navitem--left & {
				padding: 0;
			}
		}
	}

	// only show the active icon when the navitem is active
	.navitem__icon--active{
		position: absolute;
		top: ms(-2); // .navitem padding
		left: 0;
		opacity: 0;

		.navitem.is-active & {
			opacity: 1;

			// .sidebarleft & {
			// 	position: absolute;
			// 	top: ms(1); // .navitem padding
			// 	left: 0;
			// }
		}
	}

	// variant of the navitem where it has a background
	.navitem__icon--enclosed{
		padding: ms(-4);
		border-radius: 100%;
		background: #fff; //override with bg utilty class
	}

	.navitem__text{
		margin: 0 0 0 ms(1);
		vertical-align: middle;
	}
}// exports
