@include exports('components/lozenges'){
	/***
	* Lozenges:
	*/

	/**
	 *
	 *Basic lozenge:
	 *
	 *`.lozenge`
	 *
	 *A lozenge is a small tag that might be used to denote something about the content it is inline with.
	 * It is NOT a button. Lozenge colors are controlled by the `.u-color__$color` utility class. The default color is grey.
	 * Add a `.lozenge--separated` modifier to give it a bit of a margin. A `.lozenge--spacious` modifier adds extra padding to the inside of the
	 * lozenge.
	 *
	 * 	@example
	 *		div.lozenge I am a lozenge
 	 *		div.lozenge.u-color__dl-sky blue lozenge
 	 *		div.lozenge.u-color__dl-ruby ruby lozenge
 	 *		hr
 	 *		div.card
	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title
	 *						| I am a basic slat
	 *						div.lozenge basic
 	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title
	 *						| I am an awesome slat
	 *						div.lozenge.lozenge--separated.u-color__dl-canary separated a bit
	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title
	 *						| I am another slat
	 *						div.lozenge.lozenge--spacious.u-color__dl-ruby breathing room in here
	 *
	 */

	.lozenge{
		display: inline-block;
		padding: 1px 4px;
		border: 1px solid currentcolor;
		border-radius: map-get($border-radius, small);
		color: gc(dl-basalt);
		font-size: ms(0);
		cursor: default;
	}

	.lozenge--separated{
		margin-left: ms(-2);
	}

	.lozenge--spacious{
		padding: 4px 8px;
	}

	/**
	 *
	 *Filled lozenge:
	 *
	 *`.lozenge.lozenge--filled`
	 *
	 *A filled lozenge is a lozenge with a solid background color. These are designed to be used as metadata tags.
	 * They are ALSO not buttons. Filled lozenge colors are controlled by the `.u-colorbg__$color` utility class. The default color is grey.
	 *
	 * 	@example
	 *		div.lozenge.lozenge--filled FLG
 	 *		div.lozenge.lozenge--filled.u-colorbg__dl-canary FLY
 	 *		div.lozenge.lozenge--filled.u-colorbg__dl-ruby FLR
 	 *		div.lozenge.lozenge--filled.u-colorbg__dl-sky FLB
 	 *		hr
 	 *		div.card
	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title
	 *						| I am a basic slat
	 *						div.lozenge.lozenge--filled basic
 	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title
	 *						| I am an awesome slat
	 *						div.lozenge.lozenge--filled.u-colorbg__dl-grannysmith awesome
	 *
	 */

	.lozenge--filled{
		padding: 2px 5px;
		border: 0;
		background: gc(dl-basalt);
		color: gc(dl-white);
		vertical-align: middle;
	}

	// Throw one of these on some text preceding or following the filled lozenge if the baselines aren't working together.

	.lozenge--filled__textalign{
		display: inline;
		vertical-align: middle;
	}
}
