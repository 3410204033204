@include exports('utilities/visibility'){

	/**
	 *
	 *visibility:
	 *
	 *`.is-gone`
	 *
	 * Setting `is-gone` on an elements hides it via `display: none`
	 *
	 * 	@example
	 *		div.is-gone You cant see me
	 *
	 */

	.is-gone{
		// scss-lint:disable ImportantRule
		display: none !important;
	}
}
