@include exports('components/titlebar') {
	/***
	 * Title Bar:
	 */

	/**
	 *
	 *Basic titlebar:
	 *
	 *`.titlebar > h1.title`
	 *
	 *Contains the page title
	 *
	 * 	@example
	 *		div.titlebar
	 *			h1.titlebar__title My documents
	 *
	 */

	.titlebar{
		display: flex;  // if flexbox is supported... YAY
		flex-wrap: wrap;
		width: 100%;
		padding: ms(1) 0 ms(1);
	}

	/**
	 *
	 *Titlebar inside of a card:
	 *
	 *`.titlebar.titlebar--card > h1.title`
	 *
	 *
	 * 	@example
	 *		div.card.card--spacious.card--collapse-bottom
	 *			div.titlebar.titlebar--card.titlebar--border.titlebar--h-collapse.titlebar--spacious-bottom
	 *				div.titlebar__back
	 *					svg.icon.icon--medium
	 *						use(xlink:href='#icon-back')
	 *				h1.titlebar__title My documents
	 *		div.card.card--spacious.card--collapse-top
	 *			div.card__content
	 *				h3 Content of card
	 *
	 */

	.titlebar--card{
		align-items: baseline;
		padding: ms(2) ms(3) ms(1);

		.titlebar__back .icon{
			width: ms(4);
			height: ms(4);
		}

		.card--spacious &{
			padding-top: 0;

			@media #{$medium-up}{
				padding-top: ms(2);
			}
		}
	}

	/**
	 *
	 *Alternate spacing for titlebars:
	 *
	 *`.titlebar--border, .titlebar--noborder, .titlebar--invisible, .titlebar--h-collapse, .titlebar--v-collapse, .titlebar--spacious`
	 *
	 *Use these to alter border, padding, margins on titlebars
	 *`.titlebar.titlebar--border` Titlebar with a border
	 *`.titlebar.titlebar--noborder` Actually removes margin. Needs a refactor
	 *`.titlebar.titlebar--invisible` Sets background color to layout color. Also needs a refactor
	 *`.titlebar.titlebar--h-collapse` Removes right and left padding
	 *`.titlebar.titlebar--v-collapse` Removes top and bottom padding
	 *`.titlebar.titlebar--spacious` Increases top adn bottom margins
	 *`.titlebar.titlebar--compact-border` Decreases bottom margin
	 *
	 */

	.titlebar--border{
		margin: 0 0 ms(0);
		border-bottom: 1px solid darken($glb-layout-color, 4%);

		@media #{$large-up}{
			margin: 0 0 ms(4);
		}
	}

	.titlebar--thickborder{
		border-bottom: 4px solid darken($glb-layout-color, 4%);
	}

	.titlebar--noborder{ // used with tabset navigation beneath title
		margin-bottom: 0;
		border-bottom: 0;
	}

	.titlebar--invisible{ // used in doc builder so that right sidebar doesn't cut titlebar off
		border-bottom: 0;
		background: $glb-layout-color;
	}

	.titlebar--right-collapse{
		padding-right: 0;
	}

	.titlebar--left-collapse{
		padding-left: 0;
	}

	.titlebar--h-collapse{
		@extend .titlebar--right-collapse;
		@extend .titlebar--left-collapse;
	}

	.titlebar--top-collapse{
		padding-top: 0;
	}

	.titlebar--bottom-collapse{
		padding-bottom: 0;
	}

	.titlebar--v-collapse{
		@extend .titlebar--top-collapse;
		@extend .titlebar--bottom-collapse;
	}

	.titlebar--spacious{
		margin: ms(3) 0;
	}

	.titlebar--spacious-bottom{
		padding-bottom: ms(3);

		@media #{$medium-up}{
			padding-bottom: ms(5);
		}
	}

	.titlebar--compact-border{
		margin-bottom: ms(1);
	}

	.titlebar__title{
		flex: auto;
		align-items: baseline;
		margin:auto;
		cursor: default;

		@media #{$large-up}{
			margin: 0;
		}
	}

	h1.titlebar__title{
		margin: 0;
	}

	h1.titlebar__title + .titlebar__tools{
		margin: ms(2) 0;

		.titlebar--card &{ // title will wrap when it hits space reserved for button in tools in titlebar--card

			@media #{$medium-up}{
				margin: 0;
			}
		}

		@media #{$large-up}{
			margin: 0;
		}
	}

	.titlebar__title--abbr{
		flex: 1;
		overflow: auto;
		text-overflow: inherit;
		white-space: normal;
	}

	/**
	 *
	 *Back button:
	 *
	 *`.titlebar > .titlebar__back + ...`
	 *
	 *Adds a back button for going to previous screen
	 *
	 * 	@example
	 *		div.titlebar
	 *			.titlebar__back
	 *				svg.icon.icon--medium
	 *					use(xlink:href='#icon-back')
	 *			h1.titlebar__title My documents
	 *
	 */

	.titlebar__back{
		margin: 0 ms(3) 0 0;

		.icon{
			// margin: 0 ms(3) 0 0;
			padding: .3rem;
			transition: color map-get($transition-duration, fast) $ease-in-out-quad;
			color: gc(dl-iron);
			vertical-align: text-bottom;
			cursor: pointer;

			&:hover{
				color: gc(dl-mediumgrey);
			}

			// @media #{$medium-up}{
			// 	margin: 0 ms(5) 0 0;
			// }
		}

		@media #{$medium-up}{
			margin: 0 ms(5) 0 0;
		}

		@media #{$large-up}{
			margin: 0 ms(5) 0 0;
		}

	}

	/**
	 *
	 *Edit-in-place:
	 *
	 *`.titlebar > .titlebar__title > ... + .editlink `
	 *
	 *Adds an 'edit title' link to the page title to show it's editable.
	 *
	 *Setting the `contentEditable` attribute to `true` on the inner `.editable` hints that the content is now editable.
	 *
	 * 	@example
	 *		div.titlebar
	 *			h1.titlebar__title
	 *				span.editable Project X has a long name that might cause issues
	 *				.editlink
	 *					svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-edit')
	 *					| edit title
	 *
 	 *		div.titlebar
	 *			h1.titlebar__title
	 *				span.editable(contentEditable='true') Project X
	 *				.editlink
	 *					svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-tick')
	 *					| finish
	 *
	 */

	// .titlebar__title .editable{}
	// .titlebar__title .editable[contentEditable=true]{}


	/**
	 *
	 *Toolbars:
	 *
	 *`.titlebar > .titlebar__title + .titlebar__tools > ... `
	 *
	 *Used when we need to add other components into a titlebar besides the title and the back button.
	 * Usually this is just a dropdown, but it might also be a search box or something. Multiple tools will
	 * sit inline with one another.
	 *
	 * 	@example
	 *		div.titlebar
	 *			h1.titlebar__title My documents
	 *			.titlebar__tools
     *				span.dropdowntext(data-dropdown='titlebardropdown') Settings
	 *				ul.dropdown.dropdown--nopip#titlebardropdown(data-dropdown-content)
	 *					li.dropdown__item Dropdown Item 1
	 *					li.dropdown__item Dropdown Item 2
	 *					li.dropdown__item Dropdown Item 3
	 *		div.titlebar
	 *			h1.titlebar__title Multiple titlebar tools
	 *			.titlebar__tools
     *				span.dropdowntext(data-dropdown='titlebardropdown') Settings
	 *				ul.dropdown.dropdown--nopip#titlebardropdown(data-dropdown-content)
	 *					li.dropdown__item Dropdown Item 1
	 *					li.dropdown__item Dropdown Item 2
	 *					li.dropdown__item Dropdown Item 3
	 *				.searchbar
	 *					input.searchbar__input
	 *					.searchbar__action
	 *						svg.icon.icon--tiny.icon--hover
	 *							use(xlink:href='images/sprites.svg#icon-search')
	 *
	 */

	.titlebar__tools{
		display: flex;
		// flex: 1;
		align-items: center;
		margin: 0;
		color: gc(dl-basalt);

		@media #{$large-up}{
			margin: 0;
		}
	}

	.titlebar__tools--button{
		width: 100%;

		.button{
			display: block;
			width: 100%;
			margin: 0;

			@media #{$medium-up}{
				display: inline-block;
				width: auto;
				margin-left: ms(2);
			}
		}

		@media #{$medium-up}{
			width: auto;
 		}
	}

 	/**
	 *
	 *Kitchen sink:
	 *
	 *Try not to do this.
	 *
	 * 	@example
	 *		div.titlebar
	 *			.titlebar__back
	 *				svg.icon.icon--medium
	 *					use(xlink:href='images/sprites.svg#icon-chevronleft--white')
	 *			h1.titlebar__title
	 *				span.editable Project X
	 *				.editlink
	 *					svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-edit')
	 *					| edit title
	 *			.titlebar__tools
     *				span.dropdowntext(data-dropdown='titlebar2dropdown') Settings
	 *				ul.dropdown.dropdown--nopip#titlebar2dropdown(data-dropdown-content)
	 *					li.dropdown__item Dropdown Item 1
	 *					li.dropdown__item Dropdown Item 2
	 *					li.dropdown__item Dropdown Item 3
	 *
	 */


	/**
	 *
	 *Secondary titlebar:
	 *
	 *`.titlebar.titlebar--secondary > h2.title`
	 *
	 *For sub-modules on a page. Use sparingly.
	 * In theory, secondary titlebars can handle all the same sub-components as main titlebars
	 * but in reality things like back buttons will probably break it and look bad.
	 *
	 * 	@example
	 *		div.titlebar.titlebar--secondary
	 *			h2.titlebar__title My documents
	 *
	 *
	 */

	.titlebar--secondary{
		margin: 0;
		padding: 0;
		border-bottom: 0;
		background: $glb-layout-color;
	}

	.titlebar--card.titlebar--secondary{
		@extend .titlebar--card;
		margin-bottom: 0;
		padding: ms(1) 0 ms(3);
		border-bottom: 1px solid darken($glb-layout-color, 4%);
		background: transparent;
	}



	// component overrides/
	.titlebar .subnav{
		margin-bottom: 0;
	}
}
