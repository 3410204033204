@include exports('components/browsers'){

	/***
	 * Browsers:
	 */

	/**
	 *
	 *Complete browser rundown:
	 *
	 *`.browser` > `.browser__menu` , `.browser__content` ...
	 *
	 *Used in My Documents, the Dragon Law file browser was originally made up of separate modified card and slat components. For the
	 * sake of maintainability, and because the set width relationships were becoming pretty painfully unmodular anyhow, I decided to
	 * combine the whole shebang. Dropdowns are still wrapped in as a separate component so that we can use base Foundation dropdowns.
	 *
	 *The browser component mimics a card. The browser menu is an optional part that sits at the top of the browser. Below this, in
	 *the browser content, are browser rows, which mimic slats. The browser items make up the middle portion of the row, and can contain
	 * up to three browser items in order to match the widths of the items in the browser menu. If an item is included in the markup but
	 *contains nothing, space will still be reserved for it. The browser items are bookended by a browser rowdragger (optional) and
	 *browser rowicon on the left, and either browser rowtools or a browser rowbuffer (if no tools are needed) on the right.
	 *
	 *Add a `.browser__rowicon--notebadge` modifier to your `.browser__rowicon`
	 *, then follow the svg inside the `.browser__rowicon` div with a
	 *`.browser__rownotebadge` and a `.browser__rownotenumber` for a little
	 *notification badge a la iOS desktop app icon badges. Basic notebadge is
	 *meant to be used with a medium sized icon.
	 *
	 * 	@example
	 *		div.card.card--base
	 *			div.browser
	 *				div.browser__menu
	 *					div.browser__menuoption.browser__menuoption--primary
	 *						div.browser__menutitle
	 *							span Option one!
	 *						div.browser__menubutton
	 *							svg.icon.icon--micro
	 *								use(xlink:href='images/sprites.svg#icon-sort--asc')
	 *						div.browser__menubutton
	 *							svg.icon.icon--micro
	 *								use(xlink:href='images/sprites.svg#icon-sort--desc')
	 *					div.browser__menuoption.browser__menuoption--dropdown(data-dropdown='card-option-dropdown')
	 *						div.browser__menutitle
	 *							span Option dropdown!
	 *						div.browser__menubutton
	 *							div.browser__menuicon
	 *								svg.icon.icon--micro
	 *									use(xlink:href='#icon-chevrondown--menu')
	 *						ul.dropdown.dropdown--browsermenu.dropdown--nopip#card-option-dropdown(data-dropdown-content)
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Draft')
	 *								span Draft
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Finished')
	 *								span Finished
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *								span Signing
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Signed')
	 *								span Signed
	 *					div.browser__menuoption
	 *						div.browser__menutitle
	 *							span Option three!
	 *						div.browser__menubutton
	 *							svg.icon.icon--micro
	 *								use(xlink:href='images/sprites.svg#icon-sort--asc')
	 *						div.browser__menubutton
	 *							svg.icon.icon--micro
	 *								use(xlink:href='images/sprites.svg#icon-sort--desc')
	 *					div.browser__menuoption.browser__menuoption--small.browser__menuoption--dropdown(data-dropdown='card-option-dropdown-small')
	 *						div.cbrowser__menutitle
	 *							span Option small!
	 *						div.browser__menubutton
	 *							div.browser__menuicon
	 *								svg.icon.icon--micro
	 *									use(xlink:href='#icon-chevrondown--menu')
	 *						ul.dropdown.dropdown--browsermenu.dropdown--nopip#card-option-dropdown-small(data-dropdown-content)
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Draft')
	 *								span Draft
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Finished')
	 *								span Finished
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *								span Signing
	 *							li.dropdown__item.dropdown__item--icon
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Signed')
	 *								span Signed
	 *				div.browser__content
	 *					div.browser__row
	 *						div.browser__rowicon
	 *							svg.icon.icon--small
	 *								use(xlink:href='images/sprites.svg#icon-document--color')
	 *						div.browser__rowitems
	 *							div.browser__item.browser__item--primary
	 *								span Option text!
	 *							div.browser__item.u-color__dl-grey
	 *								svg.icon.icon--small
	 *									use(xlink:href='#icon-dragonlaw')
	 *								div.browser__itemtext
	 *									span Option text!
	 *							div.browser__item
	 *								svg.icon.icon--small
	 *									use(xlink:href='images/sprites.svg#icon-folder--color')
	 *								div.browser__itemtext
	 *									span Option text!
	 *						div.browser__rowtools
	 *							svg.icon.icon--tiny.icon--hover
	 *								use(xlink:href='images/sprites.svg#icon-dotmenu')
	 *					div.browser__row.browser__row--draggable
	 *						div.browser__rowdragger
	 *						div.browser__rowicon
	 *							svg.icon.icon--small
	 *								use(xlink:href='images/sprites.svg#icon-document--color')
	 *						div.browser__rowitems
	 *							div.browser__item.browser__item--primary
	 *								span Option text!
	 *							div.browser__item.browser__item--secondary.browser__item--dropdown(data-dropdown='browser-item-dropdown')
	 *								svg.icon.icon--tiny
	 *									use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *								div.browser__itemtext
	 *									span Signing
	 *									div.browser__itemicon
	 *										svg.icon.icon--micro
	 *											use(xlink:href='#icon-chevrondown--menu')
	 *								div#browser-item-dropdown.dropdown.dropdown--nopip.dropdown--browseritem
	 *									div.browser__row.browser__row--h-spacious
	 *										svg.icon.icon--tiny
	 *											use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *										div.browser__itemtext Signing
	 *											div.browser__itemicon
	 *												svg.icon.icon--micro
	 *													use(xlink:href='#icon-chevrondown--menu')
	 *									div.browser__row.browser__row--noborder.browser__row--h-spacious
	 *										div.browser__rowitems.browser__rowitems--abbr.browser__rowitems--flex-large-up
	 *											div.browser__item.browser__item--left
	 *												div.browser__itemcontent
	 *													div.browser__itemtext.u-text__bold You
	 *													div.browser__itemtext firstname@lastname.net
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *											div.browser__item.browser__item--right
	 *												div.browser__itemtools
	 *													div.button.button--primary.button--small.button--block.u-box__mt0 Sign now
	 *									div.browser__row.browser__row--noborder.browser__row--h-spacious
	 *										div.browser__rowitems.browser__rowitems--abbr.browser__rowitems--flex-large-up
	 *											div.browser__item.browser__item--left
	 *												div.browser__itemcontent
	 *													div.browser__itemtext.u-text__bold Firstname Lastname
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *											div.browser__item.browser__item--right
	 *												div.browser__itemtools
	 *													div.button.button--small.button--block.u-box__mt0 Add email
	 *									div.browser__row.browser__row--noborder.browser__row--h-spacious
	 *										div.browser__rowitems.browser__rowitems--abbr.browser__rowitems--flex-large-up
	 *											div.browser__item.browser__item--left
	 *												div.browser__itemcontent
	 *													div.browser__itemtext.u-text__bold Firstname Lastname
	 *													div.browser__itemtext firstname@lastname.net
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-state--Signing')
	 *											div.browser__item.browser__item--right
	 *												div.browser__itemtools
	 *													div.button.button--small.button--block.u-box__mt0 Resend
	 *									div.browser__row.browser__row--noborder.browser__row--h-spacious
	 *										div.browser__rowitems.browser__rowitems--abbr.browser__rowitems--flex-large-up
	 *											div.browser__item.browser__item--left
	 *												div.browser__itemcontent
	 *													div.browser__itemtext.u-text__bold Firstname Lastname
	 *													div.browser__itemtext firstname@lastname.net
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-state--Signed')
	 *											div.browser__item.browser__item--right
	 *												div.browser__itemtools
	 *							div.browser__item.u-color__dl-grey
	 *								svg.icon.icon--small
	 *									use(xlink:href='#icon-dragonlaw')
	 *								div.browser__itemtext
	 *									span Option text!
	 *						div.browser__rowtools
	 *							svg.icon.icon--tiny.icon--hover
	 *								use(xlink:href='images/sprites.svg#icon-dotmenu')
	 */

	.browser{ // styled like a card, replaces card--optionbar
		// margin-bottom: ms(1);
		padding: 0;
		// border-radius: map-get($corner-radius, small);
		// background: #fff;
		// box-shadow: 1px 1px 2px rgba(gc(dl-black), .1);

		.actions{
			margin: 0;
			padding: ms(2) calc(#{$card-horz-padding + $slat-horz-padding + ms(-3) + ms(0)}) ms(4); // horizontal padding is equal to horz card padding + horz slat padding + width of dragger + dragger margin
		}
	}

	.browser__menu{ // replaces card__optionbar
		display: flex;
		justify-content: flex-end;
		padding: 0;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		background: gc(dl-slate);
		color: gc(dl-lightgrey);
		font-size: ms(0);

		@media #{$large-up}{
			align-items: baseline;
			padding-top: 0;
			padding-right: calc(#{ms(1) + map-get($icon-size, tiny) + $slat-horz-padding + $card-horz-padding}); // slat__tools left margin + slat__tools tiny icon width + slat padding + card__content padding
			padding-bottom: 0;
			padding-left: calc(#{$card-horz-padding + $slat-horz-padding + ms(-3) + ms(0) + map-get($icon-size, small) + ms(1)}); // card__content + slat padding + slat__optionbar slat__icon min-width (slat__dragger width + slat__dragger right margin + small icon width) + slat__icon right margin
		}
	}

	.browser__menuoption{ // replaces card__option
		display: none; // only show card__option--small modifier elements for small viewport size
		flex: 1 auto;
		padding: ms(0) 0;

		@media #{$large-up}{
			display: flex;
			width: 25%;
		}
	}

	.browser__menuoption--small{ // replaces card__option--small
		display: flex;
		justify-content: flex-end;
		padding: ms(0) ms(3);

		@media #{$large-up}{
			display: none;
		}
	}

	.browser__menuoption--primary{ // replaces card__option--primary

		@media #{$large-up}{
			width: 50%;
		}
	}

	.browser__menuoption--dropdown{ // replaces card__option--dropdown
		position: relative;
		cursor: pointer;

		.browser__menutitle{
			pointer-events: none;
		}

		.browser__menubutton{
			pointer-events: none;
		}

		.browser__menuicon{ // replaces card__optionicon
			display: inline-block;
			vertical-align: middle;
			pointer-events: all;
		}

		@media #{$large-up}{
			left: -#{ms(-2)};
			padding-left: ms(-2);
		}
	}

	.browser__menuoption--dropdown[aria-expanded='true']{
		background: gc(dl-grey);
		color: gc(dl-charcoal);

		.browser__menuicon{
			transform: rotate(180deg);
			vertical-align: text-bottom;
		}
	}

	.browser__menutitle{ // replaces card__optiontitle
		margin-right: ms(-2);
	}

	.browser__menubutton{ // replaces card__optionbutton
		display: flex;
		justify-content: center;
		width: ms(0);
		margin-right: ms(-9);
		cursor: pointer;

		svg{
			pointer-events: none;
		}

		&:last-of-type{
			margin-right: 0;
		}
	}

	.browser__content{ // replaces card__content in card containing browser
		padding: ms(2) ms(3); // match card__content padding
	}

	.browser__content--h-collapse{
		padding: ms(2) 0;
	}

	// .browser__content--base{
	// 	padding: 0;
	// }

	// .browser__content--border{
	// 	margin-bottom: ms(1);
	// 	border-bottom: 1px solid gc(dl-ash);
	// }

	.browser__row{ // replaces  slat.slat--optionbar
		display: flex;
		align-items: center;
		padding: ms(1) ms(0);
		border-bottom: 1px solid gc(dl-ash);
		// cursor: pointer;

		&:last-of-type{
			border-bottom: 0;
		}
	}

	.browser__row--draggable{ // replaces slat--draggable
		position: relative;

		.browser__rowdragger{ // replaces slat__dragger
			display: block;
			align-self: flex-start;
			width: ms(-3);
			height: map-get($icon-size, small);
			margin: 0 ms(0) 0 0;
			transition: all map-get($transition-duration, fast) $ease-in-out-quad;
			opacity: .3;
			background-image: linear-gradient(transparent, transparent 50%, gc(dl-basalt) 50%, gc(dl-basalt));
			background-size: 100% 4px;
			cursor: grab;

			&:hover{
				opacity: 1;
			}
		}
	}

	.browser__row--draggable.is-dragging{
		border: 1px solid gc(dl-ash);
		border-radius: (map-get($border-radius, small));
		background: rgba(#fff, .9);
		box-shadow: ms(-4) ms(-4) ms(1) rgba(gc(dl-black), .1);

		.browser__rowdragger{
			opacity: 1;
			cursor: grabbing;
		}
	}

	.browser__row--droppable{
		transition: all map-get($transition-duration, fast) $ease-in-out-quad;
	}

	.browser__row--droppable.is-droppable{
		background: rgba(gc(dl-sky), .1);
	}

	.browser__row--droppable.is-dropover{
		z-index: 1;
		background: rgba(gc(dl-sky), .5);
		box-shadow: ms(-6) ms(-6) ms(1) rgba(gc(dl-black), .2);
	}

	.browser__row--noborder{
		border: 0;
	}

	.browser__row--border{
		margin-bottom: ms(1);
		border-bottom: 1px solid gc(dl-ash);

		&:last-of-type{ // have to override basic row styles
			border-bottom: 1px solid gc(dl-ash);
		}
	}

	.browser__row--h-spacious{
		padding: ms(1) ms(4);
	}

	.browser__rowtools,
	.browser__itemtools{ // replaces slat__tools
		align-self: flex-start;
		margin-left: ms(1);

		.no-flexbox &{
			float: right;
		}


		> .button,
		> .textbutton,
		> .splitbutton{
			margin-bottom: 0;
		}
	}

	.browser__rowicon{ // replaces slat__icon
		display: flex;
		align-self: flex-start;
		// min-width: calc(#{ms(-3) + ms(0) + ms(2)}); // slat__icon in the optionbar reserves space for the dragger and the doc icon. since it matches up in horz width w/the card optionbar, we have to guarantee this space whether or not the dragger is there
		margin-right: ms(1);

		.no-flexbox &{
			float: left;
		}

		.icon{
			margin-left: ms(2);
		}
	}

	.browser__rowdragger + .browser__rowicon .icon{ // replaces slat__dragger
		margin-left: 0;
	}

	.browser__rowicon--notebadge{
		position: relative;
	}

	.browser__rownotebadge{
		display: inline-block;
		position: absolute;
		top: -(ms(-3));
		right: -(ms(-3));
		width: ms(2);
		height: ms(2);
		// margin-right: ms(0);
		border-radius: 100%;
		background-color: gc(dl-ruby);
	}

	.browser__rownotenumber{
		@include totalcenter;
		color: $white;
		font-size: ms(-2);
		text-align: center;
	}

	.browser__rowbuffer,
	.browser__itembuffer{ // this is a shim for when slat__tools aren't present, replaces slat__optionbuffer
		width: ms(1);
		margin-left: ms(1);
	}

	.browser__rowitems{ // replaces slat__options
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow: visible;

		@media #{$large-up}{
			flex-direction: row;
		}
	}

	.browser__rowitems--abbr{ // replaces slat__options--abbr
		overflow: hidden;

		.browser__item,
		.browser__itemtext{ // replaces slat__optiontext
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.browser__rowitems--flex-large-up{ // IE11 gets real bent out of shape about flex-direction: column and flex: 1 on small screens ^^^, replaces slat__options--flex-large-up
		display: block;

		@media #{$large-up}{
			display: flex;
		}
	}

	.browser__item{ // replaces slat__option
		display: flex;
		flex: 1 auto;
		margin-bottom: ms(-2);

		@media #{$large-up}{
			width: 25%;
			margin-bottom: 0;
		}
	}

	.browser__item--dropdown{ // slat__option--dropdown
		position: relative;

		.browser__itemicon{ // replaces slat__optionicon
			display: inline-block;
			margin-left: ms(0);
			vertical-align: middle;
		}
	}

	.browser__item--dropdown[aria-expanded='true']{

		.browser__itemicon{
			transform: rotate(180deg);
		}
	}

	.browser__item--primary{ // replaces slat__option--primary

		@media #{$large-up}{
			width: 50%;
		}
	}

	.browser__item--secondary{ // rpelaces slat__option--secondary
		order: 1;
		margin-top: ms(1);

		@media #{$large-up}{
			order: 0;
			margin-top: 0;
		}
	}

	.browser__item--left{ // replaces slat__option--left
		flex: 1;
		width: auto;
		margin-right: ms(0);
	}

	.browser__item--right{ // // replaces slat__option--right
		flex: 0 auto;
		width: auto;

		.browser__rowtools,
		.browser__itemtools{
			min-width: ms(9); // right side of slat needs to be pushed out to align middle icons
			margin-left: 0;

			@media #{$large-up}{
				margin-left: ms(2);
			}
		}
	}

	.browser__itemcontent{ // replaces slat__content
		display: inline-block;
		flex: 1;
		overflow: hidden;


		.no-flexbox &{
			display: block;
		}
	}

	.browser__itemcontent--wrap{ // replaces slat__content--wrap

		.browser__itemtitle, // replaces slat__title
		.browser__itemtext{ // replaces slat__text
			overflow: visible;
		}
	}

	.browser__itemtitle{
		.no-flexbox &{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.browser__itemtitle--icon{ // replaces slat__title--icon

		.browser__itemicon{ // replaces slat__icon
			display: inline-block;
			margin-right: 0;
			margin-left: ms(1);
		}
	}

	.browser__itemtext{ // replaces slat__optiontext
		color: gc(dl-lightgrey);
		font-size: ms(0);
	}

	svg + .browser__itemtext{
		margin-left: ms(0);
	}
}
