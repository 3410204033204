@include exports('components/modal'){

	/***
	 * Modals:
	 */

	/**
	 *
	 *Basic modal:
	 *
	 *`.modal > .modal__inner > .modal__close, .modal__header, .modal__content`
	 *
	 *Need to command the user's attention? Pop a modal! Our modals function more like blockers, in that the
	 * close button is your only option out, and we can exclude it. From a UX perspective, these guys are
	 * a pretty hamfisted tactic, and should be used only when necessary, such as when a new work area is needed,
	 * but it fits within the context of the current page. Situations like adding signing parties in the document builder,
	 * or adjusting document sharing settings, are good examples of appropriate modal usage.
	 *
	 *
	 *		@example
	 *
	 *		div.modal.modal--example.is-visible
	 *			div.modal__inner.modal__inner--example
	 *				div.modal__close
	 *					svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-cross')
	 *				h3.modal__header This is a very basic modal!
	 *				p This is some more text
	 *				div.u-text__small.u-box__mt2 And here's some more text about something.
	 *
	 */

	 /**
 	 *
 	 *Icon modal:
 	 *
 	 *`.modal > .modal__inner.modal__inner--icon > .modal__close, .modal__icon, .modal__content`
 	 *
 	 *If an icon modal is used, the header goes inside a `.modal__content` div, flexed to the right of the icon.
 	 *
 	 *		@example
 	 *		div.modal.modal--example.is-visible
 	 *			div.modal__inner.modal__inner--icon.modal__inner--example
 	 *				div.modal__close
 	 *					svg.icon.icon--tiny
 	 *						use(xlink:href='images/sprites.svg#icon-cross')
 	 *				div.modal__icon
 	 *					svg.icon.icon--large
 	 *						use(xlink:href='images/sprites.svg#icon-lock--color')
 	 *				div.modal__content
 	 *					h3 This is a more complex modal!
 	 *					p This is some more text
 	 *					div.u-text__small.u-box__mt2 And here's some more text about something.
 	 *
 	 */

	// Basic wrapper for modal
	.modal{
		position: fixed;
		z-index: 100; //push above everything
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		opacity: 0;
		background: rgba(gc(dl-black), .9);
	}

	// Modal just for styledown example
	.modal--example{
		position: relative;
		padding: ms(3);
	}


	// if there is a [data-modal-title] child element used to set the
	// modal's title,Hide it
	.modal [data-modal-title]{
		display: none;
	}


	// the actual modal box
	.modal__inner{
		@include totalcenter;
		display: inline-block;
		width: 90%;
		max-width: 90%;
		max-height: 100%;
		padding: ms(2) ms(3);
		overflow-x: visible;
		overflow-y: auto;
		border-radius: map-get($corner-radius, small);
		background: #fff;
		box-shadow: 1px 1px 4px rgba(gc(dl-black), .1);

		@media #{$medium-up} {
			width: auto;
			min-width: ms(15);
		}

		@media #{$large-up}{
			max-height: 90%;
		}

		&::-webkit-scrollbar{
			width: ms(-3);
		}

		&::-webkit-scrollbar-thumb{
			background: gc(dl-grey);
		}
	}

	.modal__inner--restricted{

		@media #{$medium-up}{
			max-width: ms(16);
		}
	}

	// Modal inner for styledown example only

	.modal__inner--example{
		display: block;
		top: 0;
		left: 0;
		width: auto;
		margin: 0 auto;
		transform: translateY(0);

		@media #{$medium-up}{
			width: 20%;
		}
	}

	.modal__inner--base{
		padding: 0;
	}

	.modal__inner--icon{
		display: inline-block;

		@media #{$medium-up}{
			display: flex;
		}

		.modal__icon{
			display: none;

			@media #{$medium-up}{
				display: inline-block;
				width: map-get($icon-size, large);
				height: map-get($icon-size, large);
				margin: ms(0) 0 0;
			}
		}

		.modal__content{

			@media #{$medium-up}{
				flex: 1;
				padding-left: ms(2);
			}
		}
	}

	// close button
	// .modal__close{
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: ms(0);
	// 	height: ms(0);
	// 	padding: ms(0);
	// 	cursor: pointer;
	// 	opacity: .5;
	// 	box-sizing: content-box;

	// 	&:hover{
	// 		opacity: 1;
	// 	}

	// 	svg{
	// 		display: block;
	// 	}
	// }


	.modal__header{
		padding: 0 ms(0) 0 0;
	}

	.modal--wide .modal__inner{
		@media #{$large-up} {
			min-width: ms(18);
		}
	}

	.modal--fullscreen{
		background: gc(dl-cream);
	}



	.modal.is-visible{
		opacity: 1;
	}

	.modal.is-hiding{
		opacity: 0;
	}

}
