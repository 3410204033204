.header{
	margin-bottom: ms(2);
	border-bottom: 1px solid darken($glb-layout-color, 8%);
}

.header__content{
	width: $max-content-width;
	max-width: 100%;
	margin: 0 auto;
}

.header__tools{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	color: gc(dl-lightgrey);
	margin-bottom: ms(1) !important;

	@media #{$large-up}{
		flex-direction: row;
	}
}
