// This is what the generated stuff looks like
//  <span class="tt-dropdown-menu">
// 	{{#datasets}}
// 		<div class="tt-dataset-{{name}}">
// 			{{{header}}}
// 			<span class="tt-suggestions">
// 				{{#suggestions}}
// 					<div class="tt-suggestion">{{{suggestion}}}</div>
// 				{{/suggestions}}
// 				{{^suggestions}}
// 					{{{empty}}}
// 				{{/suggestions}}
// 			</span>
// 			{{{footer}}}
// 		</div>
// 	{{/datasets}}
// </span>


@include exports(vendor / typeahead) {
	.twitter-typeahead{
		width: 100%;
		margin-bottom: $form-spacing;
	}

	.twitter-typeahead--scroll{
		.tt-dropdown-menu{
			max-height: ms(13);
			overflow-y: auto;
		}
	}

	input.tt-input{
		// foundation buster
		// we need the actual imput to have no margin so that the dropdown snuggles up below it
		margin-bottom: 0;
	}

	input.typeahead[disabled],
	input.typeahead[readonly]{
		background-color: $input-bg-color;
	}

	.tt-dropdown-menu{
		display: block;
		min-width: 100%;
		padding: 0;
		border: 1px solid gc(dl-grey);
		background: #fff;
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .1);

		*{
			margin: 0;
		}
	}

	.tt-suggestion{
		padding: ms(-4) ms(0);
		border-bottom: 1px solid gc(dl-ash);

		&:last-child{
			border-bottom: 0;
		}

		&.tt-cursor{
			background: gc(dl-sky);
			color: #fff;
		}
	}
}//exports
