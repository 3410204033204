@import '/src/styles/2.19/helpers/spinners';
@import '/src/styles/2.19/helpers/buttons';

@include exports('components/buttons'){
	/***
	 * Buttons:
	 */

	/**
	 *
	 *Mixins:
	 *
	 *`@mixin button-disabled($color)`
	 *
	 *Returns properties for making a button appear un-clickable, default color is `$glb-button-default-color`.
	 *
	 *
	 */


	/**
	 * Button:
	 *`.button`
	 *
	 * The most basic button class; all others extend this one.
	 *
	 * 	@example
	 *		div.button I am a basic button
	 *
	 */
	button{
		&:focus{
			outline: 0; // Remove highlighted line from button
		}
	}

	.button{
		@include button-global; // see helpers/buttons
		display: inline-flex;
	}

	/**
	 *
	 *Primary button:
	 *`.button.button--primary`
	 *
	 *Denotes the main action for a component or page. As such there should only ever be one in sight.
	 *
	 * 	@example
	 *		div.button.button--primary I am a primary button
	 *
	 */

	// .button--primary{
		// @include button-style(
		// 	$bg: $glb-primary-color,
		// 	$bg-hover: $glb-primary-hover-color
		// );
		// border: 0;
		// color: gc(dl-white);
	// }

	/**
	 *
	 *Front page button:
	 *`.button.button--front-page`
	 *
	 *Kind of hacky, but given the way stylesheets are loaded and swapped out, we need a dedicated class for buttons on login screens
	 *so that they can be changed to match changes in site branding.
	 *
	 * 	@example
	 *		div.button.button--front-page I am a login screen button
	 *
	 */

	.button--front-page{

		// @include button-style(
		// 	$bg: $glb-primary-color,
		// 	$bg-hover: $glb-primary-hover-color
		// );

		// border: 0;
		// color: gc(dl-white);
		text-transform: uppercase;
	}

	// .button--clear{ //@TODO: document this
		// see themes
	// }

	/**
	 *
	 *Warning button:
	 *`.button.button--warning`
	 *
	 *Denotes the action has dire consequences.
	 *
	 * 	@example
	 *		div.button.button--warning Don't push me
	 *
	 */

	// .button--warning{
	// 	@include button-style(
	// 		$bg: gc(dl-ruby) ,
	// 		$border-color: darken(gc(dl-ruby), $button-function-factor),
	// 		$bg-hover: darken(gc(dl-ruby), $button-function-factor)
	// 	);
	// }


	/**
	 *
	 *Disabled button:
	 *`.button.button--disabled`, `.button.is-disabled`
	 *
	 *Disabled buttons _appear_
	 * unclickable. If there is a click event attached to them they will still fire it though.
	 *
	 * 	@example
	 *		div.button.button--disabled Disabled button
	 *		div.button.button--primary.button--disabled Primary disabled button
	 *		div.button.button--warning.button--disabled Warning disabled button
	 *		div.button.button--front-page.button--disabled Front page disabled button
	 *
	 */




	.button.is-disabled,
	.button.button--disabled{
		@include button-disabled;
	}

	// .button.button--primary.is-disabled,
	// .button.button--primary.button--disabled{
	// 	@include button-disabled($glb-primary-color);
	// }

	// .button.button--front-page.is-disabled,
	// .button.button--front-page.button--disabled{
	// 	@include button-disabled($glb-primary-color);
	// }

	// .button.button--warning.is-disabled,
	// .button.button--warning.button--disabled{
	// 	@include button-disabled($glb-primary-color);
	// }

	/**
	 *
	 *Button sizes:
	 *
	 *`.button.button--small` `.button.button--large`
	 *
	 *A smaller and larger version of the main button classes. Don't mix them! that's nasty.
	 *
	 * 	@example
	 *		div
	 *			div.button.button--small I am a small button
	 *			div.button.button--primary.button--small Small primary button
	 *			div.button.button--primary.button--disabled.button--small Small primary disabled button (crazy)
 	 *		 div
	 *			div.button I am a regular button
	 *			div.button.button--primary regular primary button
	 *		 div
	 *			div.button.button--large I am a large button
	 *			div.button.button--primary.button--large large primary button
	 *
	 */

	.button.button--tiny{
		@include button-size($button-tny);
		// padding-bottom: .5rem;
	}

	.button.button--small{
		@include button-size($button-sml);
		// padding-bottom: .5rem;
	}

	.button.button--large{
		@include button-size($button-lrg);
	}


	/**
	 *
	 *Block button:
	 *`.button.button--block`
	 *
	 * Takes any button class and makes it a full width block element.
	 *
	 * 	@example.slim
	 *		div.button.button--block I am a basic example
	 *
	 */

	.button.button--block{
		display: block;

		@at-root button#{&} { // because &button doesnt work well
			// if it is an actual <button> force the size to 100%
			width: 100%;
		}
	}

	/**
	 *
	 *has-spinner state:
	 *
	 *`.button.has-spinner`
	 *
	 *A state where the button indicates the app is processing a task. So just wait, you impatient person.
	 *
	 *NB. this state relies on `loading:inline` being triggered by the app
	 *
	 * 	@example
 	 *		div.button.has-spinner
	 *			div.spinner
	 *			|I am a busy spinner
  	 *		div.button.button--primary.has-spinner
	 *			div.spinner
	 *			|I am a busy primary spinner
	 *		div.button.button--front-page.has-spinner
	 *			div.spinner
	 *			|I am a busy login spinner
	 *		div.button.button--warning.has-spinner
	 *			div.spinner
	 *			|I am a busy dangerous spinner
	 *
	 */

	.button.has-spinner{
		@extend .button, .button--disabled; // controlled in themes
		padding-left: $button-med * 2 + 1rem;
		transition: padding map-get($transition-duration, fast) $ease-out-quad;

		.spinner{
			@include spinner-small;
			margin-right: ms(-2);
			margin-left: -1rem;
		}
	}


	.button.button--primary.has-spinner{
		// @extend .button.button--primary.button--disabled;

		.spinner{
			border-color: #fff;
			border-top-color: transparent;
		}
	}

	.button.button--small.has-spinner{
		padding-left: $button-sml * 2 + 1rem;
	}

	.button.button--front-page.has-spinner{
		// @extend .button.button--primary.button--disabled;

		.spinner{
			border-color: #fff;
			border-top-color: transparent;
		}
	}

	.button.button--warning.has-spinner{

		.spinner{
			border-color: #fff;
			border-top-color: transparent;
		}
	}

	/**
	 *
	 *Icon buttons:
	 *
	 *`.button > svg.icon`
	 *
	 *Simply putting an `.icon` class svg inside a button is enough.
	 *Usually, you'll want an `.icon--tiny` icon.
	 *
	 * 	@example
	 *		div.button.button--primary
	 *			svg.icon.icon--tiny
	 *				use(xlink:href='images/sprites.svg#icon-plus--white')
	 *			|Add
 	 *		div.button
	 *			svg.icon.icon--tiny
	 *				use(xlink:href='images/sprites.svg#icon-cross')
	 *			|Cancel
  	 *		div.button
	 *			|Just a regular button to test it all looks good
	 *
	 */

	.button>.icon{
		margin-right: ms(-2);
		vertical-align: middle;
	}

	/**
	 *
	 *Xero button:
	 *
	 *`.button.button--xero`
	 *
	 *Just a one-off for Xero app integration. Makes the button bg the Xero blue color.
	 *
	 * 	@example
	 *		div.button.button--xero
	 *			svg.icon.icon--medium
	 *				use(xlink:href='images/sprites.svg#icon-xero--white')
	 *			span Connect to Xero
	 */

	.button.button.button--xero{ // gotta cheez it to override theme specificity
		padding: .2rem 2rem;
		border-color: bc(xeroblue);
		background-color: bc(xeroblue);
		color: gc(dl-white);

		span + .icon{
			margin: 0 ms(-2);
		}
	}

	.button.button.button--xero:hover{
		border-color: lighten(bc(xeroblue), 9%);
		background-color: lighten(bc(xeroblue), 9%);
		color: gc(dl-white);
	}

	.button--tab{ //@TODO: document this
		margin-right: ms(3);
		padding-right: ms(-2);
		padding-bottom: ms(0);
		padding-left: ms(-2);

		&:last-of-type{
			margin-right: 0;
		}
	}

	.button--tab>.icon{
		display: block;
		margin: 0 auto ms(0);
		pointer-events: none;
	}

}
