@include exports('components/fixednavs') {
	/***
	*Fixednavs:
	*/

	/**
	 *
	 *Fixed navigation elements:
	 *
	 *`.fixednav`
	 *
	 *A fixed navigation widget, currently used only to mimic Intercom for switching animation in managed partner accounts.
	 *
	 * 	@example
	 *		div.fixednav__example-container
	 *			div.fixednav.fixednav--example.fixednav--bottom-right
	 *				div.fixednav__circle.fixednav__circle--dl.fixednav__circle--front
	 *					div.fixednav__circle-icon
	 *						svg.icon
	 *							use(xlink:href='#icon-dragonlaw')
	 *				div.fixednav__circle.fixednav__circle--partner.fixednav__circle--back
	 *					div.fixednav__circle-icon
	 *						svg.icon
	 *							use(xlink:href='#icon-info--menu')
	 *
	 */

	.fixednav{
		position: fixed;
		z-index: 12;
	}

	// used in styledown example
	.fixednav__example-container{
		position: relative;
		min-height: 150px;
	}

	.fixednav.fixednav--example{
		position: absolute;

		.fixednav__circle--front .fixednav__circle-icon{
			width: 60px;
			height: 60px;
		}
	}

	.fixednav--bottom-right{
		right: 20px;
		bottom: 80px;
	}

	.fixednav__circle{
		z-index: 12;
		width: 60px;
		height: 60px;
		transform-origin: top left;
		transform-style: preserve-3D;
		transition: transform .5s $ease-in-out-quad, background-color .3s $ease-in-out-quad;
		border-radius: 100%;
		cursor: pointer;
	}

	.fixednav__circle--front.fixednav__circle--enter{
		position: absolute;
		z-index: 11; // has to sit above right sidebar z-index of 10, evenm when it's in back
		top: 0;
		right: 0;
		transform: rotate(135deg) scale(.5) translate(50px, -100px);
	}

	.fixednav__circle--back.fixednav__circle--enter{
		position: absolute;
		z-index: 12;
		top: 100px;
		right: -50px;
		transform: rotate(0deg) scale(1) translate(0, 0);
	}

	.fixednav__circle--front,
	.fixednav__circle--front.fixednav__circle--enter-active{
		position: absolute;
		z-index: 12;
		top: 0;
		right: 0;
		box-shadow: 0 6px 6px rgba(71, 71, 62, .3);
	}

	.fixednav__circle--back,
	.fixednav__circle--back.fixednav__circle--enter-active{
		position: absolute;
		z-index: 11; // has to sit above right sidebar z-index of 10, evenm when it's in back
		top: 0;
		right: 0;
		transform: rotate(135deg) scale(.5) translate(50px, -100px);
	}

	.fixednav__circle-icon{
		width: 60px;
		height: 60px;
		padding: 10px;
		transition: width .5s $ease-in-out-quad, height .5s $ease-in-out-quad, transform .5s $ease-in-out-quad;
		color: gc(dl-white);
		text-align: center;

		// .fixednav__circle--front,
		.fixednav__circle--front.fixednav__circle--enter-active &{
			width: 0;
			height: 0;
		}

		.fixednav__circle--back &{
			transform: rotate(-135deg);
		}

		// .fixednav__circle--back.fixednav__circle--enter-active &{
		// 	transform: rotate(-135deg);
		// }

		.fixednav__circle--back.fixednav__circle--enter &{
			transform: rotate(360deg);
		}
	}

	.fixednav__circle--dl.fixednav__circle--front{
		background-color: gc(dl-ruby);
		// background-color: gc(dl-teal); // temporary to match test intercom
	}

	.fixednav__circle--dl.fixednav__circle--back{
		background-color: lighten(gc(dl-ruby), 10%);
		// background-color: lighten(gc(dl-teal), 20%); // temporary to match test intercom
	}

	// fixednav__circle--partner front and back styles are in themes, as they take theme's primary color
	// Temporarily keeping them here for proof-of-concept example

	// .fixednav__circle--partner.fixednav__circle--front{
	// 	background-color: #0071b2; // temporary to match test intercom
	// }

	// .fixednav__circle--partner.fixednav__circle--back{
	// 	background-color: lighten(#0071b2, 20%); // temporary to match test intercom
	// }
}
