@include exports('components/optionbars') {
	/***
	*Optionbars:
	*/

	/**
	 *
	 *Basic optionbar:
	 *
	 *`.optionbar`
	 *
	 *A simple pill in list form that a user can choose from.
	 *
	 * 	@example
	 *		div.card
	 *			div.optionbar I am a basic optionbar
 	 *			div.optionbar I am a basic optionbar
	 *			div.optionbar I am a basic optionbar
	 *
	 */

	.optionbar{
		display: block;
		position: relative;
		margin: 0 0 ms(0);
		padding: ms(1);
		transition: all map-get($transition-duration, fast) $ease-in-out-quad,
					padding  map-get($transition-duration, default) $ease-in-out-quad,
					font-weight 0;
		border: $input-border-width solid gc(dl-grey);
		border-radius: map-get($border-radius, small);
		color: gc(dl-charcoal);
		font-size: ms(1);
		cursor: pointer;
	}

	.optionbar:hover{
		// border-color: $glb-primary-color;
		background: gc(dl-white);
		color: gc(dl-black);
	}


	//specificty hack to avoid using important when the optionbar is a check/radio
	label.optionbar.optionbar{
		display: block;
		margin: 0 0 ms(0);
	}

	/**
	 *
	 *Optionbar with icon:
	 *
	 *`.optionbar > .optionbar__icon`
	 *
	 *Use to place an icon before the text in an optionbar.
	 *
	 * 	@example
	 *		div.card
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				|I am an optionbar with an icon
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-document--color')
	 *				|I am an optionbar with an icon
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-draft--color')
	 *				|I am an optionbar with an icon
	 *		div.card
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				|I am an optionbar with a smaller icon
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-document--color')
	 *				|I am an optionbar with a smaller icon
	 *			div.optionbar
	 *				div.optionbar__icon
	 *					svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-draft--color')
	 *				|I am an optionbar with a smaller icon
	 *
	 */

	.optionbar__icon{
		display: inline-block;
		padding-right: ms(1);
	 }

	/**
	 *
	 *Checkbox and radio optionbars:
	 *
	 *`.optionbarinput + .optionbar--radio`, `.optionbarinput + .optionbar--checkbox`
	 *
	 *Check/radio optionbars use the [checkbox hack](http://codepen.io/grgcnnr/pen/wBRddx)
	 *to visually hide native radio and checkbox inputs.
	 *Give the input a class of `.optionbarinput` to hide it and put it *directly before the optionbar it affects.
	 *
	 * 	@example
	 *		div.card
	 *			input#optionbarcheckbox1.optionbarinput(type='checkbox')
	 *			label.optionbar.optionbar--checkbox(for='optionbarcheckbox1')
	 *				|I am a checkbox optionbar
 	 *			input#optionbarcheckbox2.optionbarinput(type='checkbox' checked)
 	 *			label.optionbar.optionbar--checkbox(for='optionbarcheckbox2')
 	 *				|I am a checkbox optionbar
	 *			input#optionbarcheckbox3.optionbarinput(type='checkbox')
	 *			label.optionbar.optionbar--checkbox(for='optionbarcheckbox3')
	 *				|I am a checkbox optionbar
	 *
 	 *		div.card
	 *			input#optionbarradio1.optionbarinput(type='radio' name='obradiodemo' checked)
	 *			label.optionbar.optionbar--radio(for='optionbarradio1')
	 *				|I am a radio optionbar
 	 *			input#optionbarradio2.optionbarinput(type='radio' name='obradiodemo')
 	 *			label.optionbar.optionbar--radio(for='optionbarradio2')
 	 *				|I am a radio optionbar
	 *			input#optionbarradio3.optionbarinput(type='radio' name='obradiodemo')
	 *			label.optionbar.optionbar--radio(for='optionbarradio3')
	 *				|I am a radio optionbar
	 *
	 */

	// optionbarinput just hides the default input element
	.optionbarinput{
		visibility: hidden;
		position: absolute;
	}


	// all optionbars have the same highlighted state when checked
	.optionbarinput:checked + .optionbar{
		// border-color: $glb-primary-color;
		background: gc(dl-white);
		color: gc(dl-black);
		font-weight: bold;
		// box-shadow: 0 0 4px rgba($glb-primary-color, .5);
	}


	// foundation does some useful stuff when you put labels next to
	// inputs but we dont want it here

	input[type="radio"] + .optionbar--radio,
	input[type="radio"] + .optionbar--tabset,
	input[type="checkbox"] + .optionbar--checkbox{
		margin-right: 0;
		margin-left: 0;
	}

	//
	// Radio mode
	//

	.optionbar--radio{
		padding-left: ms(1) + ms(1) + map-get($icon-size, tiny);
		background: gc(dl-cream);

		&::before{
			@include ui-radiobutton;
			content: '';
			position: absolute;
			top: ms(2);
			left: ms(2);
			width: ms(1);
			height: ms(1);
			margin-top: 0;
			transition: all map-get($transition-duration , fast) $ease-in-out-quad;
		}

		// &:hover::before{
		// 	@include ui-radiobutton-hover;
		// }
	}

	.optionbarinput:checked + .optionbar--radio::before{
		@include ui-radiobutton-checked;
	}



	//
	//  Checkbox mode
	//

	.optionbar--checkbox{
		padding-left: ms(1) * 3;
		background: gc(dl-cream);

		&::before{
			@include ui-checkbox;
			position: absolute;
			left: ms(2);
		}

		// &:hover::before{
		// 	@include ui-checkbox-hover;
		// }
	}

	.optionbarinput:checked + .optionbar--checkbox::before{
		@include ui-checkbox-checked;
	}




	/**
	 *
	 *Tabset optionbars:
	 *
	 *`.optionbarwrapper > .optionbarinput + .optionbar--tabset`
	 *
	 *Tabsets are a special type of radio optiobar where all the options are presented
	 *together on one line, like tabs! Only tabsets need the optionbarwrapper but you can use it
	 * on all of them if you want.
	 *
	 * 	@example
 	 *		div.card
	 *			div.optionbarwrapper
	 *				input#optionbartabset1.optionbarinput(type='radio' name='obtabdemo' checked)
	 *				label.optionbar.optionbar--tabset(for='optionbartabset1')
	 *					|I am a radio optionbar
 	 *				input#optionbartabset2.optionbarinput(type='radio' name='obtabdemo')
 	 *				label.optionbar.optionbar--tabset(for='optionbartabset2')
 	 *					|I am a radio optionbar
	 *				input#optionbartabset3.optionbarinput(type='radio' name='obtabdemo')
	 *				label.optionbar.optionbar--tabset(for='optionbartabset3')
	 *					|I am a radio optionbar
	 *
	 */

	.optionbarwrapper{
		display: block;

		@media #{$medium-up}{
			display: flex;
		}
	}

	.optionbar--tabset{
		background: gc(dl-cream);
		text-align: center;

		@media #{$medium-up}{
			flex: 1;
			border-right-width: 0;


			&:last-of-type{
				border-right-width: $input-border-width;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:first-of-type{
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&:not(:first-of-type):not(:last-of-type){
				border-radius: 0;
			}
		}

	}

	// if a tab is selected, we want its whole border to be blue and visible
	.optionbarinput:checked + .optionbar--tabset{
		border-right-width: $input-border-width;
	}

	// Which means we need to make sure any adjacent tabs have no left border
	// so we don't get double thickness

	.optionbarinput:checked + .optionbar--tabset + .optionbar--tabset,
	.optionbarinput:checked + .optionbar--tabset + .optionbarinput + .optionbar--tabset{

		@media #{$medium-up}{
			border-left-width: 0;
		}
	}

	/**
	 *
	 *Tabset optionbar with icon:
	 *
	 *`.optionbarwrapper > .optionbarinput + .optionbar--tabset + .optionbar__icon`
	 *
	 *Same as tabset above, but with an icon preceding text.
	 *
	 * 	@example
	 *		div.card
	 *			div.optionbarwrapper
	 *				input#optionbartabset4.optionbarinput(type='radio' name='obtab_icondemo1' checked)
	 *				label.optionbar.optionbar--tabset(for='optionbartabset4')
	 *					div.optionbar__icon
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-folder--color')
	 *					|I am a radio optionbar w/icon
 	 *				input#optionbartabset5.optionbarinput(type='radio' name='obtab_icondemo1')
 	 *				label.optionbar.optionbar--tabset(for='optionbartabset5')
 	 *					div.optionbar__icon
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-document--color')
 	 *					|I am a radio optionbar w/icon
	 *				input#optionbartabset6.optionbarinput(type='radio' name='obtab_icondemo1')
	 *				label.optionbar.optionbar--tabset(for='optionbartabset6')
	 *					div.optionbar__icon
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-draft--color')
	 *					|I am a radio optionbar	w/icon
	 *			div.optionbarwrapper
	 *				input#optionbartabset7.optionbarinput(type='radio' name='obtab_icondemo1')
	 *				label.optionbar.optionbar--tabset(for='optionbartabset7')
	 *					div.optionbar__icon
	 *						svg.icon.icon--small
	 *							use(xlink:href='images/sprites.svg#icon-folder--color')
	 *					|I am a radio optionbar w/small icon
 	 *				input#optionbartabset8.optionbarinput(type='radio' name='obtab_icondemo1')
 	 *				label.optionbar.optionbar--tabset(for='optionbartabset8')
 	 *					div.optionbar__icon
	 *						svg.icon.icon--small
	 *							use(xlink:href='images/sprites.svg#icon-document--color')
 	 *					|I am a radio optionbar w/small icon
	 *				input#optionbartabset9.optionbarinput(type='radio' name='obtab_icondemo1')
	 *				label.optionbar.optionbar--tabset(for='optionbartabset9')
	 *					div.optionbar__icon
	 *						svg.icon.icon--small
	 *							use(xlink:href='images/sprites.svg#icon-draft--color')
	 *					|I am a radio optionbar	w/small icon
	 *
	 */



	/**
	 *
	 *Tabset optionbar with active/inactive icon:
	 *
	 *`.optionbarwrapper > .optionbarinput + .optionbar--tabset + .optionbar__icon + .optionbar__icon--active`and
	 *`.optionbarwrapper > .optionbarinput + .optionbar--tabset + .optionbar__icon + .optionbar__icon--inactive`
	 *
	 *Icon tabset with different icons for selected/unselected states. Include both active and inactive icons
	 *in markup. One will not be displayed, depending on selection.
	 *
	 * 	@example
	 *		div.card
	 *			div.optionbarwrapper
	 *				input#optionbartabset10.optionbarinput(type='radio' name='obtab_icondemo2' checked)
	 *				label.optionbar.optionbar--tabset(for='optionbartabset10')
	 *					div.optionbar__icon.optionbar__icon--inactive
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-user--mono')
	 *					div.optionbar__icon.optionbar__icon--active
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-user--color')
	 *					|I am a radio optionbar w/icon
	 *				input#optionbartabset11.optionbarinput(type='radio' name='obtab_icondemo2')
	 *				label.optionbar.optionbar--tabset(for='optionbartabset11')
	 *					div.optionbar__icon.optionbar__icon--inactive
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-user--mono')
	 *					div.optionbar__icon.optionbar__icon--active
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-user--color')
	 *					|I am a radio optionbar w/icon
	 *
	 */

	.optionbarinput:checked + .optionbar{
		.optionbar__icon--inactive{
			display: none;
		}

		.optionbar__icon--active{
			display: inline-block;
		}
	}

	.optionbarinput + .optionbar{
		.optionbar__icon--inactive{
			display: inline-block;
		}

		.optionbar__icon--active{
			display: none;
		}
	}




	/**
	 *
	 *Optionbar inputs:
	 *
	 *`.optionbar > .optionbar__input`
	 *
	 *Sometimes (like with the radioother block) we want to be able
	 *to set an option and then collect input inside the optionbar.
	 *
	 * 	@example
 	 *		div.card
	 *			input#optionbarinput1.optionbarinput(type='radio' name='obradiodemo' checked)
	 *			label.optionbar.optionbar--radio(for='optionbarinput1')
	 *				|I am a radio optionbar
 	 *			input#optionbarinput2.optionbarinput(type='radio' name='obradiodemo')
 	 *			label.optionbar.optionbar--radio(for='optionbarinput2')
 	 *				|I am a radio optionbar
	 *			input#optionbarinput3.optionbarinput(type='radio' name='obradiodemo')
	 *			label.optionbar.optionbar--radio(for='optionbarinput3')
	 *				input.optionbar__input(type='text' placeholder='I add your own option')
	 */
	// input inside optionbar should be just a line
	// we need to push up the specificity a bit
	.optionbar .optionbar__input{
		display: inline-block;
		height: auto;
		margin: 0;
		padding: 0;
		border-width: 0 0 1px;
		background-color: transparent;
		box-shadow: none;
	}

	/**
	 *
	 *has-spinner state:
	 *
	 *`.optionbar.has-spinner`
	 *
	 *As with buttons, show a spinner that lets you know the optionar clicked is doing something.
	 * Spinner state optionbars don't work woth radio or checkbox optionbars...
	 * and you shouldnt be using those for things that make api calls anyway.
	 *
	 * 	@example
	 *		div.card
	 *			div.optionbar.has-spinner
	 *				div.spinner
	 *				|I am a basic example
	 *
	 */

	.optionbar.has-spinner{
		padding-left: 2rem;
		transition: padding map-get($transition-duration, fast) $ease-out-quad;

		.spinner{
			@include spinner-small;
			margin-right: ms(-2);
			margin-left: -1rem;
		}
	}


} //exports
