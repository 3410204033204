// See components/spinners for usage
@mixin spinner{
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-image: url('/images/spinner.gif');
	background-repeat: none;
	background-size: cover;
	user-select: none;
	vertical-align: middle;

	&::before,
	&::after{
		display: none;
	}

	.cssanimations &{
		@include animation-spin;
		animation-iteration-count: infinite;
		border: 5px solid gc(dl-grey);
		border-radius: 100%;
		border-top-color: transparent;
		background: none;
	}
}


@mixin spinner-small{
	width: 1rem;
	height: 1rem;

	.cssanimations &{
		border-width: 3px;
	}
}
