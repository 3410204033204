@include exports('components/editlinks'){
	/***
	* Edit Link:
	*/

	/**
	 *
	 *Basic edit link:
	 *
	 *`.editlink`
	 *
	 * A little edit nugget to use with edit in place titlebars and other places when you need an edit thing.
	 *
	 * 	@example
	 *		.editlink
	 *			svg.icon.icon--tiny
	 *				use(xlink:href='images/sprites.svg#icon-edit')
	 *			| Edit this thing
	 *
	 */


	.editlink{
		display: inline-block;
		height: ms(1);
		margin: 0 0 0 ms(1);
		opacity: .75;
		color: gc(dl-charcoal);
		font-size: ms(0);
		font-weight: normal;
		text-decoration: underline;
		white-space: nowrap; // make sure the icon and text stay on one line
		cursor: pointer;

		&:hover{
			opacity: 1;
		}

		.icon{
			margin: 0 3px 0 0;
			vertical-align: text-bottom;
		}
	}
}
