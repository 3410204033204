@include exports('utilities/animations'){

	.u-animation__spin{
		@include animation-spin;
		// animation: spin 1s linear;
	}

	.u-animation__pulse{
		@include animation-pulse;
		// animation: pulse 1s linear;
	}

	.u-animation__fadeInDown{
		@include animation-fadeInDown;
		// animation: fadeInDown map_get($transition-duaration, fast) $ease-out-cubic;
	}

	.u-animation__fadeOutDown{
		@include animation-fadeOutDown;
		// animation: fadeInDown map_get($transition-duaration, fast) $ease-in-cubic;
	}

	.u-animation__fadeInRight{
		@include animation-fadeInRight;
		// animation: fadeInDown map_get($transition-duaration, fast) $ease-out-cubic;
	}

	.u-animation__fadeInLeft{
		@include animation-fadeInLeft;
		// animation: fadeInLeft map_get($transition-duaration, fast) $ease-out-cubic;
	}

	.u-animation__fadeInUp{
		@include animation-fadeInUp;
		// animation: fadeInUp  map_get($transition-duaration, fast) $ease-out-cubic;

	}

	.u-animation__errorBg{
		@include animation-errorBg;
		// animation: errorBg 1s linear;
	}



}
