//
//  HACKS
//  If you don't know where to put something, Then put it in here and OPEN A TICKET
//

.message-plain{
	.modal &{
		@media #{$medium-up}{
			width: ms(15);
		}
	}
}

// ticket #1619:
// overriding the span that gets thrown into the
// help text with a set line height and font size.

.u-text__help--docbuilder{

	span{
		// scss-lint:disable ImportantRule
		font-size: .8rem !important;
		line-height: 1.3 !important;
		// scss-lint:enable ImportantRule
	}
}

.validation-message{
	margin: 0 0 $form-spacing;
	padding-top: ms(-3);
	list-style: none;
	transition: all map-get($transition-duration, default) $ease-in-out-quad;
	opacity: 1;
	color: gc(dl-ruby);
	font-size: ms(0);
	}

//Some temporary Hacks for Web admin app.
body.zegal-admin{
	//Header Card
	.card.card--collapse-bottom.card--spacious{
		padding: 0;
		padding-bottom: ms(1);
		background: none;
		box-shadow: none;
	}

	//Temporary css header-tools
	.header__tools
	{
		// margin-bottom: ms(2);

		.searchbar__wrapper
		{
			flex-basis: 100%;
			width: 100%;

			.searchbar
			{
				border: 0;
				background: transparent;

				.searchbar__innerwrapper
				{
					width: 100%;
				}

				input.searchbar__input{
					padding-right: ms(3);
					padding-left: ms(3);
					border-bottom: 2px solid gc(dl-grey);

					&:focus{
						background: transparent;
					}
				}

				.searchbar__action{
					&.float-left{
						position: absolute;
						padding-left: 0;
					}

					&.float-right{
						position: absolute;
						right: 0;
						padding-right: 0;
					}

					svg.icon{
						@include icon-square(1rem);
					}
				}
			}
		}

		@media #{$large-up}{
			margin: 0;

			.searchbar__wrapper{
				flex-basis: ms(14);
			}
		}
	}

	.login-hacks-for-admin-only{
		width: 100%;
		margin: 0;

		input {
			margin: 0
		}

		button label{
			color: inherit;
			margin-bottom: 0
		}

		@media #{$medium-up}{
			width: calc(100% + 300px);
			margin-left: -300px;
		}
	}
}

.admin-hamburger{
	position: absolute;
	top: 20px;
	right: 20px;

	.admin-line {
		display: block;
		width: 30px;
		margin: 4px auto;
		padding: 0;
		transition: .3s;
		border: 2px solid gc(dl-grey);
		background: gc(dl-grey);
		color: gc(dl-white);

		@media #{$medium-up}{
			display: none;
		}
	}
}

.admin-hamburger.active {
	.admin-line:nth-child(1) {
		margin-top: 14px;
		transform: rotate(45deg);
	}

	.admin-line:nth-child(2) {
		opacity: 0;
	}

	.admin-line:nth-child(3) {
		margin-top: -16px;
		transform: rotate(-45deg);
	}
}

// Hacked Cross button
.modal__close{
	position: absolute;
	top: 13px;
	right: 16px;
	width: 18px;
	height: 18px;
	opacity: .5;
	cursor: pointer;

	svg {
		display: none;
	}

	&:hover {
		transition: .2s;
		opacity: .7;
	}

	&::before,
	&::after {
		content: ' ';
		position: absolute;
		left: 8px;
		width: 2px;
		height: 100%;
		background-color: gc(dl-black);
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.modal__hover_state{
    &:hover {
        background-color: rgba(76, 186, 192, 0.24) !important;
    }
}
