@include exports('utilities/boxmodels'){
	/**
	 *
	 *Boxmodel & float utilities:
	 *
	 *`.u-box__block` sets display:block
	 *
 	 *`.u-box__inline` sets display:inline
 	 *
	 *`.u-box__inlineblock` sets display:inline-block
	 *
	 *`.u-box__fr` & `.u-box__fl` set float:right and left respectively
	 *
	 *`.u-box__fr--medium-up` & `.u-box__fl--medium-up` set float:right and left respectively on medium size screens and larger. Small screens will display:block
	 *
	 *`.u-box__100` sets width to 100%
	 *
	 */

	//scss-lint:disable ImportantRule

	.u-box__block{
		display: block;
	}

	.u-box__inline{
		display: inline;
	}

	.u-box__inlineblock{
		display: inline-block !important;
	}

	.u-box__inlineblock--medium-up{

		@media #{$medium-up}{
			display: inline-block !important;
		}
	}

	.u-box__inlineblock--large-up{

		@media #{$large-up}{
			display: inline-block !important;
		}
	}

	// remember that float left/right implies display:block
	.u-box__fr{
		float: right;
	}

	.u-box__fl{
		float: left;
	}

	.u-box__fr--medium-up{

		@media #{$medium-up}{
			float: right;
		}
	}

	.u-box__fl--medium-up{

		@media #{$medium-up}{
			float: left;
		}
	}

	.u-box__100{
		width: 100%;
	}

	.u-box__50--medium-up{

		@media #{$medium-up}{
			display: inline-block !important;
			width: 48% !important;

			&:first-of-type{
				margin-right: ms(1);
			}
		}
	}



	// Margin
	/**
	 *Margin utilities
	 *
	 *`.u-box__m0` Set all margins to 0
	 *
	 *`.u-box__mt[0|ms0|1|2|3|4|5|6]` Set margin-top
	 *
	 *`.u-box__mb[0|msn2|ms0|1|2|3|4|5|6]` Set margin-bottom
	 *
	 *`.u-box__ml[0|1|2]`  Set margin-left
	 *
 	 *`.u-box__mr[0|1|2|3|4]`  Set margin-right
	 *
	 *`.u-box__mt[msn4]--small-only` Set mrgin-top for small screens only
 	 *
	 * `.u-box__mt[2]--medium-down`	Set margin-top for medium screens and down
	 *
	 *`.u-box__mr[1]--medium-up`  Set margin-right for medium screens and up
	 *
	 * `.u-box__mb[1|2]--medium-down`	Set margin-bottom for medium screens and down
	 *
	 *`.u-box__ml[1]--medium-up`  Set margin-left for medium screens and up
	 *
	 */

	// all

	.u-box__m0{
		margin: 0;
	}

	// top

	.u-box__mt0{
		margin-top: 0 !important;
	}

	.u-box__mtms0{
		margin-top: ms(0);
	}

	.u-box__mtmsn4--small-only{
		margin-top: ms(-4);

		@media #{$medium-up}{
			margin-top: 0;
		}
	}

	.u-box__mt1{
		margin-top: ms(1) !important;
	}

	.u-box__mt1--small-only{
		margin-top: ms(1);

		@media #{$medium-up}{
			margin-top: 0;
		}
	}

	.u-box__mt2{
		margin-top: ms(2);
	}

	.u-box__mt2--medium-down{
		margin-top: ms(2);

		@media #{$large-up}{
			margin-top: 0;
		}
	}

	.u-box__mt3{
		margin-top: ms(3);
	}

	.u-box__mt4{
		margin-top: ms(4);
	}

	.u-box__mt5{
		margin-top: ms(5);
	}

	.u-box__mt6{
		margin-top: ms(6);
	}

	// bottom
	.u-box__mb0{
		margin-bottom: ms(0) !important;
	}

	.u-box__mbmsn2{
		margin-bottom: ms(-2);
	}

	.u-box__mbms0{
		margin-bottom: ms(0);
	}

	.u-box__mb1{
		margin-bottom: ms(1);
	}

	.u-box__mb1--medium-down{
		margin-bottom: ms(1);

		@media #{$large-up}{
			margin-bottom: 0;
		}
	}

	.u-box__mb2{
		margin-bottom: ms(2);
	}

	.u-box__mb2--medium-down{
		margin-bottom: ms(2);

		@media #{$large-up}{
			margin-bottom: 0;
		}
	}

	.u-box__mb3{
		//scss-lint:disable ImportantRule
		margin-bottom: ms(3) !important;
		//scss-lint:enable ImportantRule
	}

	.u-box__mb4{
		//scss-lint:disable ImportantRule
		margin-bottom: ms(4) !important;
		//scss-lint:enable ImportantRule
	}

	.u-box__mb5{
		margin-bottom: ms(5);
	}

	.u-box__mb6{
		margin-bottom: ms(6);
	}

	// left

	.u-box__ml0{
		margin-left: 0;
	}

	.u-box__mlms0{
		margin-left: ms(0);
	}

	.u-box__ml1{
		margin-left: ms(1);
	}

	.u-box__ml1--medium-up{

		@media #{$medium-up}{
			margin-left: ms(1);
		}
	}

	.u-box__ml2{
		margin-left: ms(2);
	}

	.u-box__ml3{
		margin-left: ms(3);
	}

	.u-box__ml4{
		margin-left: ms(4);
	}

	// right
	.u-box__mr0{
		margin-right: 0  !important;
	}

	.u-box__mrms0{
		margin-right: ms(0) !important;
	}

	.u-box__mr1{
		margin-right: ms(1) !important;
	}

	.u-box__mr1--medium-up{

		@media #{$medium-up}{
			margin-right: ms(1) !important;
		}
	}

	.u-box__mr2{
		margin-right: ms(2);
	}

	.u-box__mr3{
		margin-right: ms(3);
	}

	.u-box__mr7--large-up{

		@media #{$large-up}{
			margin-right: ms(7);
		}
	}



	// Padding
	/**
	 *Padding utilities
	 *
 	 *`.u-box__p[0|ms0--large-up|1|2]` Set padding
 	 *
 	 *`.u-box__pt[0|p5rem|ms0|1|2|3|4|5]` Set padding-top
	 *
	 *`.u-box__pr[0|1|2|3|4]` Set padding-right
	 *
	 *`.u-box__pb[0|1|2|3]` Set padding-bottom
	 *
	 *`.u-box__pl[0|ms0|1|2|3|4]` Set padding-left
	 */

	// .u-box__p0{
	// 	padding: 0 !important;
	// }

	.u-box__pms0{
		padding: ms(0);
	}

	.u-box__p1{
		padding: ms(1);
	}

	.u-box__p2{
		padding: ms(2);
	}

	.u-box__pt0{
		padding-top: 0 !important;
	}

	// hacky, but some foundation sizes add .5rem with great specificity, e.g. small textbuttons
	.u-box__ptp5rem{
		padding-top: .5rem;
	}

	.u-box__ptms0--large-up{

		@media #{$large-up}{
			padding-top: ms(0);
		}
	}

	.u-box__pt1{
		padding-top: ms(1);
	}

	.u-box__pt2{
		padding-top: ms(2);
	}

	.u-box__pt3{
		padding-top: ms(3);
	}

	.u-box__pt4{
		padding-top: ms(4);
	}

	.u-box__pt5{
		padding-top: ms(5);
	}

	.u-box__pr0{
		padding-right: 0 !important;
	}

	.u-box__pr1{
		padding-right: ms(1);
	}

	.u-box__pr2{
		padding-right: ms(2);
	}

	.u-box__pr3{
		padding-right: ms(3);
	}

	.u-box__pr4{
		padding-right: ms(4);
	}

	.u-box__pb0{
		padding-bottom: 0 !important;
	}

	.u-box__pb1{
		padding-bottom: ms(1);
	}

	.u-box__pb2{
		padding-bottom: ms(2);
	}

	.u-box__pb3{
		padding-bottom: ms(3);
	}

	.u-box__pl0{
		padding-left: 0 !important;
	}

	.u-box__plms0{
		padding-left: ms(0);
	}

	.u-box__pl1{
		padding-left: ms(1);
	}

	.u-box__pl2{
		padding-left: ms(2);
	}

	.u-box__pl3{
		padding-left: ms(3);
	}

	.u-box__pl4{
		padding-left: ms(4);
	}

	.u-box__border-top{
		border-top: 1px solid gc(dl-ash);
	}

	.u-box__pt05{
		padding-top: .5rem;
	}

	.u-box__pb05{
		padding-bottom: .5rem;
	}

	.u-box__p05{
		padding: .5rem;
	}

	.u-box__mt05{
		margin-top: .5rem;
	}

	.u-box__mb05{
		margin-bottom: .5rem;
	}

	.u-box__m05{
		margin: .5rem;
	}


	 /**
	 *
	 *layout utilities:
	 *
	 *`.u-box__centered` Restricts the width of the element and centers it horizontally in its container.
	 * `.u-box__restricted` gives you the same box size, positioned left as normal.
	 *
	 *
	 */

	.u-box__centered{
		margin-right: auto;
		margin-left: auto;

		@media #{$medium-up}{
			width: ms(16);
			max-width: ms(16);
		}
	}

	.u-box__restricted{

		@media #{$medium-up}{
			width: ms(16);
		}
	}

	.u-box__layout-override{
		height: calc(100vh - #{$topnav-height});
		padding: ms(6) 0;
	}

	.u-box__100height{
		height: 100%;
	}

	.u-box__smallcenter{
		width: ms(15);
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
	}

	/**
	 *
	 *flexbox layout utilities:
	 *
	 *`.u-box__flex` Sets display:flex.
	 *`.u-box__flex--medium-up` Sets display:block for small screens, display:flex for medium and up.
	 *`.u-box__flex1` Sets flex-shrink:1, flex-grow:1, flex-basis: 0% on an item in a flex container. Can help simulate float:left and float:right in a flexbox.
	 *`.u-box__flex0auto` This is the same as flex: initial; and the shorthand for the default value: flex: 0 1 auto. It sizes the item based on its width/height properties (or its content if not set). (https://css-tricks.com/almanac/properties/f/flex/)
	 *`.u-box__alignself-end` Works like ertical align bottom for flexbox items. Assumes item's container is display: flex
	 *`.u-box__alignself-start` Works like vertical align top for flexbox items. Assumes item's container is display: flex
	 *`.u-box__alignself-center` Works like vertical align middle for flexbox items. Assumes item's container is display: flex
	 *`.u-box__alignitems-center` Sets align-items:center. Use on flex container to vertically center items in row
	 *`.u-box__alignitems-baseline` Sets align-items:baseline. Use on flex container to vertically position items at top
	 *
	 */

	.u-box__flex{
		display: flex;
	}

	.u-box__flex--medium-up{
		display: block;

		@media #{$medium-up}{
			display: flex;
		}
	}

	.u-box__flex--large-up{
		display: block;

		@media #{$large-up}{
			display: flex;
		}
	}

	.u-box__flex1{
		flex: 1;
	}

	.u-box__flex0auto{
		flex: 0 auto;
	}

	.u-box__alignitems-center{
		align-items: center;
	}

	.u-box__alignitems-baseline{
		align-items: baseline;
	}

	.u-box__alignself-end{
		align-self: flex-end;
	}

	.u-box__alignself-start{
		align-self: flex-start;
	}

	.u-box__alignself-center{
		align-self: center;
	}

	.u-box__justify-space-between{
		justify-content: space-between;
	}

	.u-box__justify-flex-end{
		justify-content: flex-end;
	}
}
