@include exports('components/switchers'){
	/***
	 *
	 *Switchers:
	 *
	 *The switcher component is styled like a select, but uses Foundation dropdowns instead.
	 * The idea is to have a component that functions like a select element, but with greater
	 * versatility in its styling. It is used in the app in ht dropdowns to switch jurisdictions
	 * on the Create page, and to switch organisations in the topnav. For an inline label, like
	 * it appears in the jursidiction switcher, add a `.switcher__label` div adjacent to the `.switcher`.
	 */

	/**
	 *Basic switcher:
	 *
	 *`.switcher__label` (optional) + `.switcher` > `.switcher__text`, `.switcher__arrow`
	 *
	 * 	@example
	 *		div.switcher__label Label
	 *		div.switcher.switcher--example(data-dropdown='example-switcher-dropdown')
	 *			div.switcher__text Selected option
	 *			div.switcher__arrow
	 *			ul#example-switcher-dropdown.dropdown.dropdown--selectable.dropdown--switcher.dropdown--juriswitcher.dropdown--nopip
	 *				li.dropdown__item.is-active Selected option
	 *				li.dropdown__item An option
	 *				li.dropdown__item Another option
	 *
	 */

	.switcher{
		display: inline-block;
		position: relative;
		// width: auto;
		width: 100%;
		// margin-bottom: ms(1);
		padding: ms(-4) ms(1);
		border: 1px solid gc(dl-iron);
		border-radius: map-get($border-radius, small);
		background: gc(dl-white);
		color: gc(dl-mediumgrey);
		cursor: pointer;

		@media #{$medium-up}{
			// max-width: ms(12);
			margin-bottom: 0;
		}
	}

	.switcher--example .switcher__text{ // styledown only - example needs some extra room
		width: calc(100% - (#{ms(-2)} + 14px)); // take up full width minus arrow width and arrow left margin
		max-width: calc(100% - (#{ms(-2)} + 14px)); // take up full width minus arrow width and arrow left margin
	}

	.switcher[aria-expanded='true']{
		border-bottom: 1px solid transparent;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		.switcher__arrow{
			// margin-top: ms(-5);
			margin-bottom: -(ms(-8));
			transform: rotate(135deg);
		}
	}

	.switcher--org{
		width: 100%;
		padding: ms(-3) ms(1);
		border: 1px solid currentcolor;
		// background: $topnav-bgcolor;
		color: currentcolor;
		cursor: pointer;

		body.has-sidebar-left &{ // @todo Delete this when we get rid of 1.0
			background: $topbar-bgcolor;
		}

		@media #{$large-up}{
			width: auto;
		}
	}

	.switcher__label{
		display: inline-block;
		margin: ms(0) ms(1) ms(-4) 0;
		// margin-right: ms(1);
		// margin-bottom: ms(1);

		// @media #{$medium-up}{
		// 	margin-bottom: ms(-4);
		// }
	}

	.switcher__text{
		display: inline-block;
		width: calc(100% - (#{ms(-2)} + 7px)); // take up full width minus arrow width and arrow left margin
		max-width: calc(100% - (#{ms(-2)} + 7px)); // take up full width minus arrow width and arrow left margin
		margin-bottom: -5px;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: text-top;
		white-space: nowrap;
	}

	.switcher--org .switcher__text{
		// width: calc(100% - #{ms(1) + ms(-4)}); // subtract width of swtcher arrow and arrow margin
		max-width: calc(100% - (#{ms(-2)} + 7px)); // take up full width minus arrow width and arrow left margin

		@media #{$large-up}{
			width: ms(10);
			max-width: ms(10);
		}

		.topnav__orgswitcher--compact &{

			@media #{$large-up}{
				width: ms(7);
				max-width: ms(7);
			}

			@media #{$xlarge-up}{
				width: ms(8);
				max-width: ms(8);
			}
		}
	}

	.switcher__arrow{
		content: '';
		display: inline-block;
		width: 7px;
		height: 7px;
		margin-bottom: ms(-5);
		margin-left: ms(-2);
		transform: rotate(-45deg);
		border: solid currentcolor;
		border-width: 0 0 2px 2px;
		border-radius: 0 0 0 2px;
		vertical-align: middle;
		// flex: 1;

		// .open &{
		// 	transform: rotate(180deg);
		// }
	}
}
