
@include exports('components/texticons'){
	/***
	 *Text Icons:
	 */

	/**
	 *
	 *Initials:
	 *
	 *`.texticon`,  `.texticon--large`
	 *
	 *We use texticons for user avatar icons since we havent actually implemented avatars.
	 *
	 *Usually you'll be using this with / generating a background color from the colors utility `u-colorbg__{color}`
	 *
	 * 	@example
 	 *		div.texticon.u-colorbg__dl-ruby GC
 	 *		br
	 *		div.texticon.texticon--large.u-colorbg__dl-grannysmith NY
	 *
	 */

	.texticon{
		display: flex;
		align-items: center;
		justify-content: center;
		width: map-get($icon-size, medium);
		height: map-get($icon-size, medium);
		background: gc(dl-grey);
		color: gc(dl-white);
		font-size: ms(1);
	}


	.texticon--large{
		width: map-get($icon-size, large);
		height: map-get($icon-size, large);
		font-size: ms(2);
	}

	.texticon--small{
		width: map-get($icon-size, small);
		height: map-get($icon-size, small);
		font-size: ms(2);
	}

}
