@import '/src/styles/2.19/helpers/buttons';
@import '/src/styles/2.19/components/buttons';

@include exports('components/splitbuttons'){

	/***
	 * Split Buttons:
	 */

	/**
	 *
	 *Split button:
	 *
	 *`.splitbutton > span[data-dropdown="splitbuttonDropdownId"]`
	 *
	 *A button that contains a dropdown element to secondary actions.
	 * Useful when there is a primary task but maybe lots of ways of
	 *achieving it (such as downbload formats)
	 *
	 *Splitbuttons require the foundation dropdown plugin to be awake
	 *as they basically use a regular dropdown tied to a button.
	 *
	 * 	@example
	 *		div.splitbutton Download (pdf)
	 *			span(data-dropdown='splitbuttonDropdownId')
 	 *		div.splitbutton.splitbutton--primary Download (pdf)
	 *			span(data-dropdown='splitbuttonDropdownId')
	 *		ul#splitbuttonDropdownId.dropdown.dropdown--nopip(data-dropdown-content)
	 *			li.dropdown__item Download as word
	 *			li.dropdown__item Download as HTML
	 *			li.dropdown__item Download as Morse code
	 *
	 */

	.splitbutton{
		@include button-global;
		@include split-button(
			$pip-color: darken($glb-button-default-color, $button-function-factor * 2),
			$span-border: darken($glb-button-default-color, $button-function-factor * 2)
		);
	}

	.splitbutton--primary{
		// @include button-style(
		// 	$bg: $glb-primary-color,
		// 	$bg-hover: $glb-primary-hover-color
		// );
		@include split-button(
			$pip-color: #fff,
			$span-border: rgba(255, 255, 255, .5)
		);
	}

	/**
	 *
	 *Question splitbutton:
	 *
	 *`.splitbutton--question`
	 *
	 *A simple hack to put a questionmark in the split section rather than the drop triangle, useful for if a button
	 * needs explaining by way of a tooltip. See [foundation tooltips](http://foundation.zurb.com/docs/components/tooltips.html)
	 * for more info.
	 *
	 * 	@example
	 *		div.splitbutton.splitbutton--question Download (pdf)
	 *			span(data-tooltip='' title='These buttons are awesome, you should totally use them!')
 	 *		div.splitbutton.splitbutton--question.splitbutton--primary Download (pdf)
	 *			span(data-tooltip='' title='These buttons are awesome, you should totally use them!')
	 *
	 */

	.splitbutton--question{
		span::after{
			content: '?';
			display: inline;
			top: 47%;
			top: calc(50% - .5rem);
			border: 0;
		}

	}

	/**
	 *
	 *has-spinner state:
	 *
	 *`.splitbutton.has-spinner`
	 *
	 *As with buttons, shows that a splitbutton's primary action is doing some work.
	 *
	 * 	@example
	 *		div.splitbutton.has-spinner
	 *			div.spinner
	 *			| Download (pdf)
	 *			span(data-tooltip='' title='These buttons are awesome, you should totally use them!')
	 *
	 */

	.splitbutton.has-spinner{
		@include button-disabled;

		padding-left: $button-med * 2 + 1rem;
		transition: padding map-get($transition-duration, fast) $ease-out-quad;

		.spinner{
			@include spinner-small;
			position: absolute;
			top: 35%;
			top: calc(50% - .5rem);
			left: 1rem;
			margin-right: ms(-2);
		}


	}

	.splitbutton.splitbutton--primary.has-spinner{
		@include button-disabled(transparent);

		.spinner{
			border-color: #fff;
			border-top-color: transparent;
		}
	}


}
