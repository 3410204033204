@include exports('components/actions') {
	/***
	 * Actions:
	 */

	/**
	 *
	 *Simple actions:
	 *
	 *`.actions`, `.actions--compact`, `.actions--inlineblock`
	 *
	 *The actions component holds a group of one or more buttons, optionally separated into left and right.
	 * A `--compact` modifer decreases top and bottom margins. An `--inlineblock` modifier sets `display: inline-block`
	 * at all viewport widths, including small viewports, so use with caution and when you know the size of your content.
	 *
	 * 	@example
	 *		div.actions
	 *			div.button Single button in an action block
	 *  	div.actions.actions--compact
	 *  		div.button Single button in a compact action block
	 *  	div.actions.actions--inlineblock
	 *  		div.button Button in actions--inlineblock container
	 *  		div.textbutton Textbutton in actions--inlineblock container
	 *
	 */

	.actions{
		@include clearfix;
		margin: ms(0) 0 1rem;

		&:empty{
			margin: 0;
		}

		.button,
		.splitbutton,
		.textbutton{
			@media #{$small-only}{
				display: block;
				width: 100%;
				margin-right: 0;
			}

			@media #{$medium-up}{
				margin-bottom: 10px;
			}
		}
	}

	.actions + .actions{ // @TODO: document this
		margin-top: -(ms(2));

		@media #{$medium-up}{
			margin-top: ms(4);
			margin-left: ms(1);
		}
	}

	.actions--compact{
		margin: 1rem 0 .5rem;
	}

	.actions--left{ //@TODO: document this

		.button,
		.splitbutton,
		.textbutton{
			text-align: left;

			@media #{$small-only}{
				padding-left: 0;
			}
		}
	}

	.actions--inlineblock{ // keeps display inline-block even in small viewport. Use with caution

		.button,
		.splitbutton,
		.textbutton{

			@media #{$small-only}{
				display: inline-block;
				width: inherit;
				margin-right: ms(0);

				&:last-of-type{
					margin-right: 0;
				}
			}
		}
	}

	.actions--flex{ // @TODO: document this

		display: flex;
		align-items: center;
		justify-content: flex-end;

		.button,
		.splitbutton,
		.textbutton{

			@media #{$small-only}{
				display: inline-block;
				width: inherit;
				margin-right: ms(0);

				&:last-of-type{
					margin-right: 0;
				}
			}
		}
	}

	.actions--centered{
		text-align: center;
	}

	/**
	 *
	 *Separate actions:
	 *
	 *`.actions .actions__forward` &  `.actions .actions__back`
	 *
	 *Sometimes when we are in a wizard-like environment, it's
	 *cool to have the forward on the right and the back on the left.
	 *On small screens, the forward should come first when the buttons are in block form.
	 *
	 *Note that this breaks the expected position of UI elements so only use it where
	 *some linear process makes it appropriate.
	 *
	 * 	@example
	 *		div.actions
	 *			.actions__forward
	 *				div.button Add more stuff
	 *				div.button.button--primary Go on to the next step
 	 *			.actions__back
	 *				div.textbutton Cancel
	 *
	 */
	.actions__forward{
		@media #{$medium-up}{
			float: right;
		}
	}

	.actions__back{
		@media #{$medium-up}{
			float: left;
		}
	}


	/**
	 *
	 *Stacked actions:
	 *
	 *`.actions--stacked`
	 *
	 *In a stacked action component, all buttons are blocks and stack vertically regardless of viewport size.
	 *
	 * 	@example
	 *		div.actions.actions--stacked
	 *			div.button.button--primary button
	 *			div.button button
	 *			div.textbutton text button
	 *
	 */
	.actions--stacked{
		.button,
		.splitbutton,
		.textbutton{
			display: block;
			width: 100%;
			margin-right: 0;
			margin-bottom: ms(0);
		}
	}
}
