@include exports('components/breadcrumb') {
	.breadcrumb {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-height: 400px;
		margin-bottom: 0;
		overflow-y: auto;
	}

	.breadcrumb__item {
		display: inline-block;
		margin: ms(-2) ms(0) ms(-2) 0;
		padding-right: ms(0);
		border-right: 1px solid gc(dl-teal);
		color: gc(dl-teal);
		cursor: pointer;

		&:last-child {
			border-right: 0;
		}

		&:hover {
			@extend .u-text__underline;
		}
	}
}
