@include exports('utilities/cursor'){
	/**
	 *
	 *Cursor utilities:
	 *
	 *`.u-cursor__pointer` sets cursor:pointer
	 *
 	 *`.u-cursor__drag` sets cursor:grab
	 *
	 *`.u-cursor__default` sets cursor:default
	 *
	 *
	 */

	.u-cursor__pointer{
		//scss-lint:disable ImportantRule
		cursor: pointer !important;
		//scss-lint:disable ImportantRule
	}

	.u-cursor__drag{
		cursor: grab;

		&:active{
			cursor: grabbing;
		}
	}

	.u-cursor__default{
		cursor: default;
	}
}
