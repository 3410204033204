@import '/src/styles/2.19/helpers/spinners';

@include exports('components/spinners'){
	/***
	 * Spinners:
	 */


	/**
	 *
	 *Mixins:
	 *
	 *`@mixin spinner`
	 *
	 *Adds styles to base `spinner` class.
 	 *
	 *`@mixin spinner-small`
	 *
	 *Augments the `spinner` mixin to make the spinner smaller.
	 *
	 * 	@example
	 *		code
 	 *			|.button:before{
	 *			|	@include spinner;
	 *			|}
	 *			|.button.button--small:before{
	 *			|	@include spinner;
	 *			|	@include spinner-small;
	 *			|}
	 *
	 *
	 */

	/**
	 *
	 *Spinner:
	 *
	 *`.spinner`
	 *
	 *A spinning loading element with gif fallback.
	 *
	 * 	@example
	 *		div.spinner
	 *
	 */

	.spinner{
		@include spinner;
	}


	/**
	 *
	 *Small spinner:
	 *
	 *`.spinner.spinner--small`
	 *
	 *A smaller version for use in buttons and cramped spaces.
	 *
	 * 	@example
	 *		div.spinner.spinner--small
	 *
	 */

	.spinner.spinner--small{
		@include spinner-small;
	}


	/**
	 *
	 *Dragon Spinner:
	 *
	 *`.dragonspinner`
	 *
	 *Used ONLY for inital app load. Background image is swapped out in themes for partner logos.
	 *
	 * 	@example
	 *		div.dragonspinner
	 *
	 */

	.dragonspinner{
		@include animation-pulse;
		display: inline-block;
		z-index: -1;
		// width: map-get($icon-size, large);
		// height: map-get($icon-size, large);
		// background-image: url('../images/icon-logo--mono.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		.no-cssanimations &{
			display: none;
		}
	}

}
