@include exports('components/tables'){
	/***
	 * Tables:
	 */

	/**
	 *
	 *Basic table:
	 *
	 *`.table`
	 *
	 *Basic table; we just add some text alignment, font weight, and padding. Used to render delimited list blocks in doc builder.
	 *
	 *
	 * 	@example
	 *		div.card
	 *			table.table
	 *				tr.table__row
	 *					th.table__header Column 1
	 *					th.table__header Column 2
	 *					th.table__header Column 3
	 *				tr.table__row
	 *					td.table__cell Column 1 Row 1
	 *					td.table__cell Column 2 Row 1
	 *					td.table__cell Column 3 Row 1
	 *				tr.table__row
	 *					td.table__cell Column 1 Row 2
	 *					td.table__cell Column 2 Row 2
	 *					td.table__cell Column 3 Row 2
	 *
	 */

	.table{
		display: table;
	}

	.table__row{
		vertical-align: baseline;
	}

	.table__header{
		font-weight: bold;
		text-align: left;
	}

	.table__header,
	.table__cell{
		padding: ms(0);
	}

}
