@import '/src/styles/2.19/settings/variables';

@import '/src/styles/2.19/helpers/animations';
@import '/src/styles/2.19/components/forms';



@include exports(vendor / hopscotch){

	//*****************  extend animations  *****************/

	.fade-in-up {
		@include animation-fadeInUp;
	}

	.fade-in-down{
		@include animation-fadeInDown;
	}

	.fade-in-right{
		@include animation-fadeInRight;
	}

	.fade-in-left{
		@include animation-fadeInLeft;
	}

	//*****************  hopscotch css  *****************/
	div.hopscotch-bubble {
		// @include box-sizing(content-box);
		position: absolute;
		z-index: 100;
		max-width: ms(13);
		border: $bubbleBorderWidth solid $borderColor; /* transparent, if supported */
		border-radius: $bubbleCornerRadius;
		background-color: #fff;
		box-shadow: 0 0 ms(2) rgba(gc(dl-black), .4);

		@media #{$medium-up}{
			max-width: ms(16);
		}


		// * {
		// 	@include box-sizing(content-box);
		// }


		// @include background-clip;

		&.animate {
			transition-property: top, left;
			transition-duration: map-get($transition-duration, default);
			transition-timing-function: $ease-in-out-quad;
		}

		&.invisible {
			opacity: 0;
		}

		&.hide,
		.hide,
		.hide-all {
			display: none;
		}

		// h3 {
		// 	color: #000;
		// 	// font-family: $bubbleFontFamily;
		// 	font-size: 16px;
		// 	font-weight: bold;
		// 	line-height: 19px;
		// 	margin: -1px $bubblePadding 0 0;
		// 	padding: 0;
		// }

		.hopscotch-bubble-container {
			position: relative;
			padding: ms(3);
			// text-align: left;
		}


		.hopscotch-bubble-content {
			margin: 0 0 0 ($circleWidth + ms(2));
		}

		&.no-number .hopscotch-bubble-content {
			margin: 0;
		}

		.hopscotch-bubble-close {
			@include _form__bg-cross;
			position: absolute;
			top: ms(0);
			right: ms(0);
			box-sizing: content-box;
			width: ms(0);
			height: ms(0);
			opacity: .5;
			text-indent: -9999px;
			cursor: pointer;

			&:hover{
				opacity: 1;
			}

			&.hide,
			&.hide-all {
				display: none;
			}
		}

		.hopscotch-bubble-number {
			display: block;
			width: $circleWidth;
			height: $circleWidth;
			margin: ms(0) ms(1) 0 0;
			float: left;
			border-radius: 100%;
			background: gc(dl-grannysmith);
			color: #fff;
			font-size: ms(2);
			font-weight: bold;
			line-height: calc(#{$circleWidth} + 1px);
			text-align: center;
		}

		.hopscotch-bubble-arrow-container {
			$arrowWidth: 8px;
			position: absolute;

			width: $arrowWidth * 2;
			height: $arrowWidth * 2;

			.hopscotch-bubble-arrow,
			.hopscotch-bubble-arrow-border {
				width: 0;
				height: 0;
			}

			&.up {
				top: 0 - $arrowWidth - $bubbleBorderWidth;
				left: ms(1);

				.hopscotch-bubble-arrow {
					position: relative;
					top: 0 - $arrowWidth + $bubbleBorderWidth;
					border-right: $arrowWidth solid transparent;
					border-bottom: $arrowWidth solid $bubbleColor;
					border-left: $arrowWidth solid transparent;
				}

				.hopscotch-bubble-arrow-border {
					border-right: $arrowWidth solid transparent;
					border-bottom: $arrowWidth solid #000;
					border-bottom: $arrowWidth solid $borderColor;
					border-left: $arrowWidth solid transparent;
				}
			}

			&.down {
				bottom: 0 - $arrowWidth * 2 - $bubbleBorderWidth;
				left: ms(1);

				.hopscotch-bubble-arrow {
					position: relative;
					top: 0 - $arrowWidth - $bubbleBorderWidth;
					border-top: $arrowWidth solid $bubbleColor;
					border-right: $arrowWidth solid transparent;
					border-left: $arrowWidth solid transparent;
				}

				.hopscotch-bubble-arrow-border {
					border-top: $arrowWidth solid #000;
					border-top: $arrowWidth solid $borderColor;
					border-right: $arrowWidth solid transparent;
					border-left: $arrowWidth solid transparent;
				}
			}

			&.left {
				top: ms(1);
				left: 0 - $arrowWidth - $bubbleBorderWidth;

				.hopscotch-bubble-arrow {
					position: relative;
					top: 0 - (2 * $arrowWidth);
					left: $bubbleBorderWidth;
					border-top: $arrowWidth solid transparent;
					border-right: $arrowWidth solid $bubbleColor;
					border-bottom: $arrowWidth solid transparent;
				}

				.hopscotch-bubble-arrow-border {
					border-top: $arrowWidth solid transparent;
					border-right: $arrowWidth solid #000;
					border-right: $arrowWidth solid $borderColor;
					border-bottom: $arrowWidth solid transparent;
				}
			}

			&.right {
				top: ms(1);
				right: 0 - $arrowWidth * 2 - $bubbleBorderWidth;

				.hopscotch-bubble-arrow {
					position: relative;
					top: 0 - (2 * $arrowWidth);
					left: 0 - $bubbleBorderWidth;
					border-top: $arrowWidth solid transparent;
					border-bottom: $arrowWidth solid transparent;
					border-left: $arrowWidth solid $bubbleColor;
				}

				.hopscotch-bubble-arrow-border {
					border-top: $arrowWidth solid transparent;
					border-bottom: $arrowWidth solid transparent;
					border-left: $arrowWidth solid $borderColor;
				}
			}
		}

	}


}//exports
