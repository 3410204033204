@import '/src/styles/2.19/settings/variables';
@import '/src/styles/2.19/components/forms';

// @import  'pickadate-sass/lib/themes-source/classic';
// @import  'pickadate-sass/lib/themes-source/classic.date';
// @import  'pickadate-sass/lib/themes-source/classic.time';

.picker{
	outline: none;
}

.picker__select--year {
	@extend select, .form__element;
	min-width: ms(8);
	height: auto;
}

.picker__select--month {
	height: auto !important;
}

.picker__holder--month-only{
	margin-top: -(ms(2));
	overflow: hidden;
	border: 0;
	outline: none;
	background: none;
	box-shadow: none;

	@media #{$medium-up}{
		margin-top: -(ms(1));
	}

	.picker--opened &{
		box-shadow: none;
	}

	// .picker__frame,
	.picker__box{
		padding: 0;
	}

	.picker__select--month{
		width: calc(100% - 1rem);
		margin-left: 0;

		// @media #{$large-up}{
		// 	width: 40%;
		// }
	}

	.picker__header{
		text-align: left;
	}
}

input.picker__input[disabled],
input.picker__input[readonly]{
	position: relative;
	background-color: $input-bg-color;
}

// input.picker__input.picker__input--active{
// 	border-color: $glb-primary-color;
// }

.react-datepicker-wrapper{
	width: 100%;

	.react-datepicker__input-container{
		width: 100%;
	}
}
