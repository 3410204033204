@include exports('modules/doctype-admin/doctype-admin'){

	//*****************  jsplumb MADNESS  *****************/

	.doctype-map__item{
		display: inline-block;
		position: relative;
		width: 250px;
		margin-right: 20px;
		padding: 20px;
		border: 1px solid #f0f0f0;
		background-color: rgba(250, 250, 250, .9);
		// z-index: 20;
	}


	.doctype-map__row{
		margin: 80px 0 20px;
		text-align: center;
	}

	._jsPlumb_overlay{
		z-index: 21;
		max-width: 250px;
		padding: 2px 10px;
		border: 1px solid #ccc;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, .9);
	}

	#connect-canvas{
		position: relative;
	}

	.doctype-section-manage{

		.modal &{
			@media #{$medium-up}{
				width: ms(15);
			}
		}
	}
}// exports
