@include exports('components/notifications'){
	/***
	 * Notifications:
	 *
	 */

	/**
	 *
	 *Basic notification:
	 *
	 *`.notification > .card > .card__content > .notification__close, .notification__content > .notification__title`
	 *
	 *With the `--top` modifier, notification divs are styled like cards, and appear fixed
	 * to the top of the viewport. They are animated by adding and removing an `.is-showing` class. Basic notifications
	 * contain a `.card`, and always contain a `.notification__close` button. Topbar notifications generally have a timeout, so they don't
	 * need a close button.
	 *
	 *Basic notifications are placed inside a `.notification__container` div on a page where they'll be called. For instance,
	 * the folder list template (My Documents home view) contains a `.notification__container` in which all front page notifications
	 * will appear. The `.notification__container` div is not styled; it exists as a class only to be referenced in the javascript.
	 *
	 * 	@example
	 *		div.notification.notification--top.is-showing(style='position:relative !important;')
	 *			div.notification__content
	 *				div.slat.slat--base
	 *					div.slat__icon
	 *						svg.icon.icon--medium
	 *							use(xlink:href='images/sprites.svg#icon-info--color')
	 *					div.slat__content
	 *						div.slat__title This is an important top notification
	 *
	 *		div.notification.is-showing(style='position:relative !important;')
	 *			div.card
	 *				div.card__content
	 *					div.notification__close
	 *						svg.icon.icon--tiny
	 *							use(xlink:href='images/sprites.svg#icon-cross')
	 *					div.notification__content
	 *						div.slat.slat--base
	 *							div.slat__icon
	 *								svg.icon.icon--medium
	 *									use(xlink:href='images/sprites.svg#icon-info--color')
	 *							div.slat__content
	 *								div.slat__title This is an important notification
	 *
	 *
	 */

	.notification{
		position: relative;
		margin: 0 0 ms(1);
	}

	.notification-card{
		background-color: $glb-primary-light-color;
		box-shadow: 2px 2px 2px rgba(71, 71, 62, .1);
	}
	
	.notification__close{
		position: absolute;
		top: 0;
		right: 0;
		box-sizing: content-box;
		width: ms(0);
		height: ms(0);
		padding: ms(0);
		opacity: .5;
		cursor: pointer;

		&:hover{
			opacity: 1;
		}

		svg{
			display: block;
			color: inherit;
		}
	}

	.notification__content{
		max-width: ms(18);
	}

	.notification__title{
		color: inherit;
	}

	.notification--top{
		position: fixed;
		z-index: -1;
		top: 0;
		left: 50%;
		width: ms(16);
		max-width: 80%;
		margin: 0;
		padding: ms(2) ms(3);
		transform: translateX(-50%);
		transition: all .2s $ease-out-quad;
		border-radius: map-get($corner-radius, small);
		opacity: 0;
		background: #e5f5f5;
		box-shadow: 0 0 4px gc(dl-lightgrey);

		&.is-showing{
			z-index: 100;
			top: ms(3);
			opacity: (1);
		}
	}
}
