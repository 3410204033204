@include exports('modules/doctype-admin/doctype__block-form'){


	// this stuff is all tied up in the meat slicer somehow.


	.doctype-conditions{
		margin: 0 0 ms(1);
	}


	//*****************  output templates for block  *****************/


	// link that toggles wether the referenced block is it's number or its full output
	.doctype-block__output-mode-toggle-link{
		&.is-ref-mode{
			color: gc(dl-rust);
		}
	}
}
