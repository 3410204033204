@include exports('components/tabsets'){
	/***
	 * Tabsets:
	 */

	/**
	 *
	 *Tabsets in titlebars:
	 *`.titlebar.titlebar--noborder + .tabset__container > .tabset > .tabset__item + .tabset__toggle > .tabset__icon`
	 *
	 *Tabsets are a horizontal navigation style for medium screens and up, and collapsed for small screens, with a toggle.
	 * Add a .tabset__container--collapsed modifier for tabsets with too many options to fit on a medium screen to mimic
	 * a small screen tab setup.
	 * Will generally live underneath page titles.
	 *
	 * 	@example
	 *		div.titlebar.titlebar--noborder
	 *			h1.titlebar__title Page title
	 *		div.tabset__container
	 *			dl.tabset
	 *				dd.tabset__item Item 1
	 *				dd.tabset__item.is-active Item 2
	 *				dd.tabset__item Item 3
	 *				dd.tabset__item Item 4
	 *				div.tabset__toggle
	 *					.tabset__icon
	 *						svg.icon.icon--tiny
	 *							use(xlink:href='images/sprites.svg#icon-chevronup')
	 *
	 *		div.titlebar.titlebar--noborder
	 *			h1.titlebar__title Page title
	 *		div.tabset__container.tabset__container--collapsed
	 *			dl.tabset
	 *				dd.tabset__item Item 1
	 *				dd.tabset__item.is-active Item 2
	 *				dd.tabset__item Item 3
	 *				dd.tabset__item Item 4
	 *				div.tabset__toggle
	 *					.tabset__icon
	 *						svg.icon.icon--tiny
	 *							use(xlink:href='images/sprites.svg#icon-chevronup')
	 */

	.tabset__container{ // reserves space (height) when tabset changes from relative to absolute
		position: relative;
		height: calc(#{ms(1) + ms(1) + ms(1)});
		margin: 0 0 ms(2);
		background: gc(dl-white);
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .1);

		@media #{$large-up}{
			height: auto;
			margin: 0;
			background: none;
			box-shadow: none;
		}
	}

	.tabset{ // Container for tabset__items
		position: relative;
		width: 100%;
		height: calc(#{ms(1) + ms(1) + ms(1)});
		margin-bottom: 0; // override default list margin
		// padding: ms(1) 0;
		overflow: hidden;
		text-transform: uppercase;

		@media #{$large-up}{
			height: auto;
			// padding: 0;
			// background: darken($glb-layout-color, 4%);

			.card &{
				margin-bottom: ms(4);
			}
		}
	}

	.tabset__item{
		display: block;
		position: absolute; //stack tabs atop one another
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
		padding: ms(1) 0 ms(1) calc(#{ms(2) + ms(-4)});
		color: gc(dl-basalt);
		font-size: ms(1);
		line-height: 1;
		text-decoration: none;
		text-transform: uppercase;
		cursor: pointer;

		&.is-active{
			// margin-bottom: -1px;
			z-index: 2; // show active tab name above all others
			background: gc(dl-white);
			// font-weight: normal;
			// font-weight: bold;
			// &:hover{
			// 	color: $glb-primary-color;
			// }
		}

		@media #{$large-up}{
			display: inline-block;
			position: relative;
			width: auto;
			margin: 0 ms(2);
			padding: ms(-1) 0;
			transition: color map-get($transition-duration, fast) $ease-in-out-quad;
			background: none;
			font-size: ms(0);
			text-align: center;
			cursor: pointer;

			&:first-of-type{
				// width: auto;
				// margin-left: calc(#{ms(2) + ms(-3)});
				margin-left: 0;
				// border-left: 0;
			}

			&:hover{
				color: darken(gc(dl-basalt), 18%);
			}

			&.is-active{
				padding-bottom: ms(-2);
				// border-bottom: 4px solid $glb-primary-color;
				background: none;
				// color: $glb-primary-color;

				// &:hover{
				// 	color: $glb-primary-color;
				// }
			}
		}

		@media #{$large-up}{
			margin: 0 ms(2);
			padding: ms(0) 0;
		}
	}

	.tabset.is-expanded{
		position: absolute;
		z-index: 2; // stupid arms race vs. 'Upload logo' #plupload-browse z-index means this must be > 1
		height: initial;
		padding: 0 0 ms(1);
		overflow: visible;
		border-bottom: 1px solid rgba(gc(dl-black), .1);
		background: gc(dl-white);
		box-shadow: 0 0 10px rgba(gc(dl-black), .1);

		@media #{$large-up}{
			position: relative;
			height: auto;
			padding: 0;
			border-bottom: 0;
			background: none;
			box-shadow: none;
		}

		.tabset__item{
			display: block;
			position: relative; // unstack tabs
			background: gc(dl-white);

			@media #{$large-up}{
				display: inline-block;
				background: none;
			}
		}

		.tabset__item.is-active{
			padding-left: ms(2) + ms(-9);
			// border-left: 4px solid $glb-primary-color;
			// color: $glb-primary-color;

			@media #{$large-up}{
				padding-left: 0;
				// border-left: 0;
			}
		}
	}

	// .tabset__toggle{
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	width: ms(5);
	// 	height: 100%;
	// 	cursor: pointer;
	// 	z-index: 3; // this needs to sit on top of whole menu in whatever state. This being 3 is reason for left sidebar z-index being 4
	// 	pointer-events: all;
	//
	// 	@media #{$large-up}{
	// 		display: none;
	// 	}
	// }

	.tabset__icon{
		position: absolute;
		z-index: 3;
		top: ms(2) - ms(-3);
		right: ms(2);
		width: ms(1);
		height: auto;
		transform: rotate(0deg);
		cursor: pointer;
		pointer-events: all;

		svg{
			color: gc(dl-basalt);
			pointer-events: none;
		}

		.is-expanded &{
			// top: ms(2) - ms(-2);
			transform: rotate(180deg);
		}

		@media #{$large-up}{
			display: none;
		}
	}

	.tabset__container--collapsed{ // Modifier to extend small screen behavior to large-up screens. Use if there are too many tabs for a large screen's horz real estate

		@media #{$large-up}{
			height: ms(7);

			.tabset{
				height: ms(7);
				margin-bottom: 0; // override default list margin
				padding: ms(1) 0;
				background: $glb-layout-color;
				text-transform: uppercase;
			}

			.tabset.is-expanded{
				position: absolute;
				z-index: 2;
				height: initial;
				padding: 0;
				border-bottom: 1px solid rgba(gc(dl-black), .1);
				background: gc(dl-ash);
				box-shadow: 0 2px 2px rgba(gc(dl-black), .1);

				.tabset__item{
					display: block;
					position: relative; // unstack tabs
					margin: ms(1) 0;
				}

				.tabset__item.is-active{
					// padding: 0 0 0 ms(2) + ms(-9);
					// border-top: 0;
					// border-bottom: 0;
					border-left: 4px solid gc(dl-teal);
					// background: gc(dl-ash);
				}
			}

			.tabset__item{
				display: block;
				position: absolute; //stack tabs atop one another
				top: 0;
				left: 0;
				width: 100%;
				margin: ms(1) 0;
				padding: 0 0 0 ms(2) + ms(-4);
				transition: color map-get($transition-duration, fast) $ease-in-out-quad;
				color: gc(dl-basalt);
				font-size: ms(1);
				line-height: 1.3;
				text-align: left;

				&:hover{
					color: darken(gc(dl-basalt), 18%);
				}

				&:first-child{
					width: 100%;
				}

				&.is-active{
					z-index: 2; // show active tab name above all others
					padding: 0 0 0 ms(2) + ms(-9);
					border-top: 0;
					border-bottom: 0;
					background: $glb-layout-color;
					color: gc(dl-black);
				}
			}

			.tabset__toggle{
				display: block;
			}

			.tabset__icon{
				display: inline-block;
			}
		}
	}
}
