@include exports('components/editables'){
	/***
	* Editables:
	*/

	/**
	 *
	 *Basic editable:
	 *
	 *`.editable`
	 *
	 *Editables are parts of the UI that can be cliked on to change their
	 * values. Usually this is via edit-in-place.
	 * See editing a folder's title or a document in the doc builder for good
	 * examples of a `titlebar`, `editlink` and `editable` working together.
	 *
	 *Use in conjuction with editlink in most cases.
	 *
	 * 	@example
	 *		h3.editable I am editable
	 *		h3.editable(contenteditable="true") I am editable and editing
	 *
	 */

	.editable{
		padding: 0;
		transition: background map-get($transition-duration, fast) $ease-in-out-quad;
		border-bottom: 1px dotted  transparent;
		cursor: pointer;

		&:hover{
			border-bottom-color: gc(dl-charcoal);
			background: #fff;
		}
	}

	.editable--nohover{
		&:hover{
			border-bottom: 1px dotted  transparent;
			background: none;
			cursor: text;
		}
	}

	.editable.is-editing,
	.editable[contentEditable=true]{
		padding: 0 ms(0);
		border-bottom: 1px dotted  gc(dl-charcoal);
		outline: none;
		background: #fff;
		color: gc(dl-black);
		cursor: text;
	}
}
