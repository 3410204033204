@include exports('components/documents'){
	/***
	* Documents:
	*
	* This is the digital representation of an actual physical document.
	*/


	/**
	 *
	 *Basic document:
	 *
	 *`.document > .document__header + [document content] `
	 *
	 *Generated document content is varied and unpredictable.
	 * We just wrap it in a simple width-restricted container
	 * with a header.
	 *
	 * 	@example
	 *		div.document
	 *			div.document__header
	 *				div.document__headertools
	 *					div.textbutton
	 *						svg.icon.icon--micro
	 *							use('xlink:href'='images/sprites.svg#icon-expand--mono')
	 *						| Pop out
	 *
	 *					span.textbutton.dropdowntext('data-dropdown'='doc-preview-download')
	 *						| Download
	 *					ul#doc-preview-download.dropdown('data-dropdown-content'='')
	 *						li Download as in pdf format
	 *						li Download in MSWord format
	 *
	 *			div
	 *				p Actual generated document content goes in here
	 *
	 */

	// the base of the document component looks a lot like a card
	.document{
		display: block;
		position: relative;
		margin-bottom: ms(1);
		padding: ms(2) ms(3);
		background: #fff;
		box-shadow: 1px 1px 1px rgba(gc(dl-black), .1);

		&::before{
			@include triangle(map_get($icon-size, medium), gc(dl-canary) gc(dl-ash), down-right);
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.document__header{
		margin-bottom: ms(1);
		padding: ms(1);
		border-bottom: 1px solid gc(dl-grey);
		// overflow: hidden;
	}

	.document__headertools{
		display: block;
		width: 100%;
		float: right;

		@media #{$medium-up}{
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.document__headerlogo{
		float: left;
	}


	.document-preview__page{
		// This is important so indentation is a predictable width
		// See Issue #1439
		box-sizing: content-box;
	}

	/**
	 *
	 *Chopped document:
	 *
	 *`.document--chopped`
	 *
	 *The chopped modifer adds a torn edge to the bottom of the document to show that this is a preview only.
	 *
	 * 	@example
	 *		div.document.document--chopped
	 *			div.document__header
	 *				div.document__headertools
	 *					div.textbutton
	 *						svg.icon.icon--micro
	 *							use('xlink:href'='images/sprites.svg#icon-expand--mono')
	 *						| Pop out
	 *
	 *					span.textbutton.dropdowntext('data-dropdown'='doc-preview-download')
	 *						| Download
	 *					ul#doc-preview-download.dropdown('data-dropdown-content'='')
	 *						li Download as in pdf format
	 *						li Download in MSWord format
	 *
	 *			div
	 *				p Actual generated document content goes in here
	 *
	 */


	.document--chopped{
		&::after{
			content: '';
			display: block;
			position: absolute;
			bottom: -1rem;
			left: 0;
			width: 100%;
			height: 1rem;
			background-image: linear-gradient(45deg, transparent 1rem, #fff 0),
							linear-gradient(-45deg, transparent 1rem, #fff 0),
							linear-gradient(-45deg, transparent ($glb-base-size - 1px), rgba(gc(dl-black), .1) 0); // Faux shadow, we cant use calc in gradients so we have to rely on the size being irem == 15px
			background-repeat: repeat-x;
			background-position: center bottom;
			background-size: 1rem 1rem;
		}
	}


}
