@mixin theme-colors(
	$name,
	$primary,
	$secondary,
	$tertiary,
	$buttonborder,
	$buttonbg,
	$buttonbghover,
	$buttontext,
	$buttontexthover,
	$textbutton,
	$topnavbg,
	$topnavtext,
	$loadingbar) {

	// Define colors in theme
	$glb-primary-color: $primary;
	$glb-secondary-color: $secondary;
	$glb-tertiary-color: $tertiary;
	$button-bordercolor: $buttonborder;
	$button-bgcolor: $buttonbg;
	$button-bgcolor-hover: $buttonbghover;
	$button-textcolor: $buttontext;
	$button-textcolor-hover: $buttontexthover;
	$textbutton-color: $textbutton;
	$topnav-bgcolor: $topnavbg;
	$topnav-textcolor: $topnavtext;
	$loadingbar-color: $loadingbar;

	// Web-app (Foundation) variable overrides
	$anchor-font-color: $glb-primary-color;
	$anchor-font-color-hover: lighten($glb-primary-color, 9%);
	$input-focus-border-color: $glb-primary-color; // from web app - Foundation
	$glb-layout-color: gc(dl-ash);

	// Theme name is usually partner company name, is applied to body so component classes inherit changes
	.#{$name} {
		// Add applicable component classes with css colors added

		// Base

		// Anchors
		// a{
		// 	color: $glb-primary-color;
		// }

		// a:hover,
		// a:focus,
		// a:active{
		// 	color: lighten($glb-primary-color, 9%);
		// }

		// Form elements

		input.has-success,
		textarea.has-success,
		select.has-success{
			// scss-lint:disable ImportantRule
			border-color: $glb-primary-color !important;
			// scss-lint:enable ImportantRule
		}

		input.form__element.form__element--pin-single:focus,
		input.form__element.form__element--pin-single.has-success{
			border-bottom: 2px solid gc(dl-black);

		}

		.form__textarea{

			&:focus{
				border-color: $input-focus-border-color; // Foundation variable - will only apply to web-app
			}
		}

		// input.form__element:focus{
		// 	border-color: $glb-primary-color;
		// }

		// select.form__element:focus{
		// 	outline-color: $glb-primary-color;
		// }

		// Components

		// Buttons
		.button,
		a.button{
			border: 1px solid $button-bordercolor;
			background-color: $button-bgcolor;
			color: $button-textcolor;
		}

		.button:hover,
		a.button:hover{
			border-color: $button-bgcolor-hover;
			background-color: $button-bgcolor-hover;
			color: $button-textcolor-hover;
		}

		.button.button--disabled,
		.button.is-disabled{
			opacity: .7;
			cursor: default;

			&:hover{
				border-color: $button-bordercolor;
				background-color: $glb-primary-color;
			}
		}

		.button.button--primary,
		a.button.button--primary,
		.button--front-page,
		a.button--front-page{
			border-color: $glb-primary-color;
			background-color: $glb-primary-color;
			color: gc(dl-white);

			&:hover{
				border-color: lighten($glb-primary-color, 9%);
				background-color: lighten($glb-primary-color, 9%);
				color: gc(dl-white);
			}
		}

		.button.button--primary.button--disabled,
		.button.button--primary.is-disabled,
		.button.button--front-page.button--disabled,
		.button.button--front-page.is-disabled,
		.button.button--primary.button--disabled.has-spinner,
		.button.button--primary.is-disabled.has-spinner,
		.button.button--front-page.button--disabled.has-spinner,
		.button.button--front-page.is-disabled.has-spinner{
			border-color: $glb-primary-color;
			background-color: $glb-primary-color;

			&:hover{
				border-color: $glb-primary-color;
				background-color: $glb-primary-color;
			}
		}

		.button.button--clear,
		a.button.button--clear{
			border-color: gc(dl-grey);
			background: transparent;
			box-shadow: 0 2px 4px rgba(gc(dl-black), .2);

			&:hover{
				border-color: gc(dl-grey);
				background: transparent;
			}
		}

		.button.button--warning,
		a.button.button--warning{
			border-color: gc(dl-ruby);
			background-color: gc(dl-ruby);
			color: gc(dl-white);

			&:hover{
				border-color: lighten(gc(dl-ruby), 9%);
				background-color: lighten(gc(dl-ruby), 9%);
				color: gc(dl-white);
			}
		}

		.button.button--warning.button--disabled,
		.button.button--warning.is-disabled,
		.button.button--warning.button--disabled.has-spinner,
		.button.button--warning.is-disabled.has-spinner{
			border-color: gc(dl-ruby);
			background-color: gc(dl-ruby);

			&:hover{
				border-color: gc(dl-ruby);
				background-color: gc(dl-ruby);
			}
		}

		.button--tab{
			border: 0;
			border-radius: 0;
			background: transparent;
			color: gc(dl-basalt);

			&:hover{
				background: transparent;
				color: $button-textcolor-hover;
			}
		}

		.button--tab.is-active{
			border-top: 0;
			border-right: 0;
			border-bottom: 4px solid $glb-primary-color;
			border-left: 0;
			color: $glb-primary-color;

			&:hover{
				color: $glb-primary-color;
			}
		}

		// Textbuttons

		.textbutton,
		a.textbutton{
			color: $textbutton-color;

			.icon{
				color: $textbutton-color;
			}
		}

		.textbutton:hover,
		a.textbutton:hover{
			color: lighten($textbutton-color, 9%);

			.icon{
				color: lighten($textbutton-color, 9%);
			}
		}

		// Primary color textbutton
		.textbutton.textbutton--primary{
			color: $glb-primary-color;

			.icon{
				color: $glb-primary-color;
			}
		}

		.textbutton.textbutton--primary:hover{
			color: lighten($glb-primary-color, 9%);

			.icon{
				color: lighten($glb-primary-color, 9%);
			}
		}

		.textbutton.textbutton--secondary{
			color: gc(dl-basalt);

			.icon{
				color: gc(dl-basalt);
			}
		}

		.textbutton.textbutton--secondary:hover{
			color: darken(gc(dl-basalt), 9%);

			.icon{
				color: darken(gc(dl-basalt), 9%);
			}
		}

		.textbutton.textbutton--front-page{
			color: $glb-primary-color;

			.icon{
				color: $glb-primary-color;
			}
		}

		.textbutton.textbutton--front-page:hover{
			color: lighten($glb-primary-color, 9%);

			.icon{
				color: lighten($glb-primary-color, 9%);
			}
		}

		.textbutton.textbutton--warning{
			color: gc(dl-ruby);

			.icon{
				color: gc(dl-ruby);
			}
		}

		.textbutton.textbutton--warning:hover{
			color: darken(gc(dl-ruby), 9%);

			.icon{
				color: darken(gc(dl-ruby), 9%);
			}
		}

		// Splitbuttons

		.splitbutton{
			border: 1px solid $button-bordercolor;
			background-color: $button-bgcolor;
			color: $button-textcolor;
		}

		.splitbutton:hover{
			border-color: $button-bgcolor-hover;
			background-color: $button-bgcolor-hover;
			color: $button-textcolor-hover;
		}

		.splitbutton.splitbutton--primary{
			border-color: $glb-primary-color;
			background-color: $glb-primary-color;
			color: gc(dl-white);
		}

		.splitbutton.splitbutton--primary:hover{
			border-color: lighten($glb-primary-color, 9%);
			background-color: lighten($glb-primary-color, 9%);
			color: gc(dl-white);
		}

		.splitbutton.splitbutton--primary.has-spinner{
			background-color: $glb-primary-color;
		}

		// Logo loading spinner

		.dragonspinner{
			width: 5.489rem;
			height: 3.39rem;
			background-image: url('../images/icon-#{$name}--mono.svg');
		}

		// doc builder progress bars

		.progressbar__bar{
			background: $glb-primary-color;
		}

		// Topnav
		.topnav{
			background: $topnav-bgcolor;
			color: $topnav-textcolor;
		}

		//scss-lint:disable SelectorDepth
		.topnav__listitem:hover .topnav__header,
		// .topnav__listitem--active .topnav__header,
		.topnav__listitem.is-active .topnav__header,
		.topnav__listitem.is-active .topnav__icon svg,
		.topnav__listitem:hover .topnav__icon--styleable svg,
		.topnav__listitem.is-active .topnav__icon--styleable svg{

			@if $topnav-bgcolor == gc(dl-white){
				color: $glb-tertiary-color;
			} @else{
				color: gc(dl-white);
			}
		}

		.topnav__listitem--no-icon-hover:hover .topnav__icon--styleable svg,
		.topnav__listitem--no-icon-hover.is-active .topnav__icon--styleable svg{
			color: inherit;
		}
		//scss-lint:enable SelectorDepth

		// .topnav__listitem--dropdown.has-hover:hover .topnav-sub{
		//
		// 	@if $topnav-bgcolor == gc(dl-white){ // if topnav is white, set dropdown opacity to 1
		// 		opacity: 1;
		// 	} @else{ // otherwise, keep it a little transparent
		// 		opacity: .9;
		// 	}
		// }

		.topnav__icon--styleable{
			margin-right: ms(0);
		}
		.topnav__icon--styleable svg{
			color: $glb-secondary-color;
		}

		.icon__notebadge--topnav{
			background-color: $glb-primary-color;
		}

		//scss-lint:disable SelectorDepth
		// .topnav__listitem:hover .topnav__icon--styleable svg,
		// .topnav__listitem.is-active .topnav__icon--styleable svg,
		// .topnav__listitem.is-active .topnav__header{
		// 	color: $glb-tertiary-color;
		// }
		//scss-lint:enable SelectorDepth

		.topnav-sub{
			background: $topnav-bgcolor;
		}

		.topnav-sub__header:hover{

			.topnav-sub__listlink,
			a.topnav-sub__listlink{
				@if $topnav-bgcolor == gc(dl-white){
					color: $glb-tertiary-color;
				} @else{
					color: gc(dl-white);
				}
			}
		}

		.topnav-sub__listlink,
		a.topnav-sub__listlink{
			color: $topnav-textcolor;

			&:hover,
			&:focus,
			&:active{

				@if $topnav-bgcolor == gc(dl-white){
					color: $glb-tertiary-color;
				} @else{
					color: gc(dl-white);
				}
			}
		}

		.topnav__lozenge{
			@if $topnav-bgcolor == gc(dl-white){
				border: 1px solid $glb-primary-color;
				background-color: transparent;
				color: $glb-primary-color;
			} @else{
				border: 0;
				background-color: $glb-primary-color;
				color: gc(dl-white);
			}
		}

		// .topnav__logo img{
		// 	height: auto;
		// }

		.topnav__menutoggle-inner{
			@if $topnav-bgcolor == gc(dl-white){ // if topnav is white, set menu bars/x to secondary color
				background-color: $glb-secondary-color;

				&::before,
				&::after{
					background-color: $glb-secondary-color;
				}
			} @else{ // otherwise, keep them white
				background-color: gc(dl-white);

				&::before,
				&::after{
					background-color: gc(dl-white);
				}
			}
		}

		.topnav__menutoggle-inner.is-active{
			background: none;
		}

		.topnav__list.is-open{
			background-color: $topnav-bgcolor;
		}

		.topnav__list.is-open .topnav__listitem{

			@if $topnav-bgcolor == gc(dl-white){
				background-color: $glb-layout-color;
			} @else{
				background-color: $topnav-bgcolor;
			}
		}

		.topnav__listitem.is-open{
			background: $topnav-bgcolor;
		}

		.topnav__list.is-open .topnav__listitem.is-open{
			background-color: $topnav-bgcolor;
		}

		// Tabsets

		.tabset__item{

			@media (min-width: 64.063em){

				&.is-active{
					border-bottom: 4px solid $glb-primary-color;
					color: $glb-primary-color;

					//scss-lint:disable NestingDepth
					&:hover{
						color: $glb-primary-color;
					}
					//scss-lint:enable NestingDepth
				}
			}
		}

		.tabset.is-expanded{

			.tabset__item.is-active{
				border-left: 4px solid $glb-primary-color;
				color: $glb-primary-color;

				@media (min-width: 64.063em){
					border-left: 0;
				}
			}
		}

		// Checkradios

		.checkradio a{
			color: $glb-primary-color;
			text-decoration: none;
		}

		.checkradio--radio{

			&::before{
				background-color: $glb-primary-color;
			}

			&:hover::before{
				border-color: $glb-primary-color;
			}
		}

		.checkradio__input:checked + .checkradio--radio::before{
			border-color: $glb-primary-color;
		}

		.checkradio--checkbox:hover{

			&::before,
			&::after{
				border-color: $glb-primary-color;
			}
		}

		.checkradio__input:checked + .checkradio--checkbox::before{
			border-color: $glb-primary-color;
			background-color: $glb-primary-color;
		}

		.checkradio__input:checked + .checkradio--checkbox::after{
			border-color: gc(dl-white);
		}

		// Optionbars

		.optionbar:hover{
			border-color: $glb-primary-color;
		}

		.optionbarinput:checked + .optionbar{
			border-color: $glb-primary-color;
			box-shadow: 0 0 4px rgba($glb-primary-color, .5);
		}

		.optionbar--radio{

			&::before{
				background: $glb-primary-color;
			}

			&:hover::before{
				border-color: $glb-primary-color;
			}
		}

		.optionbarinput:checked + .optionbar--radio::before{
			border-color: $glb-primary-color;
		}

		.optionbar--checkbox{

			&:hover::before{
				border-color: $glb-primary-color;
			}
		}

		.optionbarinput:checked + .optionbar--checkbox::before{
			border-color: $glb-primary-color;
		}

		.optionbar .optionbar__input:focus{ // single line for add option input in optionbar radio
			border-color: $glb-primary-color;
		}

		// Searchbars

		input.searchbar__input:focus,
		input.searchbar__input--big:focus{
			border: 1px solid $glb-primary-color;
		}

		.searchbar__menu{
			border-top: 0;
			border-right: 1px solid $glb-primary-color;
			border-bottom: 1px solid $glb-primary-color;
			border-left: 1px solid $glb-primary-color;
			overflow-y: scroll;
			pointer-events: all;
		}

		.searchbar--icon{

			input.searchbar__input:focus,
			input.searchbar__input--big:focus{
				border: 0;
			}
		}

		.searchbar--button{

			.searchbar__icon{

				//scss-lint:disable SelectorDepth
				//scss-lint:disable NestingDepth
				.icon{
					color: $glb-primary-color;
				}
				//scss-lint:enable SelectorDepth
				//scss-lint:enable NestingDepth
			}

			input.searchbar__input,
			input.searchbar__input--big{

				//scss-lint:disable NestingDepth
				&:focus{
					border-top: 0;
					border-right: 0;
					border-bottom: 1px solid $glb-primary-color;
					border-left: 0;
				}
				//scss-lint:enable NestingDepth
			}

			.searchbar__menu{
				border: 0;
			}
		}

		// Org switcher

		.switcher--org{
			background: $topnav-bgcolor;
		}

		// Notification cards
		.notification--primary{
			border: 1px solid $glb-primary-color;

			.notification__close{

				//scss-lint:disable SelectorDepth
				//scss-lint:disable NestingDepth
				svg{
					color: $glb-primary-color;
				}
				//scss-lint:enable SelectorDepth
				//scss-lint:enable NestingDepth
			}

			.notification__title{
				color: $glb-primary-color;
			}
		}

		.notification--top{
			border: 0;
		}

		// Pagination active tab
		.paginator__page.is-active{
			border-bottom-color: $glb-primary-color;
			color: $glb-primary-color;
		}

		// Logo Uploaders

		.uploader__item--selected{
			border-color: $glb-primary-color;

			&::after{
				background: $glb-primary-color;
			}
		}

		// Fixednavs (a la Intercom switcher)

		.fixednav__circle--partner.fixednav__circle--front{
			background-color: $glb-primary-color;
		}

		.fixednav__circle--partner.fixednav__circle--back{
			background-color: lighten($glb-primary-color, 10%);
		}

		// primary color background utility

		.u-color__primary{
			//scss-lint:disable ImportantRule
			color: $glb-primary-color !important;
			//scss-lint:enable ImportantRule
		}

		.u-colorbg__primary{
			//scss-lint:disable ImportantRule
			background-color: $glb-primary-color !important;
			//scss-lint:enable ImportantRule
		}

		.u-colorborder__primary{
			//scss-lint:disable ImportantRule
			border-color: $glb-primary-color !important;
			//scss-lint:enable ImportantRule
		}

		.u-colorfill__primary{
			//scss-lint:disable ImportantRule
			background-color: $glb-primary-color !important;
			//scss-lint:enable ImportantRule
		}

		// Vendor

		// Pickadate

		input.picker__input.picker__input--active{
			border-color: $glb-primary-color;
		}

		// nprogress bar
		//scss-lint:disable IdSelector
		#nprogress .bar{
			background: $loadingbar-color;
		}
		//scss-lint:enable IdSelector

		//scss-lint:disable IdSelector
		#nprogress .peg{
			box-shadow: 0 0 10px $loadingbar-color, 0 0 5px $loadingbar-color;
		}

		// Intercom - disabled cause Intercom stuff all happens in an iframe now
		//scss-lint:disable IdSelector
		//scss-lint:disable ImportantRule
		// #intercom-container .intercom-launcher{
		// 	background: $intercom-color !important;
		// }

		// #intercom-container .intercom-launcher-button{
		// 	border: 1px solid darken($intercom-color, 9%) !important;
		// 	background-color: $intercom-color !important;
		// }

		// #intercom-container .intercom-conversations-item-admin-avatar-no-image{
		// 	background-color: $intercom-color !important;
		// }

		//scss-lint:disable SelectorDepth
		// #intercom-container .intercom-announcement-body-container a.intercom-h2b-button,
		// #intercom-container .intercom-composer-send-button,
		// #intercom-container .intercom-conversations-new-conversation-button,
		// #intercom-container .intercom-new-anonymous-user input[type=submit],
		// #intercom-container .intercom-small-announcement-body-container a.intercom-h2b-button{
		// 	border: 1px solid $intercom-color !important;
		// 	background: $intercom-color !important;
		// 	background-image: linear-gradient(180deg, lighten($intercom-color, 9%), $intercom-color) !important;
		// }

		// #intercom-container .intercom-announcement-body-container a.intercom-h2b-button:hover,
		// #intercom-container .intercom-composer-send-button:hover,
		// #intercom-container .intercom-conversations-new-conversation-button:hover,
		// #intercom-container .intercom-new-anonymous-user input[type=submit]:hover,
		// #intercom-container .intercom-small-announcement-body-container a.intercom-h2b-button:hover{
		// 	border: 1px solid lighten($intercom-color, 9%) !important;
		// 	background: lighten($intercom-color, 9%) !important;
		// 	background-image: linear-gradient(180deg, lighten($intercom-color, 9%), lighten($intercom-color, 9%)) !important;
		// }
		//scss-lint:enable SelectorDepth
		//scss-lint:enable IdSelector


		// PARTNER PORTAL SITES

		// Partner portal site plans table
		.plans__row--header{
			color: $glb-primary-color;
		}

		.plans__row--pricing{
			background-color: $glb-primary-color;
			color: gc(dl-white);
		}

		// Partner portal site footer
		.footer--color{
			background-color: $glb-primary-color;
		}



		// REGISTRATION

		// Registration tooltip card
		.card--tooltip{
			background: $glb-primary-color;
		}

		// Registration tooltip
		.icon--styleable-tooltip-icon{
			fill: $glb-primary-color;
			color: gc(dl-white);
		}

		.tooltip__label.tooltip__label--active{
			background-color: $glb-primary-color;

			@media (min-width: 600px){ // can't use mixins for media queries so these are set to the express reg values
				background-color: transparent;

				&::before{
					border-left: 30px solid $glb-primary-color;
				}
			}

			@media (min-width: 1024px){ // can't use mixins for media queries so these are set to the express reg values

				&::before{
					border-right: 30px solid $glb-primary-color;
					border-left: 0;
				}
			}
		}

		.notification__link,
		a.notification__link{
			color: $glb-primary-color;
		}

		.notification__link:hover,
		a.notification__link:hover{
			color: lighten($glb-primary-color, 9%);
		}

		// Registration progressbar
		.loadingbar{
			background-color: $glb-primary-color;
		}
	}
}
