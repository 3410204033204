@include exports('components/ui/dropzone') {
	/***
	* Drop zones:
	*
	*/

	/**
	 *
	 *Basic dropzone:
	 *
	 *`.dropzone`
	 *
	 *Indicates the area is capable of recieving droppable items.
	 *
	 * 	@example
 	 *		div.card
	 *			div.dropzone
	 *
	 */


	.dropzone{
		position: relative;
		min-height: ms(10);
		margin-bottom: ms(1);
		padding: ms(0);
		transition: all map-get($transition-duration, fast) $ease-in-out-cubic;
		border: $input-border-width dashed $input-border-color;
		border-radius: map-get($border-radius, small);
		cursor: pointer;
	}

	.dropzone--base{
		border: 0;
		border-radius: 0;
	}


	/**
	 *
	 *is-active state:
	 *
	 *`.dropzone.is-active`
	 *
	 *Indicates that a droppable item is in range of the dropzone.
	 *
	 * 	@example
	 *		div.card
	 *			div.dropzone.is-active
	 *
	 */

	.dropzone.is-active{
		border-style: solid;
		border-color: gc(dl-sky);
		background: rgba(gc(dl-sky), .1);
	}

	/**
	 *
	 *Dropzone icon:
	 *
	 *`.dropzone > .dropzone--icon`
	 *
	 *Optional visual aid.
	 *
	 * 	@example
 	 *		div.card
	 *			div.dropzone
	 *				.dropzone__icon
	 *					svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-paperclip')
	 *
	 */

	.dropzone__icon{
		// position: absolute; // Issue #1913 - altering style for new 'click-anywhere' layout
		// top: ms(0);
		// right: ms(1);
		display: inline-block;
		margin-right: ms(0);
	}


	/**
	 *
	 *Hint dropzone:
	 *
	 *`.dropzone.dropzone--hint > .dropzone__hint`
	 *
	 *Adds some text to tell people what to do.
	 *
	 * 	@example
 	 *		div.card
	 *			div.dropzone.dropzone--hint
	 *				.dropzone__hint Drop something in me :U
	 *
	 */

	// .dropzone--hint{
		// padding-top: ms(5); // Issue #1912 - Turning this off for now sinced this is just used in logo upload for TMs
	// }

	.dropzone__hint{
		// position: absolute; // Issue #1912 - Turning this off for now sinced this is just used in logo upload for TMs
		// top: ms(0);
		display: inline;
		margin-bottom: ms(5);
		color: gc(dl-basalt);
	}

	.dropzone__hint--centered{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		margin-bottom: 0;
		transform: translateX(-50%) translateY(-50%);
		color: gc(dl-grey);
		text-align: center;
		user-select: none;
	}

	.dropzone__hintheader{
		color: gc(dl-slate);
		font-size: ms(4);
	}

	.dropzone__preview{
		position: relative;
		width: 100%;
		height: 100%;
		padding: 25px 0;

		img{
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 150px;
			max-height: 150px;
			margin: 0 auto;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}//exports
