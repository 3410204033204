// Rough HTML structure of moxie plupload container (example of what we're overriding).
// Moxie styles are defined in moxie.js, so we have to hack them all after render.
//
// <div id="plupload-container" style="position: relative;">
// 	<div id="plupload-browse" class="dropzone dropzone--hint" data-dropzone="" style="z-index: 1;">
// 		<div class="dropzone__icon">
// 			<svg class="icon icon--small">
// 				<use xlink:href="images/sprites.svg#icon-paperclip"></use>
// 			</svg>
// 		</div>
// 		<div class="dropzone__hint">Drag and drop your file or click to browse for it.</div>
// 		<div id="plupload-list"></div>
// 	</div>
// 	<div id="someid_container" class="moxie-shim moxie-shim-html5" style="position: absolute; top: 340px; left: 331px; width: 1041px; height: 133px; overflow: hidden; z-index: 0;">
// 		<input id="html5_someid" type="file" style="font-size: 999px; opacity: 0; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;" accept="image/jpeg,.jpg,image/gif,.gif,image/png,.png,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/pdf,.pdf,text/plain,.txt">
// 	</div>
// </div>

.moxie-shim{
	// scss-lint:disable ImportantRule
	position: initial !important;
	top: 0 !important;
	left: 0 !important;
	width: 0 !important;
	height: 0 !important;

	input{
		font-size: inherit !important;
	}
	// scss-lint:enable ImportantRule
}
