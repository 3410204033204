@include exports('components/signpad'){
	// container that holds the canvas and reset button
	.signpad{
		position: relative;
		width: 100%;
		max-width: 100%;
		height: 200px;
		max-height: 100%;
		margin: 0 0 ms(1);
		border: 1px solid gc(dl-grey);
		border-radius: map-get($border-radius, small);
		background: gc(dl-ash);
		user-select: none;
	}

	.signpad--base{
		border: 0;
		border-radius: 0;
	}

	//the actual element the signature plugin is bound to
	.signpad__canvas{
		width: 100%;
		max-width: 100%;
		height: 200px;
		max-height: 100%;
		cursor: crosshair;
	}

	.signpad__border{
		position: absolute;
		top: 50%;
		width: 100%;
		height: 100px;
		transform: translateY(-50%);
		border-top: 1px dashed gc(dl-slate);
		border-bottom: 1px dashed gc(dl-slate);
		cursor: default;
		pointer-events: none;
		user-select: none;
	}

	.signpad__instructions{
		visibility: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translateX(-50%) translateY(-50%);
		color: gc(dl-grey);
		text-align: center;
		cursor: default;
		pointer-events: none;
		user-select: none;
	}

	.signpad__instructions.is-visible{
		visibility: visible;
	}

	.signpad__instructionsheader{
		color: gc(dl-slate);
		font-size: ms(4);
	}

	.signpad__instructionsheader--big{
		font-size: ms(5);
	}

	// button that clears the signature
	.signpad__reset{
		position: absolute;
		right: ms(0);
		bottom: ms(0);
		opacity: .5;
		text-align: center;
		cursor: pointer;

		&:hover{
			opacity: 1;
		}
	}
}
