@include exports('components/searchbars'){
	/***
	* Search bars:
	*
	* Searchbars are for filtering lists of stuff, they generally reside instade `titlebar__tools` areas.
	*/


	/**
	 *
	 *Searchbar with icon:
	 *
	 *`.searchbar.searchbar--icon > input.searchbar__input + div.searchbar__action`
	 *
	 *Basically an input that has a button right next to it and a wrapper for styling.
	 * These shouldn't be used in place of proper search action buttons when search is the primary task of the view.
	 *
	 * 	@example
	 *		form.searchbar.searchbar--icon
	 *			input.searchbar__input(type='text')
	 *			button.searchbar__action(type='submit')
	 *				svg.icon.icon--tiny.icon--hover
	 *					use(xlink:href='#icon-search')
	 *
	 */

	.searchbar{
		display: flex;
		align-items: center;
		border: $input-border-width solid $input-border-color;
		border-radius: $input-border-radius;
		background: gc(dl-white);
	}

	.searchbar--icon{
		position: relative;

		input.searchbar__input,
		input.searchbar__input--big{
			border: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		.form__errorlist{ // searchbars are display: flex and input is inside a wrapper to mimic an input, so we have to bump this thing down if there is form validation on the searchbar.
			position: absolute;
			top: ms(2);
			left: 0;
		}
	}

	.searchbar__inputwrapper{
		position: relative;
		flex: 1;
		width: auto;
		max-width: 100%;
		margin: 0 0 ms(1);

		@media #{$medium-up}{
			width: 100%;
		}
	}

	input.searchbar__input,
	input.searchbar__input--big{
		flex: 1;
		height: auto;
		margin: 0;
		// border: 0 transparent;
		border: $input-border-width solid $input-border-color;
		background: transparent;

		// &:focus{
		// 	border: 1px solid $glb-primary-color;
		// }
	}

	input.searchbar__input{
		// padding: ms(-1);
		// font-size: ms(1);
	}

	input.searchbar__input--big{
		height: $big-form-height;
		padding: ms(0);
		font-size: ms(2);
	}

	.searchbar__action{
		padding: 0 ms(-3);
		border: 0;
		background: transparent;
		appearance: none;

		.icon{
			vertical-align: text-bottom;
		}
	}

	.searchbar__menu{
		position: absolute;
		z-index: 10;
		left: 0;
		// width: calc(100% + 2px);
		width: 100%;
		max-height: calc(#{ms(0) + ms(1) + ms(1) + ms(2) + ms(3)} + #{((ms(1) + ms(-2)) * 8)}); // height of menu padding plus help item and margins, plus 8 * menu item heights and margins, plus 8th item bottom margin
		// margin-left: -1px;
		padding: ms(1);
		overflow: hidden;
		// border-top: 0;
		// border-right: 1px solid $glb-primary-color;
		// border-bottom: 1px solid $glb-primary-color;
		// border-left: 1px solid $glb-primary-color;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: $input-border-radius;
		border-bottom-right-radius: $input-border-radius;
		background: gc(dl-jade);
		pointer-events: none;
	}

	// .searchbar__menu div:nth-child(0){
	// 	margin-top: ms(1);
	// }

	.searchbar__menu div:nth-child(8){ // add extra margin to 8th item as it's the max number to display
		margin-bottom: ms(3);
	}

	.searchbar__menu--scroll{
		overflow-y: scroll;

		&::-webkit-scrollbar{
			width: ms(-3);
		}

		&::-webkit-scrollbar-thumb{
			background: gc(dl-grey);
		}
	}

	.searchbar__menu--scroll div:nth-child(8){ // remove extra margin from 8th item as we're showing them all now
		margin-bottom: 0;
	}

	.searchbar__menuhelp{
		color: gc(dl-basalt);
		font-size: ms(0);
	}

	.searchbar__menuitems{
		margin-top: ms(1);
	}

	.searchbar__menuitem{
		margin-bottom: ms(-2);
		cursor: pointer;
		pointer-events: all;
	}

	/**
	 *
	 *Searchbar with button:
	 *
	 *`.searchbar.searchbar--button > .searchbar__icon + .searchbar__inputwrapper > input.searchbar__input, .searchbar__action > .searchbar__menu`
	 *
	 *A searchbar with new style single line input, with optional menu to be used with React
	 * typeahead component. To use typeahead dropdown menu, seacrhbar must be placed in a card with relative
	 * positioning.
	 *
	 * 	@example
	 *		card.u-pos__relative
	 *			form.searchbar.searchbar--button
	 *				div.searchbar__icon
	 *					svg.icon
	 *						use(xlink:href='#icon-search')
	 *				div.searchbar__inputwrapper
	 *					input.searchbar__input.searchbar__input--big(type='text')
	 *				div.searchbar__action
	 *					button.button.button--primary(type='submit') Search
	 *
	 */

	.searchbar--button{
		flex-wrap: wrap;
		padding: 0 ms(0);
		border: 0;

		@media #{$medium-up}{
			flex-wrap: nowrap;
		}

		.searchbar__icon{

			.icon{
				@include icon-square(small);
				// color: $glb-primary-color;

				@media #{$medium-up}{
					@include icon-square(medium);
					margin: $form-spacing 0;
					margin-right: ms(0);
				}
			}
		}

		.searchbar__inputwrapper{
			position: static;
			margin: 0;
			padding-right: ms(1);

			@media #{$medium-up}{
				margin: $form-spacing 0 0;
				padding-right: 0;
			}
		}

		input.searchbar__input,
		input.searchbar__input--big{
			flex: 1;
			width: 100%;
			max-width: 100%;
			margin: 0 ms(1);
			padding-right: ms(1);
			margin: 0 0 0 ms(0);
			border-top: 0;
			border-right: 0;
			border-bottom: 1px solid gc(dl-ash);
			border-left: 0;
			background: transparent;
			color: gc(dl-basalt);
			font-size: ms(1); // for Create view searchbar, make font size smaller again

			// &:focus{
			// 	border-bottom: 1px solid $glb-primary-color;
			// }

			@media #{$medium-up}{
				width: 100%;
				margin: 0 ms(1) $form-spacing 0;
				padding-right: 0;
			}
		}

		.searchbar__action{
			flex: 100%;
			margin-top: $form-spacing;
			padding: 0;

			.button{
				display: block;
				width: 100%;
			}

			@media #{$medium-up}{
				flex: 0 auto;
				padding: 0 ms(1) 0 ms(2);
			}
		}

		.searchbar__menu{
			top: calc(#{($card-vert-padding * 2) + $big-form-height + $form-spacing + 4rem} - 6px); // sits at bottom of card, assumes standard markup of card > searchbar with standard padding and large input height + height of button - a little wiggle room
			// width: 100%;
			margin: 0;
			padding: ms(1) calc(#{ms(0) + $card-horz-padding}) ms(2); // horizontal padding is searchbar--button padding plus card horz padding
			// border: 0;
			box-shadow: 0 2px 6px rgba(0, 0, 0, .2);

			@media #{$medium-up}{
				top: calc(#{(($card-vert-padding + $form-spacing) * 2) + $big-form-height} - 4px); // Don't have to take into account height of button underneath input
			}
		}
	}
}
