//import for `strip-unit` function
// @import 'foundation-sites/scss/foundation/functions';

@mixin ui-checkbox($color: gc(dl-grey), $size: ms(1), $thickness: 1px){
	display: inline-block;
	width: $size;
	height: $size;
	margin-top: ms(-7);
	border: $thickness solid $color;
	transition: all map_get($transition-duration, fast);
	background: #fff;
	content: '';
	vertical-align: top;
}

// @mixin ui-checkbox-hover($color: $glb-primary-color){
// 	border-color: $color;
// }

@mixin ui-checkbox-checked($color: transparent, $size: ms(1), $thickness: 2px){
	transform: rotate(40deg);
	height: ms(2) * strip-unit($size);
	width: ms(-2) * strip-unit($size);
	margin-right: ms(-2);
	margin-top: 0;
	border-style: solid;
	// border-color: $color;
	border-width: 0 $thickness $thickness 0;
}

@mixin ui-checkbox-checked-filled-box($color: transparent, $size: ms(1), $thickness: 1px){
	// background: $color;
	border-style: solid;
	// border-color: $color;
	border-width: $thickness;
}

@mixin ui-checkbox-checked-filled-check($color: gc(dl-white), $thickness: 2px){
	transform: rotate(40deg);
	height: ms(-1);
	width: ms(-5);
	// margin-right: ms(-2);
	margin-top: ms(-7);
	margin-left: ms(-4);
	background: transparent;
	border-style: solid;
	// border-color: $color;
	border-width: 0 $thickness $thickness 0;
}

@mixin ui-radiobutton{
	display: inline-block;
	margin-top: ms(-7);
	vertical-align: middle;
	border-radius: 100%;
	border: $input-border-width solid gc(dl-grey);
	// background: $glb-primary-color;
	box-shadow: inset 0 0 0 1em #fff;
}

// @mixin ui-radiobutton-hover{
// 	border-color: $glb-primary-color;
// }

@mixin ui-radiobutton-checked{
	// border-color: $glb-primary-color;
	border-width: 4px;
	// box-shadow: inset 0 0 0 2px #fff;
}
