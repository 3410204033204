@include exports('components/drawboxes'){
	/***
	 * Drawboxes:
	 */

	/**
	 *
	 *Basic drawbox:
	 *
	 *`.drawbox__canvas > .drawbox > .drawbox__close`
	 *
	 *A drawbox is the basic box users draw for Upload and Sign signature placement.
	 *
	 * 	@example
	 *		div.card(style='height:100px;')
	 *			div.drawbox__canvas
	 *				div.drawbox(style='width:400px;height:50px')
	 *					span Content goes here...
	 *					div.drawbox__close
	 *						svg.icon.icon--micro
	 *							use('xlink:href'='images/sprites.svg#icon-cross')
	 *
	 */

	.drawbox {
		position: absolute;
		max-width: ms(17); // attempting to prevent blowout of container , make this a bit smaller than the width of a restricted row
		min-height: ms(5);
		padding: ms(0);
		border: 1px solid gc(dl-sky);
		background: rgba(gc(dl-sky), .2);
		color: gc(dl-charcoal);
		font-size: ms(0);
		cursor: pointer;
	}

	.drawbox__close {
		position: absolute;
		top: ms(-2);
		right: ms(-2);
		box-sizing: content-box;
		width: ms(-2);
		height: ms(-2);
	}

	.drawbox__canvas {
		position: relative;
	}
}
