@include exports('components/sidebar') {
	$siderbar-width: 280px;

	// wrapper for content when page have right sidebar
	.content-wrapper-on-sidebar {
		width: calc(100% - (#{$siderbar-width} + 10px));
	}

	.sidebar {
		@extend .u-colorbg__dl-white;
		@extend .u-box__p2;
		@extend .u-box__ml2;
		position: fixed;
		top: 10px;
		right: 10px;
		bottom: 10px;
		right: 10px;
		width: $siderbar-width;
		overflow: auto;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .06), 0 1px 2px rgba(0, 0, 0, .08);
	}

	.sidebar__title,
	.sidebar__item {
		@extend .u-box__flex;
		@extend .u-box__alignitems-center;
		@extend .u-box__justify-space-between;
	}

	.sidebar__title {
		@extend .u-box__mb1;
		@extend .u-text__uppercase;
		@extend .u-text__bold;
		@extend .u-color__dl-lightgrey;
	}

	.sidebar__item {
		margin-bottom: ms(-4);
		padding: ms(-5) 0;
		cursor: pointer;

		&:hover {
			@extend .u-color__dl-teal;
		}
	}

	.sidebar__item--is-active {
		@extend .u-color__dl-teal;
		@extend .u-text__bold;
	}
}
