@include exports('components/panels'){
	/***
	 * Panels:
	 */

	/**
	 *
	 *Basic panel:
	 *
	 *`.panel`
	 *
	 *Panels are normally available through Foundation, but we decided to style our own. Created to display optional toggle text.
	 *
	 * 	@example
	 *		div.card
	 *			div.panel
	 *				h4 I am a panel
	 *				p I am some filler text related to the header topic. Panels are pretty basic containers meant to differentiate themselves from the rest of the card by their background color. Their basic background color is cream.
	 *			div.panel.panel--transparent
	 *				h4 I am a transparent panel
	 *				p Transparent panels set background: transparent.
	 *			div.panel.panel--border.u-colorborder__dl-mariana
	 *				h4 I am a bordered panel
	 *				p Default border color is same as default bg color, so use a border-color utlity class for it to show up.
	 *			div.panel.panel--round.u-colorbg__dl-grannysmith.u-color__dl-white
	 *				h4 I am a rounded panel
	 *				p A panel--round modifier adds a border radius to the corners. Colors changed for clarity.
	 *			div.panel.panel--invisible
	 *				h4 I am an invisible panel
	 *				p Use an invisible panel like an aside
	 *			div.panel.panel--compact
	 *				h4 I am a compact panel
	 *				p Panels have got a built in bottom margin so that whatever comes beneath them has a little room to breathe.
	 *
	 */

	.panel{
		margin-bottom: ms(2);
		padding: ms(1) ms(3);
		background: gc(dl-cream);
	}

	.panel--transparent{
		background: transparent;
	}

	.panel--border{
		border: 1px solid gc(dl-cream);
	}

	.panel--round{
		border-radius: map-get($corner-radius, small);
	}

	.panel--invisible{
		background: none;
	}

	.panel--compact{
		padding: ms(0) ms(1);
	}

	.panel--base{
		padding: 0;
	}

}
