// @include exports('helpers/positioning'){}

@mixin centershim(){
	display: inline-block;
	content: '';
	overflow: hidden;
	width: 1px;
	height: 100%;
	margin-left: -1px;
	vertical-align: text-bottom;
}

@mixin verticalcenter{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin horizontalcenter{
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

@mixin totalcenter{
	transform: translateX(-50%) translateY(-50%);
	position: relative;
	left: 50%;
	top: 50%;
}
