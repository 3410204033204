@include exports('components/numberlists'){
	/***
	 * Numberlists:
	 */

	/**
	 *
	 *Simple numberlist:
	 *
	 *`.numberlist`
	 *
	 *A list meant to be combined with entries styled with colored circles wrapping the numbers.
	 *
	 * 	@example
	 *		div.card
	 *			ul.numberlist
	 *				li.numberlist__item
	 *					div.numberlist__circle
	 *						div.numberlist__number
	 *							span 1
	 *					span.u-text__bold This is item number 1!
	 *					div.numberlist__text Here's some text about item number 1.
	 *				li.numberlist__item
	 *					div.numberlist__circle
	 *						div.numberlist__number
	 *							span 2
	 *					span.u-text__bold This is item number 2!
	 *					div.numberlist__text Here's some text about item number 1.
	 *
	 */

	.numberlist{
		margin-left: 0;
		list-style: none;
	}

	.numberlist__item{
		display: block;
		margin-bottom: ms(1);
	}

	.numberlist__circle{
		display: inline-block;
		position: relative;
		width: ms(3);
		height: ms(3);
		margin-right: ms(0);
		border-radius: 100%;
		background-color: gc(dl-sky);
	}

	.numberlist__number{
		@include totalcenter;
		color: $white;
		text-align: center;
	}

	.numberlist__text{
		margin-left: ms(3) + ms(0);
	}
}
