@include exports('utilities/radii'){
	/**
	 *
	 *Radii (radiuses):
	 *
	 *`.u-rad__small`  - adds a small radius to blocks
	 *
	 * 	@example
	 *		div.texticon.u-rad__small sm
	 *
	 */

	.u-rad__small{
		border-radius: map-get($border-radius, small);
	}
}
