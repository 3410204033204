/***
 * Dropdowns:
 *
 * Based on foundation dropdowns, full documentation of JS at
 * http://foundation.zurb.com/docs/components/dropdown.html
 */


/**
 *
 *@mixin drop-triangle:
 *
 *`@include drop-triangle($color, $size)`
 *
 *A mixin that generates a triange icon to indicate the element can be clicked
 *to activate a dropdown. To be used with :before and :after pseudo elements.
 *
 *$color     The color of the triangle, default: currentcolor
 *
 */


@mixin drop-triangle($color: currentcolor) {
	// @include triangle($size, $color, down); // bourbon method http://bourbon.io/docs/#triangle

	display: inline-block;
	width: 7px;
	height: 7px;
	margin-bottom: ms(-5);
	margin-left: ms(-2);
	border: solid $color;
	transform: rotate(-45deg);
	border-width: 0 0 2px 2px;
	border-radius: 0 0 0 2px;
	content: '';
	vertical-align: middle;
}


@include exports('components/dropdowns'){


	/**
	 *
	 *Dropdown:
	 *
	 *`ul.dropdown > li.dropdown__item[ | .is-active | dropdown__item--divider | dropdown__item--warning | dropdown__item.has-spinner ]` , `.dropdown.dropdown--html`
	 *
	 *Styles an element to look like a dropdown menu and hides it by default.
	 * Usually used with split buttons or foundation.dropdown directly.
	 *
	 * 	@example
	 *		a(data-dropdown='DropdownWithListId') Click me to see a dropdown with a list in it
	 *		ul.dropdown#DropdownWithListId(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item.is-active Active item
	 *			li.dropdown__item.dropdown__item--divider Divider dropdown
	 * 			li.dropdown__item.dropdown__item--warning Warning dropdown
	 *			li.dropdown__item.dropdown__item.has-spinner
	 *				div.spinner
	 *				span Dropdown w spinner
	 *
	 *		a(data-dropdown='DropdownWithHtmlId') Click me to see a dropdown with a html content
	 *		div.dropdown.dropdown--html#DropdownWithHtmlId(data-dropdown-content)
	 *			h3 Hey hey
	 *			p not bad
	 *			div.actions
	 *				div.button.button--primary Click me
	 *				div.textbutton.textbutton--warning Dont click me
	 */

	.dropdown{
		@include dropdown-container($triangle: bottom);
		border-radius: map_get($border-radius, small);
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .2);
		font-size: ms(0);
		text-align: left;

		&.dropdown::before,
		&.dropdown::after{
			display: none;
		}

		@media #{$medium-up}{
			&.dropdown::before,
			&.dropdown::after{
				display: block;
			}
		}
	}

	.dropdown--spacious{
		max-width: 240px;
		padding: ms(2);
	}

	.dropdown--switcher{
		//scss-lint:disable ImportantRule
		left: -1px !important;
		width: calc(100% + 2px) !important; // full width + borders
		max-width: calc(100% + 2px); // full width + borders
		margin-top: 0;
		border: 1px solid gc(dl-iron);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		box-shadow: 0 1px 2px rgba(gc(dl-black), .2);
		//scss-lint:enable ImportantRule
	}

	.dropdown--orgswitcher{ // NOTE: We have to use some ugly magic numbers to reposition versus Foundation 5's out-of-the-box inline dropdown positioning
		//scss-lint:disable ImportantRule
		top: calc(#{ms(4) - ms(-12)}) !important; // (roughyl) input height plus ~ 1 px for border
		//scss-lint:enable ImportantRule
	}

	.dropdown--selectable.dropdown--orgswitcher{ // have to override specificity as a dropdown--orgswitcher is always also a dropdown--selectable
		background: gc(dl-white);

		@media #{$large-up}{
			background: $glb-layout-color;
		}
	}

	.dropdown--juriswitcher{
		//scss-lint:disable ImportantRule
		top: calc(#{ms(4) - ms(-11)}) !important; // (roughly) input height plus ~ 1 px for border
		padding: ms(0) 0;
		font-size: ms(1);
		//scss-lint:enable ImportantRule
	}

	.dropdown--browsermenu{
		display: block;
		position: absolute;
		width: 100%;
		max-width: 100%;
		margin-top: calc(#{ms(0) + ms(1)}); // menu font size + menu text bottom padding
		margin-bottom: 0;
		margin-left: 0;
		border: 1px solid gc(dl-slate);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: map-get($corner-radius, small);
		border-bottom-right-radius: map-get($corner-radius, small);
		background: gc(dl-white);
		box-shadow: 0 2px 2px gc(dl-ash);

		.dropdown__item:not(.dropdown__item--title){
			padding-left: ms(4);

			@media #{$large-up}{
				padding-left: ms(0);
			}
		}
	}

	.dropdown--browsermenu.open.f-open-dropdown{
		//scss-lint:disable ImportantRule
		top: ms(0) !important; // this is super dirty, but Foundation dropdowns throw inline styles so we have to line it up like this.
		left: 20% !important;
		width: 80% !important;

		@media #{$large-up}{
			left: 0 !important;
			width: 100% !important;
		}
		//scss-lint:enable ImportantRule
	}

	.dropdown--browseritem{
		display: block;
		position: absolute;
		width: 100%;
		max-width: 100%;
		margin: 0;
		border: 1px solid gc(dl-slate);
		border-radius: map-get($corner-radius, small);
		background: gc(dl-white);
		box-shadow: 0 2px 2px gc(dl-ash);

		@media #{$large-up}{
			max-width: 100%;
		}
	}

	.dropdown--browseritem.open.f-open-dropdown{ // this is super dirty, but Foundation dropdowns throw inline styles so we have to line it up like this.
		//scss-lint:disable ImportantRule
		top: calc(#{(-(ms(1))) - (ms(-12))}) !important; // moving into place directly over element that triggers dropdown
		left: calc(#{(-(ms(5))) + (ms(-4)) + (ms(-12))}) !important;
		width: 160% !important;
		max-width: calc(75vw) !important;

		@media #{$medium-up}{
			width: 70% !important;
			max-width: 100% !important;
		}

		@media #{$large-up}{
			top: calc(#{(-(ms(1))) - (ms(-11))}) !important;
			width: 100% !important;
			// max-width: 200% !important;
		}
		//scss-lint:enable ImportantRule
	}

	.dropdown--slat__tools,
	.dropdown--left.dropdown--slat__tools{
		margin-left: 0;

		@media #{$large-up}{
			margin-top: -18px;
			margin-left: -12px;
		}
	}

	.dropdown--slat__tools.open.f-open-dropdown{ // use this one if you need to hack the stupid dropdown to ensure it appears JUST TO THE LEFT of the slat tools three-dot menu
		//scss-lint:disable ImportantRule
		@media #{$large-up}{
			left: calc(100% - 200px - #{ms(1)}) !important;
		}
		//scss-lint:enable ImportantRule
	}

	.dropdown--tooltip{
	//scss-lint:disable ImportantRule
		left: 0 !important;
		max-width: ms(15);
		padding: ms(2);

		@media #{$small-only}{
			position: fixed !important;
			z-index: 100;
			top: 0 !important;
			right: 0 !important;
			width: 100% !important;
			max-width: 100%;
			margin: 0;
			padding: ms(7) ms(5) ms(5);
			border-radius: 0;
		}
	}
	//scss-lint:enable ImportantRule

	// //scss-lint:disable ImportantRule
	// .dropdown--tooltip.f-open-dropdown{
	//
	// 	@media #{$medium-up}{
	// 		top: 12px !important;
	// 	}
	// }
	// //scss-lint:enable ImportantRule

	.dropdown--tooltip__wrapper{
		height: auto;

		@media #{$small-only}{
			display: none;
			position: fixed;
			z-index: 99;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(gc(dl-black), .9);
		}
	}

	.dropdown--tooltip.f-open-dropdown + .dropdown--tooltip__wrapper{

		@media #{$small-only}{
			display: block;
		}
	}

	.dropdown--tooltip__icon{
		position: absolute;
		top: ms(2);
		right: ms(2);
		cursor: pointer;

		@media #{$medium-up}{
			display: none;
		}
	}

	.dropdown--html{
		max-width: ms(15);
		padding: ms(2);
	}

	// If the dd is a ul we can go straight to list mode
	.dropdown__item{
		padding: ms(-2) ms(0);
		list-style: none;
		color: gc(dl-charcoal);
		font-weight: normal;
		cursor: pointer;

		// &:first-of-type{
		// 	padding-top: ms(-3) + ms(-3);
		// }

		// &:last-of-type{
		// 	padding-bottom: ms(-3) + ms(-3);
		// }

		&:hover{
			color: gc(dl-black);
		}

		&.is-active{
			color: gc(dl-black);
			font-weight: bold;
		}
	}

	.dropdown__item--no-hover{

		&:hover{
			color: gc(dl-charcoal);

			.icon{
				color: gc(dl-black);
			}
		}

		&.is-active{
			color: gc(dl-charcoal);
			font-weight: normal;
		}
	}

	.dropdown__item--no-hover__text{

		&:hover{
			color: gc(dl-black);
		}
	}

	// dANGEROUS OPTION
	.dropdown__item--warning{
		color: gc(dl-ruby);

		&:hover{
			color: gc(dl-rust);
		}
	}

	// add a border to the top of an item
	.dropdown__item--divider{
		border-top: 1px solid gc(dl-ash);
	}

	// if you need a row in your dropdown that just serves as a title for the next few things
	.dropdown__item--title{
		font-weight: bold;
		cursor: default;

		&:hover{
			color: gc(dl-charcoal);
		}
	}

	// if you need some help text in your dropdown that isn't a link
	.dropdown__item--help{
		color: gc(dl-basalt);
		font-size: ms(0);
		font-style: italic;
		cursor: auto;

		&:hover{
			color: gc(dl-basalt);
		}
	}

	// if you wanna throw an icon in beside your text

	.dropdown__item--icon{

		.icon{
			margin-right: ms(1);
		}

		span{
			vertical-align: text-top;
		}
	}

	/**
	 *
	 *Dropdown with inline icons in header:
	 *
	 *`ul.dropdown` > `li.dropdown__item.dropdown__item--inlinelist` > `.dropdown__item--inlinelist__title`, `.dropdown__item--inlinelist__items` > `.dropdown__item--inlinelist__item`
	 *
	 *A dropdown item with a title and some options (usually icons) displayed inline.
	 *
	 * 	@example
	 *		a(data-dropdown='DropdownWithInlineItems') Click for dropdown
	 *		ul.dropdown#DropdownWithInlineItems(data-dropdown-content)
	 *			li.dropdown__item.dropdown__item--inlinelist
	 *				div.dropdown__item--inlinelist__title Options
	 *				div.dropdown__item--inlinelist__items
	 *					div.dropdown__item--inlinelist__item
	 *						svg.icon.icon--micro
	 *							use(xlink:href='images/sprites.svg#icon-alignleft')
	 *					div.dropdown__item--inlinelist__item
	 *						svg.icon.icon--micro
	 *							use(xlink:href='images/sprites.svg#icon-aligncenter')
	 *					div.dropdown__item--inlinelist__item
	 *						svg.icon.icon--micro
	 *							use(xlink:href='images/sprites.svg#icon-alignright')
	 *			li.dropdown__item.dropdown__item--divider Option with divider modifer
	 *			li.dropdown__item One more option
	 *
	 */

	// if you need multiple choices in icon format

	.dropdown__item--inlinelist{
		display: flex;
		cursor: auto;

		&:hover{
			color: gc(dl-charcoal);
		}
	}

	.dropdown__item--inlinelist__title{
		flex: 1;
		align-self: center;
		margin-right: ms(1);
	}

	.dropdown__item--inlinelist__items{
		display: flex;
		overflow: hidden;
		border: 1px solid gc(dl-grey);
		border-radius: map-get($corner-radius, small);
		cursor: pointer;
	}

	.dropdown__item--inlinelist__item{
		display: block;
		margin: 0;
		padding: ms(-5) ms(-3);
		border-left: 1px solid darken(gc(dl-ash), 0%);
		background: #fff;
		font-size: ms(0);
		text-align: center;
		cursor: pointer;

		&:first-child{
			border-left: 0;
		}
	}


	.dropdown__item--inlinelist__item.is-active{
		border-left-color: gc(dl-grey);
		background: gc(dl-grey);
		color: #fff;
	}

	// if you need to show that a dropdown item is loading

	.dropdown__item.has-spinner{
		padding-left: ms(0) + 1rem;
		transition: padding map-get($transition-duration, fast) $ease-out-quad;

		.spinner{
			@include spinner-small;
			margin-top: ms(0) - 1rem;
			margin-right: ms(-2);
			margin-left: -1rem;
		}
	}


	.dropdown__itemcontent{
		flex: 1;
	}


	.dropdown__tools{
		float: right;
		font-size: ms(-2);
		font-weight: normal;
		line-height: ms(2);
		text-decoration: underline;

		*{
			flex: 1;
		}
	}

	/**
	 *
	 *Selectable dropdowns:
	 *
	 *`.dropdown.dropdown--selectable`
	 *
	 *Selectable dropdowns operate a bit like select boxes; one of the `dropdown__item`s inside
	 *appears selected.
	 *
	 * 	@example
 	 *		a(data-dropdown='DropdownSelectable') Selectable dropdown
	 *		ul.dropdown#DropdownSelectable(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item.is-active Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 */

	.dropdown--selectable{
		background: $glb-layout-color;

		.dropdown__item{
			padding-left: ms(4);
		}

		.dropdown__item.is-active{
			font-weight: bold;

			.dropdown__tick{
				@include ui-checkbox-checked($color: gc(dl-mediumgrey), $size: ms(-2));
				display: block;
				position: absolute;
				left: ms(0);
				margin-top: ms(-6);
			}
		}
	}

	.dropdown--selectable.dropdown--juriswitcher{

		.dropdown__item{
			padding-left: ms(5);
		}

		.dropdown__item.is-active::before{
			top: ms(0);
			left: ms(2);
		}
	}

	/**
	 *
	 *Pip positions:
	 *
	 *`.dropdown--left`,`.dropdown--right`,`.dropdown--topright`
	 *
	 *Move the dropdown to the side of the click element instead of on top of it
	 * (default is bottom left).
	 *
	 *Note that as well as the class above, there is also an align option data
	 *attribute on the trigger.
	 *
	 * 	@example
 	 *		a(data-dropdown='DropdownWithRight', data-options='align: right') Right dropdown
	 *		ul.dropdown.dropdown--right#DropdownWithRight(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 *		a(data-dropdown='DropdownWithLeft', data-options='align: left') Left dropdown
	 *		ul.dropdown.dropdown--left#DropdownWithLeft(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 *		a(data-dropdown='DropdownWithTop', data-options='align: top') Top dropdown
	 *		ul.dropdown.dropdown--top#DropdownWithTop(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 *		a(data-dropdown='DropdownWithbottomRight') bottom right dropdown
	 *		ul.dropdown.dropdown--bottomright#DropdownWithbottomRight(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 */

	.dropdown--bottomright{
		&::before {
			#{$default-float}: auto;
			#{$opposite-direction}: $f-dropdown-triangle-side-offset;
		}

		&::after {
			#{$default-float}: auto;
			#{$opposite-direction}: $f-dropdown-triangle-side-offset - 1;
		}
	}

	.dropdown--right{
		// taken from foundation _dropdown.scss
		margin-top: 0;
		margin-#{$default-float}: $f-dropdown-margin-right;

		&::before {
			@include css-triangle($f-dropdown-triangle-size, $f-dropdown-triangle-color, #{$opposite-direction});
			position: absolute;
			z-index: 89;
			top: $f-dropdown-triangle-side-offset;
			#{$default-float}: -($f-dropdown-triangle-size * 2);
		}

		&::after {
			@include css-triangle($f-dropdown-triangle-size + 1, $f-dropdown-border-color, #{$opposite-direction});
			position: absolute;
			z-index: 88;
			top: $f-dropdown-triangle-side-offset - 1;
			#{$default-float}: -($f-dropdown-triangle-size * 2) - 2;
		}
	}

	.dropdown--left{
		// taken from foundation _dropdown.scss
		margin-top: 0;
		margin-#{$default-float}: -$f-dropdown-margin-right;

		&::before {
			@include css-triangle($f-dropdown-triangle-size, $f-dropdown-triangle-color, #{$default-float});
			position: absolute;
			z-index: 89;
			top: $f-dropdown-triangle-side-offset;
			#{$opposite-direction}: -($f-dropdown-triangle-size * 2);
			#{$default-float}: auto;
		}

		&::after {
			@include css-triangle($f-dropdown-triangle-size + 1, $f-dropdown-border-color, #{$default-float});
			position: absolute;
			z-index: 88;
			top: $f-dropdown-triangle-side-offset - 1;
			#{$opposite-direction}: -($f-dropdown-triangle-size * 2) - 2;
			#{$default-float}: auto;
		}
	}

	.dropdown--top,
	.dropdown--topleft,
	.dropdown--topright{
		// taken from foundation _dropdown.scss
		margin-top: -$f-dropdown-margin-bottom;
		margin-left: 0;

		&::before {
			@include css-triangle($f-dropdown-triangle-size, $f-dropdown-triangle-color, top);
			position: absolute;
			z-index: 89;
			top: auto;
			bottom: -($f-dropdown-triangle-size * 2);
			// #{$default-float}: $f-dropdown-triangle-side-offset;
			// #{$opposite-direction}: auto;
		}

		&::after {
			@include css-triangle($f-dropdown-triangle-size + 1, $f-dropdown-border-color, top);
			position: absolute;
			z-index: 88;
			top: auto;
			bottom: -($f-dropdown-triangle-size * 2) - 2;
			// #{$default-float}: $f-dropdown-triangle-side-offset - 1;
			// #{$opposite-direction}: auto;
		}
	}

	.dropdown--top,
	.dropdown--topleft{
		&::before {
			#{$default-float}: $f-dropdown-triangle-side-offset;
			#{$opposite-direction}: auto;
		}

		&::after {
			#{$default-float}: $f-dropdown-triangle-side-offset - 1;
			#{$opposite-direction}: auto;
		}
	}

	.dropdown--topright{
		&::before {
			#{$default-float}: auto;
			#{$opposite-direction}: $f-dropdown-triangle-side-offset;

		}

		&::after {
			#{$default-float}: auto;
			#{$opposite-direction}: $f-dropdown-triangle-side-offset - 1;
		}
	}

	/**
	 *
	 *Pipless:
	 *
	 * `.dropdown.dropdown--nopip`
	 *
	 *Sometimes you don't want the little pointer arrow. Add `.dropdown--nopip` to the
	 *dropdown element to hide it. This can be used with any other dropdown modifier.
	 *
	 * 	@example
	 *		a(data-dropdown='DropdownWithNoPip') Click me to see a dropdown with a list in it
	 *		ul.dropdown.dropdown--nopip#DropdownWithNoPip(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 */

	.dropdown--nopip{
		@media #{$medium-up}{
			&.dropdown::before,
			&.dropdown::after{
				display: none;
			}
		}
	}

	/**
	 *
	 *Collapsed:
	 *
	 *`.dropdown.dropdown--collapsed`
	 *
	 *Dropdowns usually have a min width so they can escape their container if it is really small
	 * but sometiomes we need to really pack them into a tight space to stop blowout. Collapsing a dropdown simply removes its `min-width` property.
	 *
	 * 	@example
	 *		a(data-dropdown='dropdpwnCollapsed') Click me to see a collapsed dropdown
	 *		ul.dropdown.dropdown--collapsed#dropdpwnCollapsed(data-dropdown-content)
	 *			li.dropdown__item Dropdown Item 1
	 *			li.dropdown__item Dropdown Item 2
	 *			li.dropdown__item Dropdown Item 3
	 *
	 */

	.dropdown--collapsed{
		width: auto;
	}

	/**
	 *
	 *Dropdown link:
	 *
	 *`.dropdowntext`
	 *
	 *Adds a pip to a text or inline element to make it obviously a dropdown action.
	 *
	 * 	@example
	 *		div.dropdowntext Don't I look clickable
	 *
	 */

	.dropdowntext{
		margin-right: ms(1);
		cursor: pointer;

		&:last-child{
			// often a little bit of the triangle overflow dues to subpixel rendering
			// so we still give it 1px leeway #hatersgonnahate
		}

		&::after{
			@include drop-triangle();
		}
	}

	.dropdowntext--left{ //used to set text-align, width, and max-width so that dropdown can find its correct position
		width: 100%;
		max-width: 200px;
		padding-left: 0;
		text-align: left;
	}

	/**
	* Slat Tools Dropdown (Css Dependent 'Temporary')
	*
	*/
	.dropdown__container{
		position: relative;
	}

	.slat__tools--dropdown{
		position: absolute;
		top: 25px;
		right: 5%;
		width: 300px;
		border: 1px solid gc(dl-grey);
		border-radius: map_get($border-radius, small);
		background: gc(dl-white);
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .2);
	}

	//Hacks and overwritten rules for REACT TOOLTIP
	// scss-lint:disable ImportantRule
	.popper__wrapper{
		z-index: 11;
		left: auto !important;
		min-width: 200px;

		.popper__arrow{
			display: none;
			border-color: gc(dl-grey) !important;
		}

		.popper{
			min-width: 0 !important;

			.popper__inner{
				padding: ms(-3) !important;
				border: none !important;
				box-shadow: 0 0 4px gc(dl-lightgrey) !important;

				ul{
					margin: 0 !important;
				}

				.dropdown__item{
					text-align: left;
				}
			}
		}

		@media #{$medium-up}{
			.popper__arrow{
				display: block;
			}
		}

		@media #{$large-up}{
			left: 0 !important;
		}
	}
	// scss-lint:enable ImportantRule
}
