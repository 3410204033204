@include exports('components/paginators'){
	/***
	 *Paginators:
	 */

	/**
	 *
	 *Basic Pagination:
	 *
	 *`.paginator > .paginator__page + ..`
	 *
	 *The timeless UI pattern. When you need to split stuff over multiple pages, PAGINATE!
	 *
	 * 	@example
	 *		div.card.u-text__center
	 *			ul.paginator
	 *				li.paginator__page.paginator__page--prev
	 *					svg.icon.icon--micro.icon--hover
	 *						use('xlink:href'='images/sprites.svg#icon-chevronleft')
	 *				li.paginator__page 1
	 *				li.paginator__page 2
	 *				li.paginator__page.is-active 3
	 *				li.paginator__page 4
	 *				li.paginator__page 5
	 *				li.paginator__page 6
 	 *				li.paginator__page 7
	 *				li.paginator__page 8
	 *				li.paginator__page 9
	 *				li.paginator__page.paginator__page--last 22
 	 *				li.paginator__page.paginator__page--next
	 *					svg.icon.icon--micro.icon--hover
	 *						use('xlink:href'='images/sprites.svg#icon-chevronright')
	 *
	 */

	.paginator{
		display: block;
		margin: 0 0 ms(1);
		padding: 0;
		list-style: none;
		color: gc(dl-charcoal);
		font-size: 0;
		text-align: center;
	}

	.paginator__page{
		display: inline-block;
		width: ms(4);
		border-bottom: 2px solid gc(dl-grey);
		font-size: 1rem;
		text-align: center;
		cursor: pointer;

		&:hover{
			border-bottom-color: gc(dl-basalt);
			color: gc(dl-charcoal);
		}

		&.is-active{
			// border-bottom-color: $glb-primary-color;
			// color: $glb-primary-color;
			font-weight: bold;
		}
	}


	.paginator__page--last{
		margin-left: ms(1);
	}

	.paginator__page--prev{
		// margin-right: ms(1);
		border-bottom: 0;
	}

	.paginator__page--next{
		// margin-left: ms(1);
		border-bottom: 0;
	}

}
