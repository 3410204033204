//The right sidebar is a bit more complex than the left because we need to use all kinds of hackery
//To make ui draggable stuff from inside it work
//If you change any positioning stuff, make sure to test doctype admin draggin blocks from
//the sidebar into the main content area

@include exports('modules/sidebarright'){
	.sidebarright{
		display: none;
		position: fixed;
		z-index: 10;
		top: $topbar-height;
		right: 0;
		bottom: 0;
		// width: map-get($glb-sidebar-width, full); // turned off for small screen right sidebar removal
		height: 100%; // hack for devices that dont support calc
		transform: translateX(100%);
		transition: all #{map-get($transition-duration, default)} $ease-in-out-quad;
		background: gc(dl-ash);

		@media #{$medium-up} {
			width: map-get($glb-sidebar-width, full);
			// moving max-width properties into medium-up for small screen right sidebar removal
			// scss-lint:disable PropertySortOrder
			max-width: 75%;
			// scss-lint:enable PropertySortOrder
			max-width: calc(100% - #{map-get($glb-sidebar-width, compact)} - 2rem);
			transform: translateX(0%);
		}

		@media #{$large-up} {
			width: map-get($glb-sidebar-width, jumbo);
		}

		.has-sidebar-right &{
			display: block;
			margin-top: ms(3);
		}

		.has-expanded-right-sidebar &{
			// right: 0; // we only need this for devices that dont understand calc() but it shoudnt hurt if we've done our maths right.
			// left: auto; //if calc doesnt work
			// left: calc(100% - #{map-get($glb-sidebar-width, jumbo)}); //if it does, we get a nice transition
			transform: translateX(0%);

			box-shadow: 0 0 ms(1) rgba(0, 0, 0, .2);
		}
	}

	.sidebarright>div{
		// hack for devices that dont support calc
		// the wrapper needs to be 100% as well so it doesnt create
		// containing box for the children
		height: 100%;
	}


	// We need a fixed width wrapper in case none of the
	// content is 100%++ the width of the sidebar. In that case,
	// Stuff jumps around a bit when it suddenly gets absolute positioning

	// ^^  With UI 1,2 is this still relevant? have removed width and replaced with max-w, lets see if anything breaks ;) G 23/04/15
	.sidebarright__content{
		// width: map-get($glb-sidebar-width, jumbo);  // why was this required? Something to do with draggable and doctypeadmin I think
		height: 100%; // hack for devices that dont support calc
		height: calc(100vh - #{$topnav-height}); // counterract topnav
		padding: ms(1);
		// scss-lint:disable PropertySortOrder
		padding-bottom: $topnav-height; // hack for devices that dont support calc. We need to make sure theres enough extra hanging off the bottom of the viewport with nothing in it so we can scroll the actual content into view
		// scss-lint:enable PropertySortOrder
		padding-bottom: calc(#{ms(1)}); // Unhack for devices that do
		overflow-y: auto;

		@media #{$medium-up}{
			max-width: map-get($glb-sidebar-width, jumbo);	// moving into media query for small screen right sidebar removal
		}

		&::-webkit-scrollbar{
			width: ms(-3);
		}

		&::-webkit-scrollbar-thumb{
			background: gc(dl-grey);
		}


		///hack for devices that dont understand vh + calc
		//IF only there was a way we could detact calc safely
		// .has-expanded-right-sidebar &{
		// 	position: fixed;
		// 	top: 0;
		// 	bottom: 0;
		// }
	}



	// EXPANDER TURNED OFF AS SMALL VIEW IS REWORKED.
	// Expander
	//   We don't have much room for 2 sidebars and sidebar right doenst really work in compact mode
	//   so we translate it offscreen and show an expander ovlerlay to indicate it can be translated
	//   back in if we need it.

	// .sidebarright__expander{
	// 	position: absolute;
	// 	top: 50%;
	// 	left: -#{ map-get($icon-size, medium) };
	// 	width: map-get($icon-size, medium);
	// 	padding: ms(1) ms(0);
	// 	transition: all map-get($transition-speed, default) $ease-in-out-quad,
	// 				background map-get($transition-speed, fast) $ease-in-out-quad;
	// 	background: gc(dl-grey);
	// 	box-shadow: 0 1px 1px rgba(gc(dl-black), .5);

	// 	@media #{$medium-up} {
	// 		display: none;
	// 	}

		// run a line up the right side of the viewport
		// Breaks on ios for some reason.
		// &:before{
		// 	position: fixed;
		// 	top: 0;
		// 	bottom: 0;
		// 	left: -#{ms(-4)};
		// 	width: ms(-4);
		// 	transition: all map-get($transition-speed, fast) $ease-in-out-quad;
		// 	background: gc(dl-grey);
		// 	content: '';
		// }

	// 	&:hover{
	// 		// &:hover:before{
	// 		background: gc(dl-sky);
	// 	}

	// 	.has-expanded-right-sidebar &{
	// 		background: rgba(gc(dl-charcoal), .8);

	// 		// &:before{
	// 		// 	width: 0;
	// 		// }

	// 		&:hover{
	// 			background: rgba(gc(dl-sky), .8);
	// 		}
	// 	}

	// }

	// .sidebarright__expandericon{
	// 	display: block;
	// 	transition: all map-get($transition-speed, default) $ease-in-out-quad;

	// 	.has-expanded-right-sidebar &{
	// 		transform: rotate(90deg);
	// 	}
	// }

}
