/***
 * Icons:
 *
 *For the full iconset, see [The generated sprite contact sheet](images/sprites-demo.html)
 *
 * If icons aren't showing, it might be a XSS restriction. Run grunt connect:cssdoc to serve all the assets on the same domain.
 */

/**
 *
 *@mixin icon-square:
 *
 *`@include icon-square($size)`
 *
 *A mixin that returns one of several width/height square combos.
 *
 *
 *```
 *$size      A named size from the $icon-sizes in settings/sizes, options correspond to the declared classes above ['tiny', 'small', 'medium', 'large']
 *```
 *
 */


@include exports('components/icons'){


	/**
	*
	  *Base icon class:
	  *
	  *`.icon`
	  *
	  *Sets the base styles for icons. Icons have a base set size of `--large` and are inline-block
	  *
	  * 	@example
	  *		svg.icon
	  *			use(xlink:href='images/sprites.svg#icon-cross')
	  *
	  */

	.icon{
		@include icon-square(large);
		display: inline-block;
		max-width: 100%;
		max-height: 100%;
		vertical-align: middle;

		&:hover{
			opacity: 1;
		}
	}

	/**
	 *
	 *Icon sizes:
	 *
	 *`.icon--tiny`
	 *`.icon--small`
	 *`.icon--medium`
	 *`.icon--large`
	 *
	 *Set the size of the icon to a pre-defined **square** dimension.
	 *
	 * 	@example
	 *		svg.icon.icon--micro
	 *			use(xlink:href='images/sprites.svg#icon-cross')
 	 *		svg.icon.icon--tiny
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *		svg.icon.icon--small
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *		svg.icon.icon--medium
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *		svg.icon.icon--large
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 * 		svg.icon.icon--jumbo
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *
	 */


	.icon--micro{
		@include icon-square(micro);
	}

	.icon--tiny{
		@include icon-square(tiny);
	}

	.icon--small{
		@include icon-square(small);
	}

	.icon--medium{
		@include icon-square(medium);
	}

	.icon--large{
		@include icon-square(large);
	}

	.icon--jumbo{
		@include icon-square(jumbo);
	}

	.icon--giant{
		@include icon-square(giant);
	}

	.icon--xero{
		@include icon-square(medium);
		height: ms(2);
	}

	/**
	 *
	 *Icon modifiers:
	 *
	 *`.icon--hover`
	 *
	 *Makes the icon semi opaque until mouseovered.
	 *
	 * 	@example
	 *		svg.icon.icon--medium.icon--hover
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *
	 */

	.icon--hover{
		opacity: .5;

		&:hover{
			opacity: 1;
		}
	}

	/**
	 *
	 *Icon modifiers:
	 *
	 *`.icon--toggle`
	 *
	 *Makes the icon semi opaque until mouseovered.
	 *
	 * 	@example
	 *		svg.icon.icon--medium.icon--toggle
	 *			use(xlink:href='images/sprites.svg#icon-cross')
	 *
	 */

	.icon--toggle{
		transform: rotate(0deg);

		.is-expanded &{
			transform: rotate(180deg);
		}
	}

	.icon__notebadge{
		display: inline-block;
		position: absolute;
		top: -(ms(-3));
		right: -(ms(-3));
		width: ms(2);
		height: ms(2);
		transform: scale(0);
		transform-origin: center;
		transition: all map-get($transition-duration, fast) $ease-in-out-quad;
		border-radius: 100%;
	}

	.icon__notebadge--visible{
		transform: scale(1);
	}

	.icon__notebadge--topnav{
		top: -(ms(-2));
		right: -(ms(-2));
	}

	.icon__notebadge-number{
		@include totalcenter;
		color: $white;
		font-size: ms(-2);
		text-align: center;
	}

	/* Icon For different scree
	*
	*	@example
	*		svg.icon.icon--large-medium-up-jumbo
	*			use(xlink:href='images/sprites.svg#icon-cross')
	*/
	.icon--medium-up-jumbo{
		@include icon-square(medium);
		@media #{$medium-up}{
			@include icon-square(jumbo);
		}
	}
}
