@include exports('components/pricetags'){
	 /***
	 *Pricetags:
	 *
	 *
	 *

	 */

	/**
	 *
	 *Basic Pricetag:
	 *
	 *`.pricetag`
	 *
	 *A pricetag is a wee component to help format prices in a way that looks nice.
	 *
	 * 	@example
	 *		div.card
	 *			div.pricetag
	 *				span.pricetag__pre HKD
	 *				span.pricetag__amount 8,000
 	 *				span.pricetag__post per year
	 *
	 *
	 */


	.pricetag{
		margin: ms(1) 0;
		line-height: 1;
	}

	.pricetag__amount{
		font-size: ms(5);
		font-weight: bold;
	}

	.pricetag__pre{
		display: block;
		font-size: ms(0);
		font-weight: bold;
	}

	.pricetag__post{
		// margin-left: ms(-1);
		font-size: ms(0);
	}

	/**
	 *
	 *inline pricetag:
	 *
	 *`.pricetag--inline`
	 *
	 *A smaller, one-line pricetag.
	 *
	 * 	@example
	 *		div.card
	 *			div.pricetag.pricetag--inline
	 *				span.pricetag__pre HKD
	 *				span.pricetag__amount 8,000
 	 *				span.pricetag__post per year
	 *
	 */

	.pricetag--inline{
		margin: 0;

		.pricetag__amount{
			display: inline;
			font-size: ms(3);
		}


		.pricetag__pre{
			display: inline;
			margin-right: ms(-4);
		}

		.pricetag__post{
			display: inline;
			margin-left: ms(-4);

		}
	}

	.pricetag--inline-large{
		@extend .pricetag--inline;

		.pricetag__amount{
			font-size: ms(5);
		}
	}
}
