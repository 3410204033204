@include exports('components/textbuttons'){
	/***
	 * Text Buttons:
	 */


	/**
	 *
	 *Text button:
	 *
	 *`.textbutton`
	 *
	 *Textbuttons are used for de-emphasised actions like cancel. They have the same box model properties as regular buttons so can be mixed freely.
	 *
	 * 	@example
	 *		div.textbutton I am a basic text button
     *		div.button Playing nice with regular buttons
	 *
	 */

	.textbutton{
		@include button-base;
		@include button-size;
		@include trans(fast);
		margin-right: ms(0);
		padding: 0;
		padding-right: ms(0);
		border: 0;
		background: none;
		// color: $glb-primary-color;
		text-align: center;
		text-decoration: underline;
		cursor: pointer;

		// &:hover{
		// 	color: $glb-primary-hover-color;
		// }

		&:last-of-type{
			margin-right: 0;
		}
	}


	/**
	 *
	 *Button sizes:
	 *
	 *`.textbutton.textbutton--small` `.textbutton.textbutton--large`
	 *
	 *A smaller and larger version of the main textbutton classes. Don't mix them! That's nasty.
	 *
	 * 	@example
	 *		div
	 *			div.textbutton.textbutton--small I am a small textbutton
 	 *		 div
	 *			div.textbutton I am a regular textbutton
	 *		 div
	 *			div.textbutton.textbutton--large I am a large textbutton
	 *
	 */

	.textbutton.textbutton--small{
		@include button-size($button-sml);
	}

	.textbutton.textbutton--large{
		@include button-size($button-lrg);
	}

	/**
	 *
	 *Button colors:
	 *
	 *`.textbutton.textbutton--secondary`
	 *
	 *Now that textbuttons take the primary color by default, we have a modifier to grey them out.
	 * Note: for consistency I plan to refactor this so that the colorful ones get a primary modifier instead.
	 *
	 * 	@example
	 *		div.card
	 *			div.textbutton.textbutton--secondary I am a secondary textbutton
	 *
	 */

	// .textbutton--secondary{ // See themes
		// color: gc(dl-basalt);
		//
		// &:hover{
		// 	color: darken(gc(dl-basalt), $button-function-factor);
		// }
	// }

	.textbutton--nounderline{ //@TODO: document this
		text-decoration: none;
	}

	/**
	 *
	 *Warning textbutton:
	 *
	 *`.textbutton.textbutton--warning`
	 *
	 *Use sparingly for irrecoverable actions like deleting.
	 *
	 * 	@example
	 *		div.textbutton.textbutton--warning Danger! Danger! High voltage
	 *
	 */

	// .textbutton.textbutton--warning{ // moved into themes
	// 	color: gc(dl-ruby);
	//
	// 	&:hover{
	// 		color: darken(gc(dl-ruby), $button-function-factor);
	// 	}
	// }

	// .textbutton.textbutton--primary{
	// 	color: $glb-primary-color;
	// }

	// .textbutton.textbutton--primary:hover{
	// 	color: $glb-primary-hover-color;
	// }

	/**
	 *
	 *Block text button:
	 *`.textbutton.textbutton--block`
	 *
	 * Takes any textbutton class and makes it a full width block element.
	 *
	 * 	@example.slim
	 *		div.textbutton.textbutton--block I am a block text button
	 *
	 */

	.textbutton.textbutton--block{
		display: block;
	}

	// .textbutton.textbutton--front-page{
	// 	color: $glb-primary-color;
	// }

	// .textbutton.textbutton--front-page:hover{
	// 	color: $glb-primary-hover-color;
	// }


	/**
	 *
	 *Icon textbuttons:
	 *
	 *`.textbutton > svg.icon`
	 *
	 *Simply putting an `.icon` class svg inside a textbutton is enough.
	 *Usually, you'll want an `.icon--tiny` icon.
	 *
	 * 	@example
	 *		div.textbutton
	 *			svg.icon.icon--tiny
	 *				use(xlink:href='images/sprites.svg#icon-plus')
	 *			|Add to the pile
 	 *		div.textbutton
	 *			svg.icon.icon--tiny
	 *				use(xlink:href='images/sprites.svg#icon-cros')
	 *			|Remove
	 *
	 */

	.textbutton .icon{
		margin-right: ms(-2);
		vertical-align: middle;
	}

	.textbutton .icon.icon--tiny{
		vertical-align: text-top;
	}

	/**
	 *
	 *has-spinner state:
	 *
	 *
	 *`.textbutton.has-spinner`
	 *
	 *A state where the button indicates the app is processing a task. So just wait, you impatient person.
	 *
	 *NB. this state relies on `loading:inline` being triggered by the app.
	 *
	 * 	@example
 	 *		div.textbutton.has-spinner
	 *			div.spinner
	 *			|I am a busy spinner
	 *
	 */

	.textbutton{
		&.has-spinner{
			padding-left: $button-med * 2 + 1rem;
			transition: padding map-get($transition-duration, fast) $ease-out-quad;
		}

		.spinner{
			@include spinner-small;
			margin-right: ms(-2);
			margin-left: -1rem;
		}
	}

} // exports
