@mixin button-global{
	@include button-base;
	@include button-size;
	@include button-style(
		$radius: map-get($corner-radius, small)
		// $bg: gc(dl-ash),
		// $bg-hover: gc(dl-graphite)
	);
	@include trans(fast);
	margin-right: ms(0);
	border: 0;
	// color: gc(dl-superblack);
	text-transform: none;

	&:last-of-type{
		margin-right: .5rem;
	}
}

@mixin button-disabled($color: gc(dl-ash)){
	// border-color: $color;
	// background: $color;
	cursor: default;
	opacity: $button-disabled-opacity;

	// &:hover{
	// 	background: $color;
	// }
}
