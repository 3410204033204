@include exports('components/leftnav') {
	.leftnav{
		display: flex;
		max-width: 1240px;
		margin: 0 auto;
		padding: 0;
		background: gc(dl-white);
		box-shadow: 0 0 4px gc(dl-lightgrey);

		@media #{$medium-up}{
			position: fixed;
			z-index: 0;
			top: 0;
			right: auto;
			left: 0;
			box-sizing: border-box;
			width: $side-nav-width;
			height: 100%;
			padding-left: ms(2);
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	a.is-active{
		.leftnav__list--item{
			color: gc(dl-teal);
			font-weight: bold;
		}
	}

	.leftnav__list--item{
		padding: ms(-2);
		color: $topnav-textcolor;
		font-size: ms(1);

		@media #{$medium-up}{
			padding: .6rem 0;
		}

		&:hover{
			color: gc(dl-teal);
		}

		&.is-active{
			@extend a, .is-active;
		}

		&.is-end{
			margin-bottom: ms(2);
			padding-bottom: ms(4);
			border-bottom: solid 2px gc(dl-slate);
		}

		&.is-start{
			margin-top: ms(2);
			padding-top: ms(4);
			border-top: solid 2px gc(dl-slate);
		}
	}

	.leftnav__list{
		> a{
			text-decoration: none;
		}

		> a > div.leftnav__list--item{
			display: none;

			@media #{$medium-up}{
				display: block;
			}
		}

		&.is-open{
			width: 100%;

			a .leftnav__list--item{
				display: block;
				padding: ms(2);
			}
		}

		/*
		* This selector brings border line under the second item of .leftnav__list
		*/

		> :nth-child(2) div.leftnav__list--item {
			@extend .leftnav__list--item, .is-end;
		}

		/*
		* This selector brings border line on the top of last item of .leftnav__list
		*/

		> :last-child div.leftnav__list--item {
			@extend .leftnav__list--item, .is-start;
		}

	}

	.leftnav__list--item-logo{
		width: 100%;

		.icon{
			@include icon-square(ms(6));
		}

		@media #{$medium-up}{
			padding: ms(0) 0;

			.topnav__menutoggle{
				display: none;
			}

			.icon{
				@include icon-square(ms(7));
			}
		}
	}

	/*
	* Divides the left navigationa and main content of the admin app
	*
	*/
	.main-content {
		width: 100%;

		.sidebar-left{
			overflow: hidden;
		}

		.content-right{
			@media #{$medium-up}{
				margin-left: $side-nav-width;
			}

			@media #{$xlarge-up}{
				padding: ms(0) ms(1) ms(1);
			}
		}
	}

	.leftnav::-webkit-scrollbar {
		width: 0.4rem;
	}

	.leftnav::-webkit-scrollbar-track {
		margin: .1rem 0;
		background-color: transparent;
	}

	.leftnav::-webkit-scrollbar-thumb {
		background-color: #cccccc;
		-webkit-border-radius: 10px;
	  }
}
