@include exports(vendor / draft){

	.root {
		border: 1px solid #ddd;
		border-radius: 3px;
		background: #fff;
		font-family: 'Georgia', serif;
		font-size: 14px;
	}

	.editor {
		font-size: 16px;
		cursor: text;
	}

	.editor :global(.public-DraftEditorPlaceholder-root),
	.editor :global(.public-DraftEditor-content) {
		margin: 0;
		/* 1px is added as transparent border on .DraftEditor-editorContainer */
		padding: 9px;
	}

	.editor :global(.public-DraftEditor-content) {
		overflow: auto;
	}

	.hidePlaceholder :global(.public-DraftEditorPlaceholder-root) {
		display: none;
	}

	.editor .paragraph {
		margin: 14px 0;
	}

	/* Consecutive code blocks are nested inside a single parent <pre> (like <li>
		inside <ul>). Unstyle the parent and style the children. */
	.editor pre {
		margin: 14px 0;
	}

	.editor .codeBlock {
		margin: 14px 0;
		padding: 20px;
		background-color: #f3f3f3;
		font-family: "Inconsolata", "Menlo", "Consolas", monospace;
		font-size: 16px;
		/* This should collapse with the margin around the parent <pre>. */
	}

	/* Hacky: Remove padding from inline <code> within code block. */
	.editor .codeBlock span[style] {
		padding: 0 !important;
	}

	.editor .blockquote {
		margin: 16px 0;
		padding: 10px 20px;
		border-left: 5px solid #eee;
		color: #666;
		font-family: 'Hoefler Text', 'Georgia', serif;
		font-style: italic;
	}

	/* There shouldn't be margin outside the first/last blocks */
	.editor .block:first-child,
	.editor pre:first-child,
	.editor ul:first-child,
	.editor ol:first-child {
		margin-top: 0;
	}

	.editor .block:last-child,
	.editor pre:last-child,
	.editor ul:last-child,
	.editor ol:last-child {
		margin-bottom: 0;
	}

	/**
	 * We inherit the height of the container by default
	 */

	.DraftEditor-root,
	.DraftEditor-editorContainer,
	.public-DraftEditor-content {
		height: inherit;
		text-align: initial;
	}

	.DraftEditor-root {
		position: relative;
	}

	/**
	 * Zero-opacity background used to allow focus in IE. Otherwise, clicks
	 * fall through to the placeholder.
	 */

	.DraftEditor-editorContainer {
		position: relative;
		z-index: 1;
		border: 1px solid transparent;
		background-color: rgba(255, 255, 255, 0);
		/* Repair mysterious missing Safari cursor */
	}

	.public-DraftEditor-content {
		outline: none;
		white-space: pre-wrap;
	}

	.public-DraftEditor-block {
		position: relative;
	}

	.DraftEditor-alignLeft .public-DraftEditor-block {
		text-align: left;
	}

	.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
		left: 0;
		text-align: left;
	}

	.DraftEditor-alignCenter .public-DraftEditor-block {
		text-align: center;
	}

	.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}

	.DraftEditor-alignRight .public-DraftEditor-block {
		text-align: right;
	}

	.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
		right: 0;
		text-align: right;
	}
	/**
	 * @providesModule DraftEditorPlaceholder
	 */

	.public-DraftEditorPlaceholder-root {
		position: absolute;
		z-index: 0;
		color: #9197a3;
	}

	.public-DraftEditorPlaceholder-hasFocus {
		color: #bdc1c9;
	}

	.DraftEditorPlaceholder-hidden {
		display: none;
	}
	/**
	 * @providesModule DraftStyleDefault
	 */

	.public-DraftStyleDefault-block {
		position: relative;
		white-space: pre-wrap;
	}

	/* @noflip */

	.public-DraftStyleDefault-ltr {
		text-align: left;
		direction: ltr;
	}

	/* @noflip */

	.public-DraftStyleDefault-rtl {
		text-align: right;
		direction: rtl;
	}

	/**
	 * These rules provide appropriate text direction for counter pseudo-elements.
	 */

	/* @noflip */

	.public-DraftStyleDefault-listLTR {
		direction: ltr;
	}

	/* @noflip */

	.public-DraftStyleDefault-listRTL {
		direction: rtl;
	}

	/**
	 * Default spacing for list container elements. Override with CSS as needed.
	 */

	.public-DraftStyleDefault-ul,
	.public-DraftStyleDefault-ol {
		margin: 16px 0;
		padding: 0;
	}

	/**
	 * Default counters and styles are provided for five levels of nesting.
	 * If you require nesting beyond that level, you should use your own CSS
	 * classes to do so. If you care about handling RTL languages, the rules you
	 * create should look a lot like these.
	 */

	/* @noflip */

	.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
		margin-left: 1.5em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
		margin-right: 1.5em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
		margin-left: 3em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
		margin-right: 3em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
		margin-left: 4.5em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
		margin-right: 4.5em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
		margin-left: 6em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
		margin-right: 6em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
		margin-left: 7.5em;
	}

	/* @noflip */

	.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
		margin-right: 7.5em;
	}

	/**
	 * Only use `square` list-style after the first two levels.
	 */

	.public-DraftStyleDefault-unorderedListItem {
		position: relative;
		list-style-type: square;
	}

	.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
		list-style-type: disc;
	}

	.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
		list-style-type: circle;
	}

	/**
	 * Ordered list item counters are managed with CSS, since all list nesting is
	 * purely visual.
	 */

	.public-DraftStyleDefault-orderedListItem {
		position: relative;
		list-style-type: none;
	}

	/* @noflip */

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR::before {
		position: absolute;
		left: -36px;
		width: 30px;
		text-align: right;
	}

	/* @noflip */

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL::before {
		position: absolute;
		right: -36px;
		width: 30px;
		text-align: left;
	}

	/**
	 * Counters are reset in JavaScript. If you need different counter styles,
	 * override these rules. If you need more nesting, create your own rules to
	 * do so.
	 */

	.public-DraftStyleDefault-orderedListItem::before {
		content: counter(ol0) ' . ';
		counter-increment: ol0;
	}

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1::before {
		content: counter(ol1) '. ';
		counter-increment: ol1;
	}

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2::before {
		content: counter(ol2) '. ';
		counter-increment: ol2;
	}

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3::before {
		content: counter(ol3) '. ';
		counter-increment: ol3;
	}

	.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4::before {
		content: counter(ol4) '. ';
		counter-increment: ol4;
	}

	.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
		counter-reset: ol0;
	}

	.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
		counter-reset: ol1;
	}

	.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
		counter-reset: ol2;
	}

	.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
		counter-reset: ol3;
	}

	.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
		counter-reset: ol4;
	}
}
