@import '/src/styles/2.19/helpers/spinners';

//  mixin to add to any form elements that want to use the inline downard facing chevron (select, chosen)
@mixin _form__bg-down-pip{
	// background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%208.941%205.885%22%3E%3Cpolyline%20style%3D%22fill%3Anone%3Bstroke%3A%23CCCBCB%3Bstroke-width%3A2%3Bstroke-miterlimit%3A10%3B%22%20points%3D%228.234%2C0.707%204.471%2C4.471%200.707%2C0.707%22%2F%3E%3C%2Fsvg%3E');
	background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2013.414%208.827%22%3E%3Cpolyline%20style%3D%22fill%3Anone%3Bstroke%3A%23CCCBCB%3Bstroke-width%3A3%3Bstroke-miterlimit%3A10%3B%22%20points%3D%2212.354%2C1.061%206.707%2C6.706%201.061%2C1.061%20%22%2F%3E%3C%2Fsvg%3E%0A');
}

@mixin _form__bg-up-pip{
	background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2013.414%208.826%22%3E%3Cpolyline%20style%3D%22fill%3Anone%3Bstroke%3A%23CCCBCB%3Bstroke-width%3A3%3Bstroke-miterlimit%3A10%3B%22%20points%3D%221.061%2C7.766%206.707%2C2.141%2012.354%2C7.766%22%2F%3E%3C%2Fsvg%3E%0A');
}

@mixin _form__bg-search{
	background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2065.669%2063.245%22%20style%3D%22enable-background%3Anew%200%200%2065.669%2063.245%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20style%3D%22fill%3Anone%3Bstroke%3A%2380807F%3Bstroke-width%3A7.7264%3B%22%20d%3D%22M26.409%2C3.863C38.828%2C3.903%2C48.862%2C13.999%2C48.82%2C26.41%20C48.784%2C38.824%2C38.685%2C48.865%2C26.27%2C48.824C13.859%2C48.783%2C3.822%2C38.684%2C3.863%2C26.269C3.903%2C13.859%2C13.999%2C3.823%2C26.409%2C3.863z%22%2F%3E%3Cline%20style%3D%22fill%3Anone%3Bstroke%3A%23808080%3Bstroke-width%3A10.3833%3Bstroke-linecap%3Around%3Bstroke-miterlimit%3A10%3B%22%20x1%3D%2244.828%22%20y1%3D%2244.936%22%20x2%3D%2260.478%22%20y2%3D%2258.053%22%2F%3E%3C%2Fsvg%3E%0A');
}

@mixin _form__bg-cross{
	background-image: url('data:image/svg+xml;utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2067.494%2067.493%22%20xml%3Aspace%3D%22preserve%22%3E%3Cline%20style%3D%22fill%3Anone%3Bstroke%3A%23808080%3Bstroke-width%3A15%3Bstroke-miterlimit%3A10%3B%22%20x1%3D%2264.666%22%20y1%3D%222.828%22%20x2%3D%222.829%22%20y2%3D%2264.665%22%2F%3E%3Cline%20style%3D%22fill%3Anone%3Bstroke%3A%23808080%3Bstroke-width%3A15%3Bstroke-miterlimit%3A10%3B%22%20x1%3D%222.943%22%20y1%3D%222.952%22%20x2%3D%2264.556%22%20y2%3D%2264.565%22%2F%3E%3C%2Fsvg%3E%0A');
}

@include exports('components/forms'){

	/***
	* Forms:
	*
	* Most form elements will run along just fine on foundation's default form classes.
	* However, because they use an `input[type='']` style selector, their specificity
	* Is already 1x *higher* than what we'd ideally like. We can trump this here.
	* As well as deal with some extra layout stuff. I encourage you to put the base forms components
	* classes on input elements anyway just for consistency.
	*
	* The [full foundation form suite](http://foundation.zurb.com/docs/components/forms.html) for base form elements is available for reference.
	*/


	/**
	 *
	 *Base form elements:
	 *
	 *For inputs, selects and textareas with no classes this is what you get.
	 *
	 * 	@example
	 *		div.row
	 *			div.small-12.medium-6.columns
	 *				label date
	 *				input(type="date")
	 *				label color
	 *				input(type="color")
	 *				label datetime
	 *				input(type="datetime")
	 *				label datetime-local
	 *				input(type="datetime-local")
	 *				label email
	 *				input(type="email")
	 *				label month
	 *				input(type="month")
	 *				label number
	 *				input(type="number")
	 *				label password
	 *				input(type="password")
	 *				label search
	 *				input(type="search")
	 *			div.small-12.medium-6.columns
	 *				label tel
	 *				input(type="tel")
	 *				label text
	 *				input(type="text")
	 *				label text disabled
	 *				input(type="text" disabled)
	 *				label time
	 *				input(type="time")
	 *				label url
	 *				input(type="url")
	 *				label week
	 *				input(type="week")
	 *				label textarea
	 *				textarea
	 *				label select
	 *				select
	 *					option Select
	 *				select
	 *					optgroup(label="Optgroup 1")
	 *						option Select with optgroups
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
 	 *					optgroup(label="Optgroup 2")
	 *						option Select
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
	 *					optgroup(label="Disabled optgroup", disabled)
	 *						option Select
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
	 */


	// scss-lint:disable VendorPrefix
	::-webkit-input-placeholder {
		opacity: .4;
	}
	// scss-lint:enable VendorPrefix

	// scss-lint:disable VendorPrefix
	::-moz-placeholder { /* Firefox 18- */
		opacity: .4;
	}
	// scss-lint:enable VendorPrefix

	// scss-lint:disable VendorPrefix
	:-ms-input-placeholder {
		opacity: .4;
	}
	// scss-lint:enable VendorPrefix

	[placeholder]:focus::-webkit-input-placeholder {
		transition: opacity map-get($transition-duration , default) map-get($transition-duration , default) $ease-in-out-quad;
		opacity: 0;

		.modal &{
			// unset transition in modals because it causes ugly subpixel dithering
			transition: opacity 0;
		}
	}

	/**
	 *
	 *Basic form elements:
	 *
	 *`.form__element`
	 *
	 *Adding the `form__element` class to base elements doesnt do much on its own.
	 * It sets the height of textareas and makes the select dropdown pip a bit nicer.
	 *
	 * 	@example
	 *		div.row
	 *			div.small-12.medium-6.columns
	 *				label date
	 *				input.form__element(type="date")
	 *				label color
	 *				input.form__element(type="color")
	 *				label datetime
	 *				input.form__element(type="datetime")
	 *				label datetime-local
	 *				input.form__element(type="datetime-local")
	 *				label email
	 *				input.form__element(type="email")
	 *				label month
	 *				input.form__element(type="month")
	 *				label number
	 *				input.form__element(type="number")
	 *				label password
	 *				input.form__element(type="password")
	 *				label search
	 *				input.form__element(type="search")
	 *			div.small-12.medium-6.columns
	 *				label tel
	 *				input.form__element(type="tel")
	 *				label text
	 *				input.form__element(type="text")
	 *				label text disabled
	 *				input.form__element(type="text" disabled)
	 *				label time
	 *				input.form__element(type="time")
	 *				label url
	 *				input.form__element(type="url")
	 *				label week
	 *				input.form__element(type="week")
	 *				label textarea
	 *				textarea.form__element
	 *				label select
	 *				select.form__element
	 *					option Select
	 *					option I'm a select
	 *					option I'm a select with a deviously long option text
	 *					option I'm a select
 	 *				select.form__element
	 *					optgroup(label="Optgroup 1")
	 *						option Select with optgroups
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
 	 *					optgroup(label="Optgroup 2")
	 *						option Select
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
	 *					optgroup(label="Disabled optgroup", disabled)
	 *						option Select
	 *						option I'm a select
	 *						option I'm a select with a deviously long option text
	 *						option I'm a select
	 */

	input.form__element,
	textarea.form__element,
	select.form__element{
		border-radius: $input-border-radius;
	}


	textarea.form__element{
		min-height: 8.3rem;  // the height of 2 label/input combos
	}

	select.form__element{
		@include _form__bg-down-pip;
		padding-right: ms(4);
		background-repeat: no-repeat;
		background-position: calc(100% - #{ms(1)});
		background-size: ms(-1);
	}

	select.form__element:focus{
		outline-color: gc(dl-teal);
	}



	/**
	 *
	 *Big inputs:
	 *
	 *`.form__element.form__element--big`
	 *
	 *Does what you'd expect, works with text inputs and selects.
	 *
	 * 	@example
	 *		div.row
	 *			div.small-12.medium-6.columns
	 *				input.form__element.form__element--big(type="text", placeholder="Imma big")
	 *			div.small-12.medium-6.columns
	 *				select.form__element.form__element--big
	 *					option Imma big select
	 *					option Imma big select with a deviously long option text
	 *					option Imma big select
	 *
	 */

	.form__element--big,
	input.form__element--big,
	select.form__element--big{
		height: $big-form-height;
		padding: ms(0);
		font-size: ms(2);
	}

	select.form__element--big{
		padding-right: ms(4);
	}

	/**
	 *
	 *Tall inputs:
	 *
	 *`.form__element.form__element--tall`
	 *
	 *Same as big, but doesn't blow up the text. Useful for reserving space for text in a row of big inputs.
	 *
	 * 	@example
	 *		div.row
	 *			div.small-12.medium-6.columns
	 *				input.form__element.form__element--tall(type="text", placeholder="I'm tall")
	 *			div.small-12.medium-6.columns
	 *				select.form__element.form__element--tall
	 *					option I'm a tall select
	 *					option It me
	 *					option One more
	 *
	 */

	.form__element--tall, // just reserve the height without the big type
 	input.form__element--tall,
 	select.form__element--tall{
		height: $big-form-height;
		padding: ms(0);
 	}

	/**
	 *
	 *Small inputs:
	 *
	 *`.form__element.form__element--small`
	 *
	 *There are few reasons to use this. Cramped admin UIs only!
	 *
	 *		@example
	 *		input.form__element.form__element--small(type="text", placeholder="Imma small")
	 *
	 */


	input.form__element--small{
		height: ms(4);
		padding: ms(-3);
		border-width: 1px;
		font-size: ms(0);
	}

	/**
	 *
	 *Inline-block inputs:
	 *
	 *`div.form__inline-block-input`
	 *
	 *Wrap the input to place the label in line before the input for large screens and up.
	 *
	 *		@example
	 *		div.form__inline-block-input
 	 *			label Inline-block label
 	 *			input.form__element(type="text")
	 *
	 */

	.form__inline-block-input,
 	.form__inline-block-input{

		@media #{$large-up}{

			label{
				margin-right: ms(0);
 			}

			input,
 			select{
				width: auto;
 			}

			label,
 			input,
 			select{
				display: inline-block;
 			}
 		}
 	}

	/**
	 *
	 *Input with a loading spinner:
	 *
	 *`.input__spinner > input.form__element.has-spinner + .spinner`
	 *
	 *If you need confirmation that the content of an individual input is on its way, put a spinner in it.
	 * Since, assuming user's browser can do css animations, we can't just throw a background image gif
	 * on the input, instead we wrap the input in a relatively positioned div and add a hidden `.spinner`
	 * BESIDE the input. When the input has a `.has-spinner` class added to it, the absolutely positioned
	 * spinner will appear in the input.
	 *
	 *		@example
	 *		div.input__spinner
	 *			input.form__element.has-spinner(type="text", placeholder="Something is coming!")
	 *			div.spinner
	 *
	 */

	 /**
 	 *
 	 *Small input with a loading spinner:
 	 *
 	 *`.input__spinner > input.form__element.form__element--small.has-spinner + .spinner`
 	 *
 	 *		@example
 	 *		div.input__spinner
 	 *			input.form__element.form__element--small.has-spinner(type="text", placeholder="Something smaller is coming!")
 	 *			div.spinner
 	 *
 	 */

	 /**
 	 *
 	 *Big input with a loading spinner:
 	 *
 	 *`.input__spinner > input.form__element.form__element--big.has-spinner + .spinner`
 	 *
 	 *		@example
 	 *		div.input__spinner
 	 *			input.form__element.form__element--big.has-spinner(type="text", placeholder="Something bigger is coming!")
 	 *			div.spinner
 	 *
 	 */



	.input__spinner{
		position: relative;

		.form__element{
			padding-right: ms(5); // add extra padding so that text won't ever overlap spinner
		}

		.spinner{
			display: none;
		}

		.has-spinner + .spinner{
			@include spinner;
			@include spinner-small;
			display: inline-block;
			position: absolute;
			top: ms(0);
			right: ms(0);
			background: gc(dl-white);
		}

		.form__element--small.has-spinner + .spinner{
			top: ms(-3);
		}

		.form__element--big{
			padding-right: ms(6);
		}

		.form__element--big.has-spinner + .spinner{
			@include spinner;
			top: ms(-2);
		}
	}



	/**
	 *
	 *Ghost inputs:
	 *
	 *`.form__element.form__element--ghost`
	 *
	 *Removes the input border but otherwise uses input styling. Useful for properly aligning filler text between inputs, like 'per' or 'and.' Can be used
	 * as an actual input, or as a placeholder that isn't editable.
	 *
	 *		@example
	 *		input.form__element.form__element--ghost(type="text", placeholder="Boo, et cetera.")
	 *
	 */

	input.form__element--ghost,
	.form__element--ghost{
		border: 0;
		background: none;

		&:focus{
			background: none;
		}
	}

	/**
	 *
	 *Expandable textarea:
	 *
	 *`textarea.textarea--expandable`
	 *
	 *A textarea that is set to regular input height until focused, at which point it expands to accommodate typing.
	 *
	 *		@example
	 *		textarea.textarea--expandable(placeholder="Click me and type something...")
	 *
	 */

	textarea.textarea--expandable{
		height: ms(4);
		min-height: ms(4);
		padding: ms(-3);
		overflow: hidden;
		transition: height map-get($transition-duration, default) $ease-in-out-quad;
		border-width: 1px;
		border-radius: $input-border-radius;
		font-size: ms(0);
		resize: none;
	}

	.textarea--expandable:focus{
		height: 8.3rem;
		overflow: auto;
	}

	/**
	 *
	 *Pin input:
	 *
	 *`.form__element.form__element--pin`
	 *
	 *Makes a real obvious pincode style input for password type inputs.
	 *
	 * 	@example
	 *		input(type='password').form__element.form__element--pin
 	 *		input(type='password').form__element.form__element--big.form__element--pin
	 *
	 */


	input.form__element--pin{
		font-weight: bold;
		letter-spacing: ms(3);
		text-align: center;
		text-indent: ms(3);
	}

	input.form__element--pin-single{ //@TODO: document this
		border-top: 0;
		border-right: 0;
		border-bottom: 2px solid gc(dl-basalt);
		border-left: 0;
		font-size: ms(0);
		text-align: center;

		@media #{$medium-up}{
			height: ms(6);
			font-size: ms(5);
		}
	}

	input.form__element--pin-single.has-error{
		margin-bottom: $form-spacing;
	}

	input.form__element--pin-single.has-error + .form__errorlist.filled{
		display: none;
	}

	/**
	 *
	 *Prefix & Postfix:
	 *
	 *`.prefix` `postfix` `.prefix--big` `postfix--big`
	 *
	 *These are foundation classes that add a little stump onto the beginning or end of the form.
	 *
	 *Also add `.form__element--prefix` and `.form__element--postfix` modifiers to the inputs so that their radius on the adjacent edge is removed.
	 *
	 * 	@example
	 *		.row.collapse
	 *			.small-3.large-2.columns
	 *				span.prefix http://
	 *			  .small-9.large-10.columns
	 *				input.form__element.form__element--prefix(type='text', placeholder='Enter your URL...')
	 *		.row.collapse
	 *			  .small-9.large-10.columns
	 *				input.form__element.form__element--postfix(type='number', placeholder='How much tax do you pay')
	 *			  .small-3.large-2.columns
	 *				span.postfix %
	 *		.row.collapse
	 *			.small-3.large-2.columns
	 *				span.prefix.prefix--big http://
	 *			  .small-9.large-10.columns
	 *				input.form__element.form__element--big.form__element--prefix(type='text', placeholder='Enter your URL...')
	 *		.row.collapse
	 *			  .small-9.large-10.columns
	 *				input.form__element.form__element--big.form__element--postfix(type='number', placeholder='How much tax do you pay')
	 *			  .small-3.large-2.columns
	 *				span.postfix.postfix--big %
	 */

	.prefix--big,
	.postfix--big{
		height: $big-form-height;
		font-size: ms(2);
		line-height: $big-form-height;
	}


	input.form__element--prefix{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	input.form__element--postfix{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	/**
	 *
	 *Form append (alternate type of postfix):
	 *
	 *`.form__appendwrapper` >  `.form__element--append`, `.form__append`, `.form__append--big` > `.form__appendtext`
	 *
	 *An alternative to using the Foundation classes for appending form elements, this is useful when you can't actually
	 * throw in another column reliably to hold the postfix element. This method positions the symbol absolutely
	 * inside the input. Careful to allow the necessary extra space for it!
	 *
	 * 	@example
	 *		div.row
	 *			div.small-12.large-6.columns
	 *				.form__appendwrapper
	 *					div.form__append.form__append--big
	 *						div.form__appendtext %
	 *					input.form__element.form__element--big.form__element--append
	 *			div.small-12.large-6.columns
	 *				div.form__appendwrapper
	 *			  		div.form__append.form__append--big
	 *						div.form__appendtext ?
	 *					input.form__element.form__element--big.form__element--append
	 */

	.form__appendwrapper{
		position: relative;
	}

	input.form__element--append{
		padding-right: ms(5);
	}

	.form__append{
		position: absolute;
		top: 1px;
		right: 1px;
		bottom: 1px;
		width: ms(4);
		border-left: 1px solid $input-border-color;
		border-top-right-radius: map-get($corner-radius, small);
		border-bottom-right-radius: map-get($corner-radius, small);
		background: $input-prefix-bg;
		color: $input-prefix-font-color;
	}

	.form__append--big{
		height: calc(#{$big-form-height} - 2px);
		font-size: ms(2);
	}

	.form__appendtext{
		@include verticalcenter;
		text-align: center;
	}

	/**
	 *
	 *Required fields:
	 *
	 *`.form__row.form__row--required > label + .form__element`
	 *
	 * Add an asterisk to show that this field is required.
	 * We can't add it directly to an element (inputs are self-closing so no :before or :after)
	 * So we wrap the input and label in a `.form__row`.
	 *
	 * For legacy reasons `.form__row--required` can also be added to labels but this is a bit unclean.
	 *
	 * 	@example
	 *		div.form__row.form__row--required
	 *			label text
	 *			input.form__element(type="text")
	 *		div.form__row.form__row--required
	 *			label textarea
	 *			textarea.form__element
	 *		div.form__row.form__row--required
	 *			label select
	 *			select.form__element
	 *				option Select
	 *		div
	 *			label.form__row.form__row--required select legacy form row
	 *			select.form__element
	 *				option Select
	 *
	 */

	.form__row--required{
		position: relative;

		&::after{
			content: '*';
			position: absolute;
			top: ms(-4);
			right: 0;
			color: gc(dl-ruby);
			font-size: ms(3);
		}

		label{
			// make room for the star
			padding-right: ms(1);
		}
	}

	/**
	 *
	 *Invisible fields:
	 *
	 *`.form__row.form__row--invisible`
	 *
	 * Add a blank space to fill in for the label. Useful when you need to reserve space to line up some other form elements properly.
	 * Wrap a `.form__element.form__element--ghost` with it if you need to float an icon, vertically centered, next to a row of inputs,
	 * for example.
	 *
	 *
	 * 	@example
	 *		div.form__row.form__row--invisible
	 *			div.form__element.form__element--ghost
	 *				svg.icon.icon--tiny
	 *					use(xlink:href='images/sprites.svg#icon-circlecross')
	 *
	 */

	.form__row--invisible{
		position: relative;

		//Simulate a label
		&::before{
			content: '\0000a0'; // Basically a non-breaking space
			display: block;
			margin-bottom: $form-label-bottom-margin;
		}

		&::after{
			content: '';
			position: absolute;
			top: ms(-4);
			right: 0;
			font-size: ms(3);
		}
	}

	/**
	 *
	 *Nolabel form row:
	 *
	 *`.form__row--required.form__row--nolabel`
	 *
	 *Sometimes (hopefully not often) you need a form row without a label.
	 * In that case, you'll need to space the form row out by the amount a label would have taken up.
	 *
	 * 	@example
	 *		div.form__row.form__row--required.form__row--nolabel
	 *			input.form__element(type="text" placeholder="I have no label. Naughty!")
	 */

	.form__row--required.form__row--nolabel{
		//Simulate a label
		&::before{
			content: '\0000a0'; // Basically a non-breaking space
			display: block;
			margin-bottom: $form-label-bottom-margin;
		}
	}




	/**
	 *
	 *Form validation:
	 *
	 *Validation is handled by parsely.js.
	 *
	 *
	 * `.has-error` `.has-success`
	 *
	 * 	@example
	 *		div.form__row
	 *			label text
	 *			input.has-success.form__element(type="text")
	 *		div.form__row
	 *			label textarea
	 *			textarea.has-success.form__element
	 *		div.form__row
	 *			label select
	 *			select.has-success.form__element
	 *				option Select
	 *		div.form__row
	 *			label text
	 *			input.has-error.form__element(type="text")
	 *			div.form__errorlist.filled you did something wrong
	 *		div.form__row
	 *			label textarea
	 *			textarea.has-error.form__element
	 *			div.form__errorlist.filled you didn't even write anything!
	 *		div.form__row
	 *			label select
	 *			select.has-error.form__element
	 *				option Select
	 *			div.form__errorlist.filled Bad choice, choose better
	 *
	 *
	 */

	input.has-error,
	textarea.has-error,
	select.has-error{
		margin-bottom: 0;
		transition: margin-bottom 0;
		// scss-lint:disable ImportantRule
		border-color: gc(dl-ruby) !important;
	}

	// input.has-success,
	// textarea.has-success,
	// select.has-success{
	// 	// scss-lint:disable ImportantRule
	// 	border-color: $glb-primary-color !important;
	// }


	.form__errorlist{
		margin: 0 0 $form-spacing;
		// padding: 0 ms(-3);
		padding: 0;
		list-style: none;
		transition: all map-get($transition-duration, default) $ease-in-out-quad;
		opacity: 0;
		color: gc(dl-ruby);
		font-size: ms(0);

		&.filled{
			padding-top: ms(-3);
			opacity: 1;
		}
	}


	/**
	 *
	 *Emulated form fields:
	 *
	 *`.form__textarea`
	 *
	 *Sometimes we need to make a basic element look like a form field (like for pen.js).
	 *
	 * 	@example
	 *		div.form__textarea I am a basic example
	 *
	 */

	.form__textarea{
		// @extend textarea.form__element;
		min-height: 8.3rem;  // the height of 2 label/input combos
		margin-bottom: $form-spacing;
		padding: $form-spacing / 2;
		border: $input-border-width solid $input-border-color;
		border-radius: $input-border-radius;
		background: $input-bg-color;
		box-shadow: $input-box-shadow;

		// &:focus{
		// 	border-color: $input-focus-border-color;
		// }
	}

	/**
	 *
	 *Chosen:
	 *
	 *
	 *Chosen is a select plugin created by harvest.  [Visit project](https://harvesthq.github.io/chosen/)
	 *
	 * Because the css can't meet our code styles, we overwrite in in the 'vendor' folder rather than in _forms.scss
	 *
	 * 	@example
	 *		div.card
	 *			label Simple Chosen
	 *			select.chosenselect.form__element
	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
 	 *
 	 *			label Big Chosen
	 *			select.chosenselect.form__element.form__element--big
	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
	 *
	 *			label Multiple Chosen
	 *			select.chosenselect.form__element.form__element--big(multiple)
	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
 	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
 	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
 	 * 				option option 1
	 * 				option option 2
	 * 				option option 3
	 */

	/**
	 *
	 *Typeahead:
	 *
	 *
	 *Typeahead is our fill-it-in as you type input box built by twitter. [Visit project](https://twitter.github.io/typeahead.js)
	 * Because the css can't meet our code styles, we overwrite in in the 'vendor' folder rather than in _forms.scss
	 *
	 * Adding `.twitter-typeahead--scroll` to the generated typeahead wrapper will constrict the dropdown to a max height and add a scrollbar.
	 *
	 * 	@example
	 *		div.card
	 *			input.form__element.typeahead(type='text', placeholder='type something')
 	 *		div.card
	 *			input.form__element.form__element--big.typeahead(type='text', placeholder='type something BIGGER')
 	 *		div.card.twitter-typeahead--scroll
	 *			input.form__element.typeahead(type='text', placeholder='type something scrollable')
	 *
	 */

}

.workflow_id-list{
	display: block;
	height: 30px;
	font-weight: 500;
	transition: 0.1s all;
}
.workflow_id-list:hover{
	cursor: pointer;
	background: rgb(235, 238, 240);
	border-radius: 4px;
	padding-left: 5px;
}