@include exports('components/uploaders'){
	/***
	 *Uploaders:
	 *
	 * A flex layout row of boxes that show thumbnails or a message to add a new file.
	 */

	/**
	 *
	 *Basic uploader:
	 *
	 *`.uploader > .uploader__row > uploader__item`
	 *
	 *Layout for adding and switching between logos in document preview as well as in org info view.
	 *
	 * 	@example
	 *		div.card
	 *			div.uploader
	 *				div.uploader__row
	 *					div.uploader__item.uploader__item--selected Img Here
	 *					div.uploader__item Img Here
	 *					div.uploader__item.uploader__item--empty Add New Logo
	 *
	 */

	.uploader{
		display: block;
		// padding: 0 ms(1);
		padding: 0;
	}

	.uploader__row{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: ms(2) 0;
		padding: 0;

		.no-flexbox &{
			display: block;
		}

		@media #{$medium-up}{
			justify-content: space-between;
			// padding: 0 ms(2);
		}
	}

	.uploader__item{
		// display: block;
		position: relative;
		width: ms(8);
		max-width: 100%;
		height: ms(8);
		max-height: 100%;
		margin: ms(1);
		padding: ms(0);
		transition: all map-get($transition-duration, fast) $ease-in-out-quad;
		border: 2px solid gc(dl-charcoal);
		border-radius: map-get($border-radius, small);
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;

		@media #{$medium-up}{
			width: ms(9);
			height: ms(9);
			margin: 0;
			padding: ms(2);
		}

		img{
			@include verticalcenter;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.uploader__item--empty{
		*{
			cursor: pointer;
		}

		&::after{
			content: '+';
			display: inline-block;
			position: absolute;
			top: -1px;
			right: -1px;
			width: ms(2);
			height: ms(2);
			border-radius: map-get($border-radius, small);
			background: gc(dl-charcoal);
			color: gc(dl-white);
			font-weight: bold;
		}
	}

	.uploader__item--selected{
		// padding: ms(-4);
		// border-color: $glb-primary-color;
		cursor: default;

		&::after{
			content: '\2714';
			display: inline-block;
			position: absolute;
			top: -1px;
			right: -1px;
			width: ms(2);
			height: ms(2);
			border-radius: map-get($border-radius, small);
			// background: $glb-primary-color;
			color: gc(dl-white);
			font-weight: normal;
		}
	}

	// .uploader__item--selected-doc{
	// 	border-color: gc(dl-canary);
	// 	cursor: default;
	//
	// 	&::after{
	// 		display: inline-block;
	// 		position: absolute;
	// 		top: -1px;
	// 		right: -1px;
	// 		width: ms(2);
	// 		height: ms(2);
	// 		border-radius: map-get($border-radius, small);
	// 		background: gc(dl-canary);
	// 		color: gc(dl-white);
	// 		font-weight: normal;
	// 		content: '\2606';
	// 	}
	// }
}
