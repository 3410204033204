@import '/src/styles/2.19/helpers/themes';

@include theme-colors(
	dragonlaw, 					// Theme name (name)
	gc(dl-teal), 				// Primary color ($glb-primary-color)
	gc(dl-iron), 				// Secondary color ($glb-secondary-color)
	gc(dl-mediumgrey), 			// Tertiary color ($glb-tertiary-color)
	gc(dl-ash), 				// Button border color ($button-bordercolor)
	gc(dl-ash), 				// Button background color ($button-bgcolor)
	gc(dl-graphite), 			// Button background hover color ($button-bgcolor-hover)
	gc(dl-superblack), 			// Button text color ($button-textcolor)
	gc(dl-superblack), 			// Button text hover color ($button-textcolor-hover)
	gc(dl-teal), 				// Textbutton color ($textbutton-color)
	gc(dl-white), 				// Topnav background color ($topnav-bgcolor)
	gc(dl-iron), 				// Topnav text color ($topnav-textcolor)
	gc(dl-ruby) 				// Loading bar (nprogress) color ($loadingbar-color)
);
