@include exports('utilities/positioning'){
	.u-pos__totalcenter{
		@include totalcenter;
	}

	.u-pos__vcenter{
		@include verticalcenter;
	}

	.u-pos__hcenter{
		@include horizontalcenter;
	}

	.u-pos__relative{
		position: relative;
	}

}
