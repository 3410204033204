@include exports('base/elements') {


	html{
		box-sizing: border-box;
		height: 100%;
		overflow-y: scroll;
		font-size: $glb-base-size#{px};  // sizes.scss
		@include hidpi(1.5){
			font-size: $glb-base-size - 2#{px};  // sizes.scss
		}
	}

	*,
	*::before,
	*::after{
		box-sizing: inherit;
	}

	body{
		color: glb-color(dl-black);  // colors.scss
		font-family: $body-font-family;
		font-size: 1rem;
		line-height: 1.3;
		-ms-overflow-style: none;
		overflow:-moz-scrollbars-none;
	}

	body::-webkit-scrollbar {
		width: 0.4rem;
	}

	body::-webkit-scrollbar-track {
		margin: .1rem 0;
		background-color: transparent;
	}

	body::-webkit-scrollbar-thumb {
		background-color: #cccccc;
		-webkit-border-radius: 10px;
	  }

	// a{
	// 	color: gc(dl-teal);
	// }

	// a:hover,
	// a:focus,
	// a:active{
	// 	color: $glb-primary-hover-color;
	// }


	p{
		font-size: inherit;
		line-height: 1.3;
	}

	ul{
		font-size: inherit;
	}

	/***
	 * Type:
	 */

	/**
	 *
	 *Headings:
	 *
	 *`h1`, `h2`, `h3`, `h4`, `h5`,
	 *
	 *
	 *
	 * 	@example
	 *
	 *		h1 Heading 1
	 *		h2 Heading 2
	 *		h3 Heading 3
	 *		h4 Heading 4
	 *		h5 Heading 5
	 *
	 */


	// See Type section in varibales.scss

	// h1{}

	h1,
	h2,
	h3,
	h4,
	h5{
		margin: .5em 0;
		color: inherit;
		line-height: 1.3;

		a,
		a:hover,
		a:focus,
		a:active{
			color: inherit;
			text-decoration: none;
		}
	}

	h1,
	h2,
	h4,
	h5{

		@media #{$small-only}{
			font-weight: bold;
		}
	}
}//exports
