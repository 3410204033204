@include exports('components/cards'){
	/***
	 * Cards:
	 */

	/**
	 *
	 *Basic cards:
	 *
	 *`.card`
	 *
	 *Cards are the world's simplest, dumbest containers.
	 *They are a base component for a ton of other stuff.
	 *
	 * 	@example
	 *		div.card
	 *			h3.card__title A card at its most simple useful form
	 *			p I am a basic example
	 *
	 */
	.card{
		margin-bottom: $card-bottom-margin;
		padding: $card-vert-padding $card-horz-padding;
		border-radius: map-get($corner-radius, small);
		background: gc(dl-white);
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .1);
		// overflow: hidden;

		.no-flexbox &{
			padding: ms(-2) ms(-1);
		}

		.notification &{ // notifications are just boxes that enclose cards, but in themes the have a border, so we need to remove the card margin
			margin-bottom: 0;
		}
	}

	.card--invisible{
		margin: 0;
		padding: 0;
		background: transparent;
		box-shadow: none;
	}

	/**
	 *
	 *Card with restricted content width:
	 *
	 *`.card` > `.card__content.card__content--restricted`
	 *
	 *Rstricts the max-width of content inside a card. Used for legibility in reading
	 * text in cards that stretch to full width of layout.
	 *
	 * 	@example
	 *		div.card
	 *			div.card__content.card__content--restricted
	 *				h3.card__title I am a card whose content width is restricted.
	 *				p Spicy jalapeno bacon ipsum dolor amet pork qui aliquip corned beef commodo. Short ribs chicken voluptate, picanha shankle incididunt non tongue. Lorem sausage et filet mignon voluptate. Pork belly bresaola frankfurter pariatur proident porchetta. Ribeye doner ad in enim turkey ut. Fatback ullamco shankle, in commodo in veniam porchetta minim short ribs chuck flank ham hock biltong.
	 *				p Bresaola beef ribs pariatur exercitation fugiat sunt. Velit short ribs qui doner salami leberkas venison aute. Labore pork chop fugiat excepteur velit. Nisi kielbasa ut incididunt pastrami fugiat dolore velit spare ribs. Cow prosciutto irure pig anim.
	 */

	.card__content--restricted{
		max-width: ms(18);
	}

	/**
	 *
	 *Card state modifiers:
	 *
	 *`.card.is-focused`, `.card.has-error`
	 *
	 *Makes box shadow more prominent, generally on focus of an input inside the card
	 *
	 * 	@example
	 *		div.card.is-focused
	 *			h3.card__title A card at its most simple useful form
	 *			p I am a card you are interacting with
	 *
	 *`.card.has-error`
	 *
	 *Adds a red border and box shadow, generally to call attention to an error within the card.
	 *
	 * 	@example
	 *		div.card.has-error
	 *			h3.card__title A card at its most simple useful form
	 *			p ALERT!!! I am a card that needs your attention!
	 *
	 *`.card.has-border`
	 *
	 *Adds a transparent border. Use with a u-colorborder utility class to color the border.
	 *
	 * 	@example
	 *		div.card.has-border.u-colorborder__dl-canary
	 *			h3.card__title A card with a border
	 *			p My border is yellow.
	 *
	 */

	.card.is-focused{
		box-shadow: 0 0 10px rgba(gc(dl-black), .2);
	}

	.card.has-error{
		border: 1px solid gc(dl-ruby);
		box-shadow: 0 0 10px rgba(gc(dl-ruby), .4);
	}

	.card.has-border{
		border: 1px solid transparent;
	}

	.card--last{ //@TODO: document this
		margin-bottom: ms(6);

		@media #{$medium-up}{
			margin-bottom: $card-bottom-margin;
		}
	}

	/**
	 *
	 *Spacious cards:
	 *
	 *`.card.card--spacious`
	 *
	 *Need a bit more cushion? Use a spacious card.
	 *
	 * 	@example
	 *		div.card.card--spacious
	 *			h3.card__title I have some more breathing room
	 *			p I am a spacious card.
	 *
	 */

	.card--spacious{
		padding: ms(4) ms(3);

		@media #{$medium-up}{
			padding: ms(1) ms(2) ms(1);
		}
	}

	.card--h-super-spacious{ //@TODO: document this

		@media #{$medium-up}{
			padding-right: ms(7);
			padding-left: ms(7);
		}
	}

	.card--h-collapse{ //@TODO: document this
		padding-right: 0;
		padding-left: 0;
	}

	/**
	 *
	 *Cards containing images:
	 *
	 *`.card.card--image.card--image-right`
	 *
	 *Rarely used. A card that has a right-aligned image or svg
	 *
	 * 	@example
	 *		div.card.card--image.card--image-right
	 *			div.card__content
	 *				h3.card__title I am a card with a right-aligned image
	 *				p Check me out here is some content etc.
	 *			div.card__image
	 *				img(src='images/my_documents_bubble.png')
	 *
	 */

	.card--image-right{

		.card__content{
			flex: 1;
		}

		.card__image{
			display: none;
			flex: 0 auto;
			max-width: ms(12);

			img,
			svg{
				max-width: ms(12);
			}
		}

		@media #{$medium-up}{
			display: flex;

			.card__content{
				margin-right: ms(4);
			}

			.card__image{
				display: block;

				svg,
				img{
					margin-bottom: ms(4);
				}

				:last-child{
					margin-bottom: 0;
				}
			}
		}
	}

	/**
	 *
	 *Base cards:
	 *
	 *`.card.card--base`
	 *
	 *Need to wipe out all padding? Use a base card.
	 *
	 * 	@example
	 *		div.card.card--base
	 *			h3.card__title I have no breathing room
	 *			p I am a base card.
	 *
	 */

	.card--base{
		padding: 0;
	}

	/**
	 *
	 *Action cards:
	 *
	 *`.card.card--action`
	 *
	 *Action cards have actions stacked on the right that move down below when the
	 * viewport is reduced.
	 *
	 *Of course, if you want the buttons along the bottom, you can always use a normal card with an `.actions`
	 *component inside it
	 *
	 * 	@example
	 *		div.card.card--action
	 *			div.card__content
	 *				h3.card__title A card at its most simple useful form
	 *				p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum totam est, veritatis cumque, officia corrupti aut labore provident dolores doloribus eligendi. Dolorum ad veritatis, optio dolorem consectetur magni sunt. Porro!
	 *			div.actions.actions--stacked
	 *				div.button.button--primary Yes!
	 *				div.textbutton No, thanks for asking
	 */


	.card--action{
		@media #{$medium-up}{
			display: flex;
		}

		.card__content{
			@media #{$medium-up}{
				flex: 2;
				padding-right: ms(4);
			}
		}

		>.actions--stacked{
			@media #{$medium-up}{
				flex: 1;
				min-width: ms(10);
				margin: ms(6) 0 0; //push actions down by an <h3>'s height
			}
		}
	}

	/**
	 *
	 *Notitle card:
	 *
	 *`.card--notitle`
	 *
	 *In some cases, having a title in the card affects tha layout of other things, like the actions in `card--actions`.
	 * Setting the `notitle` modifier lets the card know not to make room for titles.
	 *
	 * 	@example
 	 *		div.card.card--action.card--notitle
	 *			div.card__content
	 *				p this card also has the --notitle modifier. This will pull the actions back up to be in-line with the top of the card content
	 *			div.actions.actions--stacked
	 *				div.button.button--primary Yes!
	 *				div.textbutton No, thanks for asking
	 *
	 */


	.card--notitle{
		padding-top: ms(3);

		&.card--icon .card__icon{
			margin-top: 0;
		}

		&.card--action .actions--stacked{

			@media #{$medium-up}{
				margin-top: 0;
			}
		}
	}


	/**
	 *
	 *Icon cards:
	 *
	 *`.card.card--icon`
	 *
	 *Icon cards have an icon when there is enough room.
	 *Use them sparingly to attract attention to the main content.
	 *Generally, icon cards should not be blended with action cards. Too busy!
	 *Instead, include a standard `actions` component in the card.
	 *
	 * 	@example
	 *		div.card.card--icon
	 *			div.card__icon
	 *				svg.icon
	 *				 	use(xlink:href='images/sprites.svg#icon-draft--color')
	 *			div.card__content
	 *				h3.card__title This is a draft document
	 *				p Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum totam est, veritatis cumque, officia corrupti aut labore provident dolores doloribus eligendi. Dolorum ad veritatis, optio dolorem consectetur magni sunt. Porro!
	 *
	 *		div.card.card--icon
	 *			div.card__icon
	 *				svg.icon
	 *				 	use(xlink:href='images/sprites.svg#icon-folder--color')
	 *			div.card__content
	 *				h3.card__title This is a draft document and actions
	 *				p icon cards and action cards shouldn't be blended, just do actions the normal way Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum totam est, veritatis cumque, officia corrupti aut labore provident dolores doloribus eligendi. Dolorum ad veritatis, optio dolorem consectetur magni sunt. Porro!
	 *				div.actions
	 *					div.button.button--primary Do it! Lets go
	 *					div.textbutton No! I changed my mind
	 *		div.card.card--icon.card--notitle
	 *			div.card__icon
	 *				svg.icon
	 *				 	use(xlink:href='images/sprites.svg#icon-draft--color')
	 *			div.card__content
	 *				p this `notitle` card has slightly different icon placement
	 */

	.card--icon{

		@media #{$medium-up}{
			display: flex;
		}

		.card__icon{
			display: none;

			@media #{$medium-up}{
				display: flex;
				width: map-get($icon-size, large);
				height: map-get($icon-size, large);
				margin: ms(0) 0 0;
			}
		}

		.card__content{
			flex: 1;
			padding-left: 0;

			@media #{$medium-up}{
				margin-top: ms(0);
				padding-left: ms(2);
			}
		}
	}


	/**
	 *
	 *Icon/action cards:
	 *
	 *`.card--icon.card--action`
	 *
	 *Danger zone! only do this if you are very sure of the content that's going in it
	 * and that the layout can cope. A `.card--action-spacious` modifier keeps the actions
	 * below the rest of the content on medium screens as well as small, so it's a little
	 * safer.
	 *
	 * 	@example
	 *		div.card.card--icon.card--action
	 *			div.card__icon
	 *				svg.icon
	 *				 	use(xlink:href='images/sprites.svg#icon-folder--color')
	 *			div.card__content
	 *				h3.card__title This is a draft document and actions
	 *				p icon cards and action cards shouldn't be blended, just do actions the normal way Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum totam est, veritatis cumque, officia corrupti aut labore provident dolores doloribus eligendi. Dolorum ad veritatis, optio dolorem consectetur magni sunt. Porro!
	 *			div.actions.actions--stacked
	 *				div.button.button--primary Do it! Let's go
	 *				div.textbutton No! I changed my mind
	 *		div.card.card--spacious.card--icon.card--action.card--action-spacious
	 *			div.card__icon
	 *				svg.icon
	 *				 	use(xlink:href='images/sprites.svg#icon-folder--color')
	 *			div.card__content
	 *				h3.card__title This card has the card--action-spacious modifier
	 *				p icon cards and action cards shouldn't be blended, just do actions the normal way Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum totam est, veritatis cumque, officia corrupti aut labore provident dolores doloribus eligendi. Dolorum ad veritatis, optio dolorem consectetur magni sunt. Porro!
	 *			div.actions.actions--stacked
	 *				div.button.button--primary Do it! Let's go
	 *				div.textbutton No! I changed my mind
	 */

	.card--icon.card--action{
		flex-direction: row;
		flex-wrap: wrap;

		.card__content{
			flex: 2; // 2 thirds for content
		}

		.actions{
			//Issue #2781 - turnign off for problem on iPhone 5 & 6
			// flex: 100%;
			display: block;

			@media #{$medium-up}{
				flex: 1; // 1 third for actions
			}
		}
	}

	.card--supreme{ //@TODO: document this
		padding: calc(#{$card-vert-padding} * 1.5) calc(#{$card-horz-padding} * 1.5);

		.card__icon{
			flex: 0 auto;
			max-width: ms(9);
			margin: ms(2) 0;

			@media #{$medium-up}{
				margin: 0;
			}
		}

		.card__content{
			flex: 1;

			@media #{$medium-up}{
				margin: 0 ms(4);
			}
		}

		.card__contentinner{
			max-width: ms(17);
		}

		.actions{
			display: block;
			flex: 0 auto;
		}

		@media #{$medium-up}{
			display: flex;
		}
	}

	.card--icon.card--action.card--spacious{

		.card__icon{
			flex: 0 auto;

			@media #{$large-up}{
				flex: 0 1 auto; // reset to default
			}
		}

		.card__content{
			flex: 1;

			@media #{$large-up}{
				flex: 2;
			}
		}

		.actions{
			flex: 100%;

			.button,
			.splitbutton,
			.textbutton{
				margin-right: auto;
				margin-left: auto;

				@media #{$medium-only}{
					max-width: ms(15);
					margin-left: calc(#{map-get($icon-size, large) + ms(2) + $slat-horz-padding}); // large icon width + .card--action .card__content left padding + slat horizontal padding
				}
			}

			@media #{$large-up}{
				flex: 1;
			}
		}
	}

	// Little sugar for if a card contains only an actions component
	// Then remove all the margins

	.card > .actions:only-child{
		margin-top: 0;
		margin-bottom: 0;
	}

	/**
	 *
	 *Collapsed cards:
	 *
	 *`.card.card--collapse-top`, `.card.card--collapse-bottom`
	 *
	 *Eliminates border radius and margin from the collapsed edge so two cards can fit together, looking like one.
	 * Used for Upload & Sign, where bottom half of card anchors to top of container after scrolling down; and for
	 * titlebar--cards, where the top and bottom are composed of two seamless cards.
	 *
	 * 	@example
	 *		div.card.card--collapse-bottom
	 *			div.card__content
	 *				h3.card__title Hi!
	 *				p I am a card with a collapsed bottom edge.
	 *		div.card.card--collapse-top
 	 *			div.card__content
 	 *				h3.card__title Hi!
 	 *				p I am a card with a collapsed top edge.
	 */

	.card--collapse-top{
		margin-top: 0;
		padding-top: ms(0);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.card--collapse-bottom{
		margin-bottom: 0;
		padding-bottom: ms(0);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	// /**
	//  *
	//  *Optionbar cards:
	//  *
	//  *`.card.card--optionbar` > `.card__optionbar` > `.card__option`, `.card__option.card__option--primary` > `.card__optiontitle`, `.card__optionbutton`
	//  *
	//  *Used in My Documents, optionbar cards have a small toolbar at top that flexes to match widths of a `.slat--optionbar` contained below, within the card.
	//  * A `.card__option--primary` is twice as wide, and should only be used once, paired with two other `.card__option`s. Note that this is kind of a
	//  * prototype of a super specialized component, hence its total inflexibility.
	//  *
	//  * 	@example
	//  *		div.card.card--optionbar
	//  *			div.card__optionbar
	//  *				div.card__option.card__option--primary
	//  *					div.card__optiontitle
	//  *						span Option one!
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--asc')
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--desc')
	//  *				div.card__option.card__option--dropdown(data-dropdown='card-option-dropdown')
	//  *					div.card__optiontitle
	//  *						span Option dropdown!
	//  *					div.card__optionbutton
	//  *						div.card__optionicon
	//  *							svg.icon.icon--micro
	//  *								use(xlink:href='#icon-chevrondown--menu')
	//  *					ul.dropdown.dropdown--cardoption.dropdown--nopip#card-option-dropdown(data-dropdown-content)
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Draft')
	//  *							span Draft
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Finished')
	//  *							span Finished
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *							span Signing
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Signed')
	//  *							span Signed
	//  *				div.card__option
	//  *					div.card__optiontitle
	//  *						span Option three!
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--asc')
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--desc')
	//  *				div.card__option.card__option--small.card__option--dropdown(data-dropdown='card-option-dropdown-small')
	//  *					div.card__optiontitle
	//  *						span Option small!
	//  *					div.card__optionbutton
	//  *						div.card__optionicon
	//  *							svg.icon.icon--micro
	//  *								use(xlink:href='#icon-chevrondown--menu')
	//  *					ul.dropdown.dropdown--cardoption.dropdown--nopip#card-option-dropdown-small(data-dropdown-content)
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Draft')
	//  *							span Draft
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Finished')
	//  *							span Finished
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *							span Signing
	//  *						li.dropdown__item.dropdown__item--icon
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Signed')
	//  *							span Signed
	//  *			div.card__content
	//  *				div.slat.slat--optionbar
	//  *					div.slat__icon
	//  *						svg.icon.icon--small
	//  *							use(xlink:href='images/sprites.svg#icon-document--color')
	//  *					div.slat__options
	//  *						div.slat__option.slat__option--primary
	//  *							span Option text!
	//  *						div.slat__option.u-color__dl-grey
	//  *							svg.icon.icon--small
	//  *								use(xlink:href='#icon-dragonlaw')
	//  *							div.slat__optiontext
	//  *								span Option text!
	//  *						div.slat__option
	//  *							svg.icon.icon--small
	//  *								use(xlink:href='images/sprites.svg#icon-folder--color')
	//  *							div.slat__optiontext
	//  *								span Option text!
	//  *					div.slat__tools
	//  *						svg.icon.icon--tiny.icon--hover
	//  *							use(xlink:href='images/sprites.svg#icon-dotmenu')
	//  */
	//
	// .card--optionbar{ // Card with a optionbar anchored to the top
	// 	padding: 0; // removed padding to allow optionbar to sit flush w/top and sides
	//
	// 	.card__content{
	// 		padding: ms(2) ms(3); // add regular card padding back into content portion
	// 	}
	// }
	//
	// .card__optionbar{
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	padding: 0;
	// 	border-top-left-radius: 2px;
	// 	border-top-right-radius: 2px;
	// 	background: gc(dl-slate);
	// 	color: gc(dl-lightgrey);
	// 	font-size: ms(0);
	//
	// 	@media #{$large-up}{
	// 		align-items: baseline;
	// 		padding-top: 0;
	// 		padding-right: calc(#{ms(1) + ms(1) + ms(0) + ms(3)}); // slat__tools left margin + slat__tools tiny icon width + slat padding + card__content padding
	// 		padding-bottom: 0;
	// 		padding-left: calc(#{ms(3) + ms(0) + ms(-3) + ms(0) + ms(2) + ms(1)}); // card__content + slat padding + slat__optionbar slat__icon min-width (slat__dragger width + slat__dragger right margin + small icon width) + slat__icon right margin
	// 	}
	// }
	//
	// .card__option{
	// 	display: none; // only show card__option--small modifier elements for small viewport size
	// 	flex: 1 auto;
	// 	padding: ms(0) 0;
	//
	// 	@media #{$large-up}{
	// 		display: flex;
	// 		width: 25%;
	// 	}
	// }
	//
	// .card__option--small{
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	padding: ms(0) ms(3);
	//
	// 	@media #{$large-up}{
	// 		display: none;
	// 	}
	// }
	//
	// .card__option--primary{
	//
	// 	@media #{$large-up}{
	// 		width: 50%;
	// 	}
	// }
	//
	// .card__option--dropdown{
	// 	position: relative;
	// 	cursor: pointer;
	//
	// 	.card__optiontitle{
	// 		pointer-events: none;
	// 	}
	//
	// 	.card__optionbutton{
	// 		pointer-events: none;
	// 	}
	//
	// 	.card__optionicon{
	// 		display: inline-block;
	// 		vertical-align: middle;
	// 		pointer-events: all;
	// 	}
	//
	// 	@media #{$large-up}{
	// 		left: -#{ms(-2)};
	// 		padding-left: ms(-2);
	// 	}
	// }
	//
	// .card__option--dropdown[aria-expanded='true']{
	// 	background: gc(dl-grey);
	// 	color: gc(dl-charcoal);
	//
	// 	.card__optionicon{
	// 		transform: rotate(180deg);
	// 		vertical-align: text-bottom;
	// 	}
	// }
	//
	// .card__optiontitle{
	// 	margin-right: ms(-2);
	// }
	//
	// .card__optionbutton{
	// 	display: flex;
	// 	justify-content: center;
	// 	width: ms(0);
	// 	margin-right: ms(-9);
	// 	cursor: pointer;
	//
	// 	svg{
	// 		pointer-events: none;
	// 	}
	//
	// 	&:last-of-type{
	// 		margin-right: 0;
	// 	}
	// }

	/*
	* Card container
	* Flexbox: Card Layouts
	*/

	.card-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;

		.card {
			@include card-column(1);
		}
	}

	.card-container--col-2{
		@extend .card-container;

		@media #{$medium-up}{
			.card {
				@include card-column(2);
			}
		}
	}

	.card-container--col-3{
		@extend .card-container--col-2;

		@media #{$large-up}{
			.card {
				@include card-column(3);
			}
		}
	}

	.card-container--col-4{
		@extend .card-container--col-2;

		@media #{$large-up}{
			.card {
				@include card-column(4);
			}
		}
	}
}
