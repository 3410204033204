@include exports('base/grid'){
	.row{
		width: $max-content-width;
		max-width: 100%;
	}

	.main-content .row,
	.main-content .row.restricted{
		width: $max-left-content-width;
		margin: 0;
	}

	.row.row--outer{
		padding: 0 $column-gutter;

		@media #{$xlarge-up}{
			padding: 0;
		}
	}

	.row.restricted{
		width: $max-content-width;
		max-width: 100%;
		// margin-left: 0;
	}

}
