@include exports('modules/sidebarleft'){
	// Size and position of sidebar container
	// make it small on small screens basically
	// body.has-sidebar-left{}

	.sidebarleft{
		display: none; // in 2.0 mode, hide left sidebar
		position: fixed;
		z-index: 4; // needs to sit on top of mobile tabset, which needs to sit on top of plupload stuff
		top: $topbar-height;
		bottom: 0;
		width: map-get($glb-sidebar-width, compact);
		padding: ms(1);
		overflow: auto;
		transform: translateX(-#{map-get($glb-sidebar-width, compact)});
		transition: all #{map-get($transition-duration, default)} $ease-in-out-quad;
					// box-shadow #{map-get($transition-duration, default)} $ease-in-out-quad;
		border-right: 1px solid darken(gc(dl-grey), 2%);
		background: gc(dl-cream);
		// z-index: 1;

		&::-webkit-scrollbar{
			width: ms(-3);
		}

		&::-webkit-scrollbar-thumb{
			background: gc(dl-grey);
		}

		@media #{$medium-up} {
			transform: translateX(0);
		}

		@media #{$large-up} {
			width: map-get($glb-sidebar-width, full);
			padding: ms(0) ms(2);
			background: gc(dl-cream);
		}
	}

	body.has-sidebar-left{ // in 1.0 mode, show left sidebar

		.sidebarleft{
			display: block;
		}
	}

	// if we are hovering on small screens or if someone toggles
	body.has-expanded-left-sidebar .sidebarleft,
	.no-touch .sidebarleft:hover{
		width: map-get($glb-sidebar-width, full);
		transform: translateX(0); // if we grab the sidebar with the mouse as it is closing, show it
		box-shadow: 0 0 ms(1) rgba(0, 0, 0, .2);

		.sidebarleft__username{
			display: block;
		}

		@media #{$medium-up} {
			transition-delay: .3s; // Delay a second to avaoid annoying accidental hovers, but not on small screens if we have grabbed the sidebar
		}

		@media #{$large-up} {
			box-shadow: none;
		}
	}

	body.has-expanded-left-sidebar .sidebarleft{
		transform: translateX(0);
		transition-delay: 0s;

		.sidebarleft__username{
			display: block;
		}
	}

	// Nag Box below the menu


	.sidebarleft__nag{
		display: none;
		margin: ms(3) 0;
		padding-top: ms(1);
		border-top: 1px solid gc(dl-grey);

		@media #{$large-up} {
			display: block;
		}
	}

	// User name box below menu

	.sidebarleft__username{
		display: none;
		margin-top: ms(3);
		padding: ms(5) ms(-2) 0; // Little extra padding for small and medium screens...
		overflow: hidden;
		border-top: 1px solid gc(dl-grey);
		color: gc(dl-basalt);
		font-size: ms(0);
		font-style: italic;
		text-overflow: ellipsis;
		white-space: nowrap;

		@media #{$large-up}{
			display: block;
			padding: ms(4) ms(-2) 0; // ... removed for large screens.
		}
	}
}
