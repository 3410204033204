@include exports('components/poppers'){

	.popper__wrapper{
		margin: 16px 0 0;
	}

	.popper__wrapper[data-placement='top']{
		margin: 0 0 16px;
	}

	.popper__wrapper[data-placement='right']{
		margin: 0 0 0 16px;
	}

	.popper__wrapper[data-placement='left']{
		margin: 0 16px 0 0;
	}

	.popper{
		position: relative;
		width: calc(100vw - 2 * (#{$column-gutter}));
		max-width: 100%;
		max-height: 100%;
		background: transparent;
		pointer-events: all;

		@media #{$medium-up}{
			width: 100%;
			min-width: 400px;
		}
	}

	.popper--noarrow{

		.popper__arrow{
			display: none;
		}
	}

	.popper__arrow{
		content: '';
		position: absolute;
		z-index: 10;
		width: 16px;
		height: 16px;
		margin-top: -8px;
		transform: rotate(-225deg);
		border: solid gc(dl-iron);
		border-width: 0 0 1px 1px;
		border-radius: 0 0 0 2px;
		background: gc(dl-white);
		vertical-align: middle;

		.popper__wrapper[data-placement='top'] &{
			top: 100%;
			transform: rotate(-45deg);
		}

		.popper__wrapper[data-placement='right'] &{
			right: 100%;
			left: 0;
			margin-top: 0;
			margin-left: -8px;
			transform: rotate(45deg);
		}

		.popper__wrapper[data-placement='left'] &{
			right: 0;
			left: 100%;
			margin-top: 0;
			margin-left: -8px;
			transform: rotate(-135deg);
		}
	}

	.popper__inner{
		padding: $slat-vert-padding $slat-horz-padding;
		border: 1px solid gc(dl-iron);
		border-radius: map-get($corner-radius, small);
		background: gc(dl-white);
		box-shadow: 0 4px 8px gc(dl-lightgrey);
		text-align: center;
	}

	.popper__inner--list{
		padding: 0;
		background: gc(dl-ash);
		text-align: left;
	}

	.popper__header{
		display: flex;
		padding: $slat-vert-padding $slat-horz-padding;
		border-bottom: 1px solid gc(dl-slate);
		background: gc(dl-white);
		color: gc(dl-basalt);
		font-size: ms(0);
	}

	.popper__headertext{
		flex: 1;
	}

	.popper_headeractions{
		flex: 0 auto;
	}

	.popper__content{
		height: 300px;
		max-height: 100%;
		overflow-y: scroll;

		&::-webkit-scrollbar{
			width: ms(-3);
		}

		&::-webkit-scrollbar-thumb{
			background: gc(dl-grey);
		}
	}

	.popper__list{
		width: 100%;
		height: 100%;
		border-right: 1px solid gc(dl-slate);
	}

	.popper__listitem{
		border-bottom: 1px solid gc(dl-slate);
		background: gc(dl-white);

		&:last-of-type{
			border-bottom: 0;
		}
	}

	.popper__listdate{
		width: ms(8);
		max-width: 100%;
		color: gc(dl-basalt);
		text-align: right;

		@media #{medium-up}{
			width: ms(11);
		}
	}

	.popper__listitem.is-read{
		background: gc(dl-ash);
	}
}
