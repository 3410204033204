@include exports('components/plans'){
	/***
	 * Plans:
	 */

	/**
	 *
	 *Styles for the subscription plan features list. .plan--selected class added to plan with checked input via javascript, making text black.
	 *
	 * 	@example
	 *		div.card
	 *			div.row
	 *				div.small-12.medium-6.columns
	 *					div.plan
	 *						div.slat.slat--h-collapse
	 *							div.slat__icon
	 *								input.plan__input(type='radio', name='planradio', id='plan1')
	 *							div.slat__content
	 *								label.plan__header(for='plan1')
	 *									div.u-text__bold Plan 1
	 *									div Some dollars
	 *								div.plan__features
	 *									div Feature 1
	 *									div Feature 2
	 *									div Feature 3
	 *									div Feature 4
	 *									div Feature 5
	 *				div.small-12.medium-6.columns
	 *					div.plan
	 *						div.slat.slat--h-collapse
	 *							div.slat__icon
	 *								input.plan__input(type='radio', name='planradio', id='plan2')
	 *							div.slat__content
	 *								label.plan__header(for='plan2')
	 *									div.u-text__bold Plan 2
	 *									div Some dollars
	 *								div.plan__features
	 *									div Feature 1
	 *									div Feature 2
	 *									div Feature 3
	 *									div Feature 4
	 *									div Feature 5
	 *
	 */



	.plan{
		transition: color map-get($transition-duration, fast) $ease-in-out-quad;
		color: gc(dl-basalt);

		label.plan__header{
			transition: color map-get($transition-duration, fast) $ease-in-out-quad;
			border-bottom: 1px solid currentcolor;
			color: gc(dl-basalt);
		}
	}

	// .plan__input[type='radio'],
	// .plan__input[type='checkbox']{
	// 	margin-left: 1px; // input is in a container with overflow:hidden, and clips off 1px on some browsers
	// }

	.plan--selected,
	.plan--checkbox{
		color: gc(dl-black);

		label.plan__header{
			border-bottom: 1px solid currentcolor;
			color: gc(dl-black);
		}
	}
}
