@include exports('utilities/text'){
	.u-text__familymono{
		font-family: $family-mono;
	}

	.u-text__break{
		overflow: visible;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
	}

	.u-text__linethrough{ // @TODO: document this
		overflow: hidden;
		text-align: center;

		&::before,
		&::after {
			content: '';
			display: inline-block;
			position: relative;
			width: 50%;
			height: 1px;
			background-color: currentColor;
			vertical-align: middle;
		}

		&::before {
			right: 1rem;
			margin-left: -50%;
		}

		&::after {
			left: 1rem;
			margin-right: -50%;
		}
	}

	.u-text__small{
		font-size: ms(0);
	}

	.u-text__large{
		font-size: ms(2);
	}

	.u-text__jumbo{
		font-size: ms(4);
	}

	.u-text__light{
		color: gc(dl-basalt);
	}

	.u-text__bold{
		// scss-lint:disable ImportantRule
		font-weight: bold !important;
	}

	.u-text__italic{
		font-style: italic;
	}

	.u-text__normal{
		// scss-lint:disable ImportantRule
		font-weight: normal !important;
	}

	.u-text__quiet{
		// @extend .u-text__light;
		color: gc(dl-teal);
		@extend .u-text__small;
	}

	.u-text__warning{
		// scss-lint:disable ImportantRule
		color: gc(dl-ruby) !important;
	}


	.u-text__link{
		color: $anchor-font-color;
		text-decoration: $anchor-text-decoration;
		cursor: pointer;

		&:hover{
			color: $anchor-font-color-hover;
			text-decoration: $anchor-text-decoration-hover;
		}
	}

	.u-text__uppercase{
		text-transform: uppercase;
	}

	.u-text__underline{
		text-decoration: underline;
	}

	.u-text__center{
		text-align: center;
	}

	.u-text__center--large-up{

		@media #{$large-up}{
			text-align: center;
		}
	}

	.u-text__right{
		text-align: right;
	}

	.u-text__right--medium-up{

		@media #{$medium-up}{
			text-align: right;
		}
	}

	.u-text__right--large-up{

		@media #{$large-up}{
			text-align: right;
		}
	}

	.u-text__left{
		text-align: left;
	}

	.u-text__left--large-up{

		@media #{$large-up}{
			text-align: left;
		}
	}

	.u-text__info{
		// for now these are the same, but may not always be
		@extend .u-text__help;
	}

	.u-text__help{
		color: gc(dl-basalt);
		font-size: ms(0);
		font-style: italic;
	}

	.u-text__reset{
		color: inherit;
		text-decoration: none;
		text-transform: none;

		&:hover,
		&:focus,
		&:active{
			color: inherit;
			text-decoration: none;
			text-transform: none;
		}
	}
}
