@include exports('utilities/lists'){
	/**
	 *
	 *List styles:
	 *
	 *`.u-list__none` removes bullet points from a `<ul>` or `<li>`
	 *
	 */

	.u-list__none{
		list-style: none;
	}

	.u-list__square{
		list-style-type: square;
	}

	.u-list__inside{
		list-style-position: inside;
	}
}
