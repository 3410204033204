// Layout is concerned with the skeleton of the site.
// IE. The  main regions: content, sidebarleft & sidebarright
// Sidebars are included as part of this module rather than as their own
// because the position of all three is so closely linked.

@include exports('modules/layout'){
	.layout{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		height: 100%;
		background: $glb-layout-color;
	}



	.layout__content{
		position: absolute;
		right: 0;
		left: 0;
		padding: 0;
		transition: all #{map-get($transition-duration, default)} $ease-in-out-quad;
	}

	.layout__content--maintenancepage{
		left: ms(1);
	}





	//
	// Sidebar Left
	//


	// If we have a left sidebar, which we almost always do,
	// push the content over to make room
	.layout__content{
		top: $topnav-height;
		// width: $max-content-width;
		max-width: 100%;
		margin: 0 auto;

		body.has-sidebar-left &{
			top: $topbar-height;
			width: auto;

			@media #{$medium-up} {
				left: map-get($glb-sidebar-width, compact);
			}

			@media #{$large-up} {
				left: map-get($glb-sidebar-width, full);
			}
		}
	}





	//
	// Sidebar Right
	//



	.layout__content{
		.has-sidebar-right &{
			width: auto; // give the sidebar ability to push over the layout__content
			// right: map-get($glb-sidebar-width, tiny) + ms(1); //turned off for removal of right sidebar on small screens.

			@media #{$medium-up} {
				right: map-get($glb-sidebar-width, full);
			}

			@media #{$large-up} {
				right: map-get($glb-sidebar-width, jumbo);
			}
		}
	}

}
