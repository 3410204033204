@include exports('components/checkradios') {
	/***
	*Checkboxes and Radio Buttons:
	*/

	/**
	 *
	 *Checkbox and radio checkradios:
	 *
	 *`.checkradioinput + .checkradio--radio`, `.checkradioinput + .checkradio--checkbox`
	 *
	 *Checkboxes and radio buttons use the [checkbox hack](http://codepen.io/grgcnnr/pen/wBRddx)
	 *to visually hide native radio and checkbox inputs.
	 *Give the input a class of `.checkradio__input` to hide it and put it *directly before the checkradio it affects.
	 *Wrap your checkradio inputs and labels in a `.checkradio__inlineblockgroup` div to display them side by side.
	 *
	 * 	@example
	 *		div.card
	 *			input#checkradiocheckbox1.checkradio__input(type='checkbox')
	 *			label.checkradio.checkradio--checkbox(for='checkradiocheckbox1')
	 *				|I am a checkbox checkradio
 	 *			input#checkradiocheckbox2.checkradio__input(type='checkbox' checked)
 	 *			label.checkradio.checkradio--checkbox(for='checkradiocheckbox2')
 	 *				|I am a checkbox checkradio
	 *			input#checkradiocheckbox3.checkradio__input(type='checkbox')
	 *			label.checkradio.checkradio--checkbox(for='checkradiocheckbox3')
	 *				|I am a checkbox checkradio
	 *
 	 *		div.card
	 *			input#checkradioradio1.checkradio__input(type='radio' name='obradiodemo' checked)
	 *			label.checkradio.checkradio--radio(for='checkradioradio1')
	 *				|I am a radio checkradio
 	 *			input#checkradioradio2.checkradio__input(type='radio' name='obradiodemo')
 	 *			label.checkradio.checkradio--radio(for='checkradioradio2')
 	 *				|I am a radio checkradio
	 *			input#checkradioradio3.checkradio__input(type='radio' name='obradiodemo')
	 *			label.checkradio.checkradio--radio(for='checkradioradio3')
	 *				|I am a radio checkradio
	 *
	 *		div.card
	 *			div.checkradio__inlineblockgroup
	 *				input#checkradioradio2-1.checkradio__input(type='radio' name='obradiodemo2' checked)
	 *				label.checkradio.checkradio--radio(for='checkradioradio2-1')
	 *					|I am an inline-block radio checkradio
 	 *				input#checkradioradio2-2.checkradio__input(type='radio' name='obradiodemo2')
 	 *				label.checkradio.checkradio--radio(for='checkradioradio2-2')
 	 *					|Me too
	 *				input#checkradioradio2-3.checkradio__input(type='radio' name='obradiodemo2')
	 *				label.checkradio.checkradio--radio(for='checkradioradio2-3')
	 *					|Hi!
	 *
	 */

	.checkradio{
		display: block;
		position: relative;
		margin: 0 0 ms(0);
		padding: 0;
		transition: all map-get($transition-duration, fast) $ease-in-out-quad;
		color: gc(dl-black);
		font-size: ms(1);
		cursor: pointer;
	}

	.checkradio:hover{
		color: gc(dl-black);
	}

	//specificity hack to avoid using important when the checkradio is a check/radio
	label.checkradio.checkradio{
		display: block;
	}

	// label.checkradio.checkradio--inlineblock{
	// 	display: inline-block;
	// }

	// checkradioinput just hides the default input element
	.checkradio__input{
		visibility: hidden;
		position: absolute;
	}


	// all checkradios have the same highlighted state when checked
	.checkradio__input:checked + .checkradio{
		color: gc(dl-black);
	}


	// foundation does some useful stuff when you put labels next to
	// inputs but we dont want it here

	input[type="radio"] + .checkradio--radio,
	input[type="checkbox"] + .checkradio--checkbox{
		margin-right: 0;
		margin-left: 0;
	}

	// input[type="radio"] + .checkradio--radio.checkradio--inlineblock,
	// input[type="checkbox"] + .checkradio--checkbox.checkradio--inlineblock{
	// 	margin-right: ms(2);
	//
	// 	&:last-of-type{
	// 		margin-right: 0;
	// 	}
	// }

	.checkradio__inlineblockgroup{

		div, // to target react wrapper divs
		label.checkradio.checkradio{
			display: inline-block;
		}

		div,
		input[type="radio"] + .checkradio--radio,
		input[type="checkbox"] + .checkradio--checkbox{
			margin-right: ms(2);

			&:last-of-type{
				margin-right: 0;
			}
		}
	}

	//
	// Radio mode
	//

	.checkradio--radio{
		padding-left: calc(#{ms(3) + ms(-5)});
		// background: gc(dl-cream);

		&::before{
			@include ui-radiobutton;
			content: '';
			position: absolute;
			left: 0;
			width: ms(1);
			height: ms(1);
			transition: all map-get($transition-duration , fast) $ease-in-out-quad;
		}

		// &:hover::before{
		// 	@include ui-radiobutton-hover;
		// }
	}

	.checkradio__input:checked + .checkradio--radio::before{
		@include ui-radiobutton-checked;
	}

	//
	//  Checkbox mode
	//

	.checkradio--checkbox{
		padding-left: calc(#{ms(3) + ms(-5)});

		&::before,
		&::after{
			@include ui-checkbox;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	// .checkradio--checkbox:hover{
	//
	// 	&::before,
	// 	&::after{
	// 		@include ui-checkbox-hover;
	// 	}
	// }

	.checkradio__input:checked + .checkradio--checkbox::before{
		@include ui-checkbox-checked-filled-box;
	}

	.checkradio__input:checked + .checkradio--checkbox::after{
		@include ui-checkbox-checked-filled-check;
	}
} //exports
