@include exports('components/uploadsigners'){
	/***
	 * Uploadsigners:
	 */

	/**
	 *
	 *Basic uploadsigner:
	 *
	 *`.uploadsigner__parties-container` > `.uploadsigner__parties--top` > `.uploadsigner__partyselect`, `.uploadsigner__partybuttons`,
	 *`.uploadsigner__parties--bottom` > `.actions`
	 *
	 *Upload and Sign signing parties are organized inside a slat. The Upload and Sign card
	 * is split off as a separate component mainly because it is a crowded UI with
	 * a lot going on. Creating a custom all-in-one (plus some use of slats and form elements)
	 * makes the whole thing easier to maintain. See the markup for usage.
	 *The card is comprised of a collapsible top half that disappears when the user scrolls down,
	 * and a bottom half broken into two parts: the buttons for signer options and placement, and
	 * actions to return to the document builder or proceed after placement is complete.
	 * After scrolling, the uploadsigner__parties-container merges with the topnav (v2+) or topbar (v1) and the party
	 * selector becomes more compact. This occurs so that the document is visible, with the signing options fixed to
	 * the top.
	 *
	 * 	@example
	 *		div.card.card--collapse-bottom
	 *			div.card__content
	 *				h3.card__title Upload and sign card
	 *				div.panel Here's some advice in a panel about what to do next
	 *		div.uploadsigner__parties-container
	 *			div.uploadsigner__parties.uploadsigner__parties--top
	 *				div.uploadsigner__partyselect
	 *					select.form__element
	 *						option Select a signer
	 *						optgroup Name Name
	 *							option Name Name
	 *				div.uploadsigner__partybuttons
	 *					div.uploadsigner__partybuttons-inner
	 *						div.uploadsigner__party-container
	 *							div.uploadsigner__header On behalf of A Company
	 *							div.uploadsigner
	 *								div.uploadsigner__party
	 *									div.slat.slat--base
	 *										div.slat__icon
	 *											div.texticon.u-colorbg__dl-canary JM
	 *										div.slat__content
	 *											div.slat__title Juanita Matthews
	 *											div.slat__text a lawyer
	 *								label
	 *									input(type='checkbox')
	 *									span.u-box__ml1 Display role
	 *								label
	 *									input(type='checkbox')
	 *									span.u-box__ml1 Display business name
	 *							div.uploadsigner__input-container
	 *								div.uploadsigner__input
	 *									div.uploadsigner__input-inner
	 *										div.uploadsigner__content
	 *											div.uploadsigner__icon
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-user--mono')
	 *											div.uploadsigner__text Place Juanita's name
	 *									div.uploadsigner__actions
	 *										div.textbutton.textbutton--small.u-box__m0.u-box__pl0.u-box__mr1 Go to placed name
	 *										div.textbutton.textbutton--small.u-box__m0.u-box__pl0 Delete placed name
	 *
	 *								div.uploadsigner__input
	 *									div.uploadsigner__input-inner
	 *										div.uploadsigner__content
	 *											div.uploadsigner__icon
	 *												svg.icon.icon--tiny
	 *													use(xlink:href='images/sprites.svg#icon-pen')
	 *											div.uploadsigner__text Place Juanita's signature
	 *									div.uploadsigner__actions
	 *										div.textbutton.textbutton--small.u-box__m0.u-box__pl0.u-box__mr1 Go to placed signature
	 *										div.textbutton.textbutton--small.u-box__m0.u-box__pl0 Delete placed signature
	 *			div.uploadsigner__parties.uploadsigner__parties--bottom
	 *				div.actions.actions--compact
	 *					div.actions__forward
	 *						div.button.button--primary Continue
	 *					div.actions__back
	 *						div.textbutton Back to overview
	 *
	 */

	.uploadsigner__parties-container{ //bottom half of card that contains party select - mimics a .card.card--collapse-top
		width: 100%;
		margin-top: 0;
		margin-bottom: ms(1);
		padding: ms(0) ms(3) ms(2);
		transition: background-color  map-get($transition-duration, fast) $ease-in-out-quad;
		border-radius: map-get($corner-radius, small);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: gc(dl-white);
		box-shadow: 1px 1px 2px rgba(gc(dl-black), .1);

		.is-fixed &{ // when div is fixed to top after scroll
			position: fixed;
			z-index: 3;
			top: $topnav-height;
			left: 0;
			max-width: 100%;
			padding: ms(0);
			border-radius: 0;
			background-color: $topnav-bgcolor;
			box-shadow: 0 4px 6px rgba(gc(dl-black), .3);

			@media #{$large-up}{
				padding: ms(0) 0;
			}

			.has-sidebar-left &{ // adjust position and color for v1.0
				top: $topbar-height;
				background-color: $topbar-bgcolor;

				@media #{$medium-up} {
					left: map-get($glb-sidebar-width, compact);
					width: calc(100% - #{map-get($glb-sidebar-width, compact)});
				}

				@media #{$large-up} {
					left: map-get($glb-sidebar-width, full);
					width: calc(100% - #{map-get($glb-sidebar-width, full)});
				}
			}
		}
	}

	.uploadsigner__parties{ // content of bottom half of card that contains party select
		margin: 0 auto;
		padding: 0;

		.is-fixed &{ // when div is fixed to top after scroll

			@media #{$large-up}{
				width: $max-content-width;
				max-width: 100%;
				padding: ms(0) ms(3) 0;
			}

			.has-sidebar-left &{ // adjust size for v1.0
			//
			// 	@media #{$medium-up} {
			// 		width: calc(100% - #{map-get($glb-sidebar-width, compact)});
			// 	}
			//
				@media #{$large-up} {
					width: 100%;
				}
			}
		}
	}

	.uploadsigner__parties--top{

		.is-fixed &{

			@media #{$medium-up}{
				display: flex;
				align-items: center;
			}
		}
	}

	.uploadsigner__parties--bottom{

		.is-fixed &{ // when div is fixed to top after scroll
			padding: 0 ms(0);

			@media #{$medium-up}{
				padding: 0 ms(4) 0 ms(1);
			}

			@media #{$large-up}{
				width: $max-content-width;
				padding: 0 ms(7) 0 ms(3);
			}

			.has-side-bar-left &{ // adjust padding for v1.0

				@media #{$medium-up}{
					padding: 0;
				}

				@media #{$large-up}{
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	.uploadsigner__party-container{ // holds select and party
		padding: 0;
		border-radius: 0;

		.is-fixed &{
			padding: 0;
			border-radius: 2px;

			// @media #{$large-up}{
				// padding: ms(0) ms(0) ms(1);
				// background: gc(dl-white);
			// }
		}
	}

	.uploadsigner__party{
		margin: 0 0 ms(0);
	}

	.uploadsigner__partyselect{
		padding: 0 ms(0);

		.is-fixed &{

			@media #{$medium-up}{
				flex: 0 auto;

				select.form__element{
					margin-bottom: 0;
				}
			}
		}
	}

	.uploadsigner__partybuttons{ // mimics a slat slat--base
		@include clearfix;
		display: flex;
		align-items: center;
		padding: 0;

		.no-flexbox &{
			display: block;
		}

		.is-fixed &{

			@media #{$medium-up}{
				flex: 1;
			}
		}
	}

	.uploadsigner__partybuttons-inner{ // mimics slat__content
		display: inline-block;
		flex: 1;
		overflow: hidden;

		.no-flexbox &{
			display: block;
		}
	}

	.uploadsigner{ //Basic container that holds the party boxes
		display: block;
		padding: 0 ms(0);
		vertical-align: top;

		.is-fixed &{
			display: none;

			// @media #{$large-up}{
			// 	display: inline-block;
			// }
		}

		@media #{$large-up}{
			display: inline-block;
			margin-top: ms(1);
			margin-right: ms(2);
		}
	}

	.uploadsigner__input-container{
		display: block;
		margin-top: ms(0);
		margin-right: 0;
		padding: 0 ms(0);
		vertical-align: top;

		.is-fixed &{
			margin-top: 0;

			@media #{$medium-up}{
				width: 100%;
			}

			@media #{$large-up}{
				margin-right: 0;
			}
		}

		@media #{$large-up}{
			display: inline-block;
			margin-top: ms(1);
			padding-right: 0;
			// margin-right: ms(2);
		}

		@media #{$xlarge-up}{
			display: block;
			float: right;
		}
	}

	.uploadsigner__header{
		margin: ms(1) 0 ms(0);
		color: gc(dl-charcoal);

		.is-fixed &{
			display: none;

			// @media #{$large-up}{
			// 	display: block;
			// }
		}

		@media #{$large-up}{
			margin: ms(1) 0 0;
		}
	}

	.uploadsigner__input{
		display: block;
		margin: 0;

		&:first-of-type{
			// margin-right: ms(0);
			margin-bottom: ms(0);
		}

		@media #{$medium-up}{
			display: inline-block;
			min-width: ms(11);

			&:first-of-type{
				margin-right: ms(0);
				margin-bottom: 0;
			}

			&:last-of-type{
				margin-right: 0;
			}

			.is-fixed &{
				display: inline-block;
				width: 48%;
				margin: 0;

				&:first-of-type{
					// margin-right: ms(0);
					margin-right: ms(0);
					margin-bottom: 0;
				}
			}
		}

		@media #{$large-up}{
			margin: 0 ms(0) 0 0;

			&:last-of-type{
				margin-right: 0;
			}
		}
	}

	.uploadsigner__input-inner{
		display: block;
		width: 100%;
		// margin: ms(0) 0;
		padding: ms(0);
		transition: border map-get($transition-duration, fast), background map-get($transition-duration, fast) $ease-in-out-quad;
		border: 1px solid gc(dl-grey);
		border-radius: $input-border-radius;
		background: gc(dl-cream);
		color: gc(dl-charcoal);
		vertical-align: middle;
		cursor: pointer;

		&:hover {
			border: 1px solid gc(dl-sky);
			background: gc(dl-white);
			// color: gc(dl-black);
		}

		// @media #{$medium-up}{
		// 	display: inline-block;
			// margin-right: ms(0);

			// &:last-of-type{
			// 	margin-right: 0;
			// }
		// }

		// @media #{$large-up}{
		// 	margin: 0 ms(0) ms(0) 0;

		// 	&:last-of-type{
		// 		margin-right: 0;
		// 	}
		// }
	}

	.uploadsigner__input-inner--selected {
		border: 1px solid gc(dl-sky);
		background: gc(dl-white);
		color: gc(dl-black);
		// font-weight: bold;

		&:hover {
			background: gc(dl-white);
		}
	}

	.uploadsigner__content{
		display: flex;
		align-items: flex-end;

		.is-fixed &{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.uploadsigner__icon{
		align-self: flex-start;
		margin-right: ms(0);
	}

	.uploadsigner__text{
		flex: 1;
		font-size: ms(0);
	}

	.uploadsigner__actions{
		display: block;
		margin-top: ms(0);
	}
}
