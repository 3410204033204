@include exports('components/toggleboxes'){
	/***
	 * Toggleboxes:
	 */

	/**
	 *
	 *Togglebox basic setup:
	 *
	 *`.togglebox.togglebox--base > .togglebox__icon`
	 *
	 *Togglebox is a component that expands when clicked to reveal additional content. Used in conjunction with the toggleBox.js behavior.
	 * A togglebox itself is a container div. Add a data-toggle='value' attribute to the togglebox and an id of the same value to the target,
	 * and the javascript toggles '.is-expanded' class on the togglebox and '.is-gone' class on the target when clicked.
	 * To do: Swap style naming so that this is basic class and styled guy has all the modifiers...
	 *
	 * 	@example
	 *		div.card
	 *			div.togglebox.togglebox--base.is-expanded
	 *				div.togglebox__target
	 *					span.u-text__bold The content you always see
	 *					div.togglebox__icon
	 *						svg.icon.icon--tiny
	 *							use('xlink:href'='images/sprites.svg#icon-chevronup')
	 *				div
	 *					span The content that wouuld be hidden
	 *
	 */

	// basic togglebox - no flexbox, less formatting
	.togglebox.togglebox--base{

		.togglebox__icon{
			display: inline-block;
			margin-left: ms(1);
			transition: none;
			// line-height: 1.3;
			vertical-align: middle;
		}

		// .togglebox__bottom-container{
		// 	border-top: 0;
		// }
	}

	.togglebox.togglebox--base.is-expanded {

		.togglebox__icon{

			.icon{
				margin-bottom: ms(-4);
			}
		}
	}

	.togglebox__target{
		cursor: pointer;
	}

	// /**
	//  *Togglebox, styled:
	//  *
	//  *`.togglebox.togglebox__top-container > .togglebox__top-content > ...`
	//  *`.togglebox.togglebox__top-container.is-expanded > .togglebox__top-content > ...` + `.togglebox__bottom-container > .togglebox__bottom-content > ...`
	//  *
	//  *Original togglebox component - heavily styled, more specific class selection. Works with separate togglebox() function, was used for mobile view of old subscription
	//  * upgrade list.
	//  *
	//  * 	@example
	//  *		div.card
	//  *			div.togglebox.togglebox__top-container
	//  *				div.togglebox__top-content
	//  *					div.togglebox__item
	//  *						h3 Name
	//  *					div.togglebox__item
	//  *						div.pricetag.pricetag--inline
	//  *							span.pricetag__pre DLZ
	//  *							span.pricetag__amount A BILLION
	//  *					div.togglebox__icon
	//  *						svg.icon.icon--small
	//  *							use(xlink:href='images/sprites.svg#icon-chevronup')
	//  *			div.togglebox.togglebox__top-container.is-expanded
	//  *				div.togglebox__top-content
	//  *					div.togglebox__item
	//  *						h3 Name
	//  *					div.togglebox__item
	//  *						div.pricetag.pricetag--inline
	//  *							span.pricetag__pre DLZ
	//  *							span.pricetag__amount A BILLION
	//  *					div.togglebox__icon
	//  *						svg.icon.icon--small
	//  *							use(xlink:href='images/sprites.svg#icon-chevronup')
	//  *				div.togglebox__bottom-container
	//  *					div.togglebox__bottom-content
	//  *						h3 Get it get it
	//  *						div.actions
	//  *							div.button.button--primary.button--small Now!
	//  *
	//  */

	// container when not expanded

	.togglebox__top-container{
		margin-top: ms(2);
		border: 1px solid gc(dl-grey);
		border-radius: map-get($border-radius, small);
		cursor: pointer;

		&:hover{
			border-color: gc(dl-sky);
		}
	}

	// content when not expanded

	.togglebox__top-content{
		display: inline-block;
		width: 100%;
		padding: ms(2);

		@media #{$medium-up}{
			display: flex;
			// scss-lint:disable PropertySortOrder
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			// scss-lint:enable PropertySortOrder

			.togglebox__item{
				flex-basis: 0;
				flex-grow: 2;
			}
		}
	}

	// icon that rotates in '.is-expanded' state. Usually a chevron

	.togglebox__icon{
		display: inline-block;
		margin-left: ms(2);
		transform: rotate(180deg);
		transition: transform map-get($transition-duration, fast) $ease-in-out-quad;
		cursor: pointer;

		.togglebox.is-expanded & {
			transform: rotate(0deg);
		}

		@media #{$medium-up}{
			display: flex;
			margin-left: 0;
		}
	}

	// top border of expanded content area

	.togglebox__bottom-container{
		display: none;
		border-top: 1px solid gc(dl-grey);

		.togglebox.is-expanded & {
			display: block;
		}
	}


	// expanded content

	.togglebox__bottom-content{
		padding: ms(2);
	}


}
