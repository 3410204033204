@include exports('components/topnavs'){

	/***
	 *Topnavs:
	 *
	*/

	/**
	 *
	 *Topnav:
	 *
	 *`.topnav > .topnav__list > .topnav__listitem.topnav__listitem--dropdown/+--icon > .topnav__icon-container > .topnav__icon, .topnav__header`,
	 *`.topnav-sub > .topnav-sub__row > .topnav-sub__column > .topnav-sub__list > .topnoav-sub__header > .topnav-sub__listlink`
	 *
	 *The topnav for Dragon Law v2.5+ is built in React, and
	 * is a much cleaner, simplified version of the original v2.0 topnav. It is a good
	 * example for theming as well, as it's the most heavily themed component in the app's
	 * whitelabeled accounts. Add a `.topnav--partner` class to the top level `.topan` element, and place it inside a section with the
	 * class `.localhost`, to see how the component is themed for white labelled partner apps.
	 *
	 * 	@example
	 *		div.topnav.topnav--example
	 *			div.topnav__list
	 *				div.topnav__logo
	 *					svg.icon.u-color__dl-ruby
	 *						use(xlink:href='#icon-dragonlaw')
	 *				div.topnav__menutoggle
	 *					div.topnav__menutoggle-inner
	 *						span Toggle
	 *				div.topnav__listitem.topnav__listitem--dropdown
	 *					div.topnav__orgswitcher
	 *						div.switcher.switcher--org(data-dropdown='example-org-dropdown')
	 *							div.switcher__text Active Org Name
	 *							div.switcher__arrow
	 *							ul#example-org-dropdown.dropdown.dropdown--selectable.dropdown--switcher.dropdown--orgswitcher.dropdown--nopip
	 *								li.dropdown__item.is-active Active Org Name
	 *								li.dropdown__item Other Org Name
	 *								li.dropdown__item Other Org Name
	 *				div.topnav__listitem.topnav__listitem--icon
	 *					div.topnav__icon-container
	 *						div.topnav__icon.topnav__icon--styleable
	 *							svg.icon.icon--small
	 *								use(xlink:href='#icon-circleplus--menu')
	 *						span.topnav__header Create
	 *				div.topnav__listitem.topnav__listitem--icon
	 *					div.topnav__icon-container
	 *						div.topnav__icon.topnav__icon--styleable
	 *							svg.icon.icon--small
	 *								use(xlink:href='#icon-document--menu')
	 *						span.topnav__header My Documents
	 *				div.topnav__listitem.topnav__listitem--icon.topnav__listitem--dropdown.has-hover
	 *					div.topnav__icon-container
	 *						div.topnav__icon.topnav__icon--styleable
	 *							svg.icon.icon--small
	 *								use(xlink:href='#icon-info--menu')
	 *						span.topnav__header Help
	 *					div.topnav-sub.topnav-sub--shift-right
	 *						div.topnav-sub__row
	 *							div.topnav-sub__column
	 *								ul.topnav-sub__list
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Contact
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Get a Trademark
	 *				div.topnav__listitem.topnav__listitem--icon.topnav__listitem--dropdown.has-hover
	 *					div.topnav__icon-container
	 *						div.topnav__icon.topnav__icon--styleable
	 *							svg.icon.icon--small
	 *								use(xlink:href='#icon-settings--menu')
	 *						span.topnav__header Settings
	 *					div.topnav-sub.topnav-sub--shift-right
	 *						div.topnav-sub__row
	 *							div.topnav-sub__column
	 *								ul.topnav-sub__list
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Subscription
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Org info
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Billing
	 *									li.topnav-sub__header.topnav-sub__header--lowercase
	 *										a.topnav-sub__listlink Sign out
	 *
	 */

	// TOPNAV STYLES FOR PARTNER APP & APP v2.5

	.topnav{
		position: fixed;
		z-index: 90; // issue #3134 - putting topnav on top of dropdowns
		left: 0;
		width: 100%;
		height: $topnav-height;
		// background: $topnav-bgcolor;
		// color: $topnav-textcolor;
		box-shadow: 0 0 4px gc(dl-lightgrey);
		font-family: 'Helvetica Neue', Roboto, Arial, sans-serif;
		font-size: ms(0);
	}

	.topnav--noshadow{
		box-shadow: none;
	}

	// set pos:rel just for the styledown example
	.topnav--example{
		position: relative;
	}

	.topnav__list{
		display: flex;
		max-width: $max-content-width; // from marketing site max width
		margin: 0 auto;
		padding: 0;

		// @media #{$large-up}{
			// display: flex;
			// padding: 0 ms(3);
		// }
	}

	.topnav__list.is-open{ // if clicked open by toggle
		display: block;
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		// background: $topnav-bgcolor;
		overflow-y: auto;

		@media #{$large-up}{
			position: relative;
			width: auto;
			height: auto;
		}
	}

	.topnav__listitem{
		display: none;
		position: relative;
		height: $topnav-height;
		user-select: none;

		.is-open &{ // if parent menu is open in mobile view
			display: block;

		}

		@media #{$large-up}{
			display: flex;
			flex: 1 auto;
			align-items: center;
		}
	}

	.topnav__listitem--orgswitcher{
		display: flex;

		.topnav__orgswitcher{
			display: none;

			.is-open &{
				display: inline-block;
			}

			@media #{$large-up}{
				display: inline-block;
			}
		}
	}

	.topnav__listitem:hover{
		cursor: pointer;
	}

	.topnav__listitem--dropdown:hover{

		@media #{$large-up}{
			cursor: default;

			.topnav__icon{
				cursor: default;
			}

			.topnav__header{
				cursor: default;
			}
		}
	}

	.topnav__listitem--small-only{
		display: none;
	}

	.topnav__logo{
		display: block;
		visibility: visible;
		flex: 0 auto;
		height: $topnav-height;
		margin-right: ms(1);
		// padding: ms(-4) 0 ms(-4) ms(3);
		padding: ms(-4) calc(#{$column-gutter + ($column-gutter / 2)});

		img{
			max-width: 100%;
			height: $topnav-height;
			max-height: 100%;
		}

		@media #{$xlarge-up}{
			padding: ms(-4) 0 ms(-4) $column-gutter / 2;
		}
	}

	.topnav__logo--partner{
		max-width: calc(#{ms(13)} + #{ms(2)});
		margin-right: $topnav-height;
		padding: ms(0) 0 ms(0) ms(1);

		img{
			max-width: calc(#{ms(9)} + #{ms(2)});
			height: auto;
			// max-height: 100%;
		}

		@media #{$large-up}{
			padding: ms(0) 0 ms(0) ms(3);
		}
	}

	.topnav__disclaimer{
		display: inline-block;
		margin-left: ms(0);
		font-size: ms(-2);
		text-transform: uppercase;
		vertical-align: bottom;
		cursor: default;
		user-select: none;
	}

	.topnav__orgswitcher{
		text-transform: none;

		@media #{$large-up}{
			margin-left: ms(1);
		}

		.is-open &{
			display: none;

			@media #{$large-up}{
				display: inline-block;
			}
		}
	}

	.topnav__orgname{ // for orgs without any additional orgs (no dropdown)
		display: none;
		// max-width: ms(6);
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: nowrap;

		// @media #{$medium-up}{
		// 	max-width: ms(14);
		// }

		@media #{$large-up}{
			display: inline-block;
			max-width: ms(10);
		}

		@media #{$xlarge-up}{
			max-width: ms(12);
		}

		// .topnav__header{
		// 	text-overflow: ellipsis;
		// 	white-space: nowrap;
		// 	overflow: hidden;
		// }
	}

	.topnav__upgradenag{
		display: none;
		margin: 0 ms(1);
		vertical-align: sub;
		cursor: pointer;

		@media #{$large-up}{
			display: inline-block;
		}
	}

	.topnav__notification-container{
		display: none;
		// position: absolute;
		// top: calc(-#{$topnav-height});
		// right: $topnav-height;

		.topnav__icon-container--full-height{
			// display: flex;
			display: block;
			align-items: center;
			padding: calc(#{$topnav-height} - (#{ms(2)} * 2)) 0;

			// .topnav__list.is-open &{
			// 	margin: 0 ms(0) 0 ms(1);
			// }
		}

		@media #{$large-up}{
			display: block;
			// position: relative;
			// top: auto;
			// right: auto;

			.topnav__icon-container--full-height{
				display: block;
				align-items: center;

				// .topnav__list.is-open &{
				// 	margin: 0;
				// }
			}
		}
	}

	.topnav__notification-container--small-only{
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		flex: 1;
		align-self: flex-end;
		margin-right: $topnav-height;
		// position: relative;
		// top: 0;
		// right: $topnav-height;

		.topnav__icon-container--full-height{
			display: flex;
			position: relative;
			align-items: center;
			justify-content: flex-end;
			padding: calc(#{$topnav-height} - (#{ms(2)} * 2)) 0;

			.topnav__list.is-open &{
				margin: 0 ms(0) 0 ms(1);
			}
		}

		// .topnav__list.is-open &{
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// }

		@media #{$large-up}{
			display: none;
		}
	}

	// .topnav__lozenge{
	// 	@if $topnav-bgcolor == gc(dl-white){
	// 		border: 1px solid $glb-primary-color;
	// 		background-color: transparent;
	// 		color: $glb-primary-color;
	// 	} @else{
	// 		border: 0;
	// 		background-color: $glb-primary-color;
	// 		color: gc(dl-white);
	// 	}
	// }

	.topnav__header{
		display: block;
		text-transform: uppercase;
		cursor: pointer;
	}

	.topnav__listitem--icon-example + .topnav__header{
		margin-left: ms(4);
	}

	.topnav__listitem:hover .topnav__header{
		transition: color map-get($transition-duration, fast) $ease-out-quad;

		// @if $topnav-bgcolor == gc(dl-white){
		// 	color: $glb-tertiary-color;
		// } @else{
		// 	color: gc(dl-white);
		// }
	}

	// .topnav__listitem.is-active .topnav__header,
	// .topnav__listitem.is-active .topnav__icon svg{
	// 	color: $glb-tertiary-color;
	// }

	.topnav__icon-container{
		display: flex;

		@media #{$large-up}{
			display: flex;
			align-items: center;
		}
	}

	.topnav__icon-container--full-height{
		height: $topnav-height;
	}

	.topnav__icon-container--notification{
		z-index: 100;
		margin: 0 ms(0) 0 ms(1);
		cursor: pointer;
	}

	// Styleable menu icons
	// .topnav__icon--styleable svg{
	// 	color: $topnav-textcolor;
	// }

	// .topnav__listitem:hover .topnav__icon--styleable svg{
	// 	@if $topnav-bgcolor == gc(dl-white){
	// 		color: $glb-tertiary-color;
	// 	} @else{
	// 		color: gc(dl-white);
	// 	}
	// }

	.topnav__icon + .topnav__header,
	.topnav__header + .topnav__icon,
	.topnav__orgname + .topnav__icon{

		@media #{$large-up}{
			margin-left: 8px;
		}
	}

	.topnav__menutoggle{
		display: block;
		position: absolute; // in small view, move up into top right
		top: 0; // in small view, move up into top right
		right: 2px; // in small view, move up into top right
		width: $topnav-height;
		height: $topnav-height;
		margin: 0;
		padding: 0;
		overflow: hidden;
		font-size: 0;
		text-indent: -9999px;
		cursor: pointer;
		appearance: none;

		@media #{$large-up}{
			display: none;
		}

		&:focus{
			outline: none;
		}
	}

	.topnav__menutoggle-inner{

		// @if $topnav-bgcolor == gc(dl-white){ // if topnav is white, set menu bars/x to secondary color
		// 	background-color: $glb-secondary-color;
		//
		// 	&::before,
		// 	&::after{
		// 		background-color: $glb-secondary-color;
		// 	}
		// } @else{ // otherwise, keep them white
		// 	background-color: gc(dl-white);
		//
		// 	&::before,
		// 	&::after{
		// 		background-color: gc(dl-white);
		// 	}
		// }

		display: block;
		position: absolute;
		top: ms(4);
		right: 19px;
		left: 19px;
		height: 2px;

		&::before,
		&::after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			transition: background 0s .15s;
			transition-duration: .15s, .15s;
			transition-delay: 0s, .15s;
		}

		&::before{
			top: -5px;
			transition-property: top, transform;
		}

		&::after{
			bottom: -5px;
			transition-property: bottom, transform;
		}
	}

	.topnav__menutoggle-inner.is-active{
		background: none;

		@media #{$large-up}{
			display: none;
		}

		&::before {
			top: 0;
			transform: rotate(45deg);
		}

		&::after {
			bottom: 0;
			transform: rotate(-45deg);
		}

		&::before,
		&::after {
			transition-delay: 0s, 3s;
		}
	}

	.topnav-sub{
		display: none;
		// background: $topnav-bgcolor;

		@media #{$large-up}{

			display: block;
			visibility: hidden;
			position: absolute;
			top: $topnav-height;
			left: -(ms(3));
			max-height: calc(100vh);
			padding: ms(3);
			overflow-y: auto;
			transition: opacity .2s $ease-in-out-quad;
			// background: $topnav-bgcolor;
			opacity: 0;

			.topnav__listitem--dropdown.has-hover:hover &,
			.topnav-sub:hover &{
				visibility: visible;
				opacity: .9;
			}
		}
	}

	.topnav-sub__list{ // sub-submneu list of links
		display: block;
		width: 100%;
		margin: 0;
		list-style-type: none;

		@media #{$large-up}{
			min-width: ms(10);
		}
	}

	.topnav-sub__header{ // top of each sub-submenu list
		height: $topnav-height;
		border-top: 1px solid rgba(gc(dl-grey), .2);
		text-transform: uppercase;

		@media #{$large-up}{
			height: auto;
			margin-bottom: ms(2);
			border: 0;

			&:last-of-type{
				border: 0;
			}
		}
	}

	.topnav-sub__header--lowercase{ // want thsi to be uppercase on mobile menu, otherwise not

		@media #{$large-up}{
			text-transform: none;

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}

	.topnav__listitem--dropdown.has-hover:hover .topnav-sub{

		@if $topnav-bgcolor == gc(dl-white){ // if topnav is white, set dropdown opacity to 1
			opacity: 1;
		} @else{ // otherwise, keep it a little transparent
			opacity: .9;
		}

		@media #{$large-up}{

			@if $topnav-bgcolor == gc(dl-white){ // if topnav is white, give dropdown a box shadow on large screens
				box-shadow: 0 2px 2px gc(dl-lightgrey);
			}
		}
	}

	// .topnav-sub__header:hover{
	//
	// 	.topnav-sub__listlink,
	// 	a.topnav-sub__listlink{
	// 		@if $topnav-bgcolor == gc(dl-white){
	// 			color: $glb-tertiary-color;
	// 		} @else{
	// 			color: gc(dl-white);
	// 		}
	// 	}
	// }

	.topnav-sub__listlink,
	a.topnav-sub__listlink{
		@include verticalcenter;
		display: block;
		// color: $topnav-textcolor;
		text-decoration: none; // reset link styles
		text-transform: none;

		// &:hover,
		// &:focus,
		// &:active{
		// 	@if $topnav-bgcolor == gc(dl-white){
		// 		color: $glb-tertiary-color;
		// 	} @else{
		// 		color: gc(dl-white);
		// 	}
		// }
	}

	.topnav__list.is-open .topnav__listitem{
		height: auto;

		// @if $topnav-bgcolor == gc(dl-white){
		// 	background-color: $glb-layout-color;
		// } @else{
		// 	background-color: $topnav-bgcolor;
		// }
	}

	.topnav__list.is-open .topnav__listitem.is-open{
		border-top: 1px solid rgba(gc(dl-grey), .2);
		// background-color: $topnav-bgcolor;
	}

	.topnav__list.is-open .topnav__upgradenag{
		display: none;
	}

	.topnav__list.is-open .topnav__listitem--small-only{
		display: flex;
		align-items: center;
		height: $topnav-height;

		.topnav__upgradenag{
			display: block;
		}

		@media #{$large-up}{
			display: none;
		}
	}

	.topnav__list.is-open .topnav__orgswitcher{ // in regular app, orgswitcher is hidden when topnav__list is open
		display: flex;
		align-items: center;
		align-self: center;
		height: $topnav-height;
		margin: 0 ms(3);
		text-transform: none;
	}

	.topnav__list.is-open .topnav__icon-container{
		display: flex;
		align-items: center;
		height: $topnav-height;
		margin: 0 ms(3);
	}

	.topnav__list.is-open .topnav__icon{
		display: block;
	}

	.topnav__list.is-open .topnav__icon.is-active{
		transform: rotate(180deg);
	}

	.topnav__list.is-open .topnav__header{
		margin-left: 0;
	}

	.topnav__list.is-open .topnav__icon + .topnav__header,
	.topnav__list.is-open .topnav__header + .topnav__icon,
	.topnav__list.is-open .topnav__orgname + .topnav__icon{ // add a left margin for listitem headers preceded by icons, and for icons preceded by headers or the orgname div that holds the header
		margin-left: ms(1);
	}

	// .topnav__list.is-open .topnav__orgname{
		// display: block;
		// position: relative;
		// max-width: 100%;
	// }

	// .topnav__listitem.is-open{
	// 	background: $topnav-bgcolor;
	// }

	.topnav-sub.is-open{ // when this menu is open in mobile
		display: block;
	}

	.topnav-sub.is-open .topnav-sub__header{
		display: block;
	}

	.topnav-sub.is-open .topnav-sub__listlink{
		margin-left: calc(#{ms(7) - ms(-6)});
	}

	.topnav-sub--with-upgrade-nag{

		@media #{$large-up}{
			left: ms(7);
		}
	}

	.topnav-sub--shift-right{

		@media #{$large-up}{
			left: ms(-16);

			.topnav-sub__list{
				min-width: ms(11);
			}

			.topnav-sub__header{
				padding-left: ms(0);
			}
		}
	}
}
