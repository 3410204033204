@include exports('components/slats'){
	/***
	 * Slats:
	 *
	 * Slats are rows of list-like content
	 */

	/**
	 *
	 *Basic slat:
	 *
	 *`.slat`
	 *
	 *In these examples, we put the slats in a `.card` module. That's how you'll most likely use them.
	 *Use a slat with `slat__content.slat__content--abbr` to ellipsis out any overflown content.
	 *Use a slat with` slat__content.slat__content--visible` to set overflow to visible.
	 *Use a slat with` slat__content.slat__content--break` to set overflow to visible and wrap long strings of text (like URLs) with hyphenation.
	 *
	 * 	@example
	 *		div.card
	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title I am a basic slat
	 *					div.slat__text I have some supplimentary text too
 	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title I am a basic slat
	 *					div.slat__text I have some supplimentary text too
  	 *			div.slat
	 *				div.slat__content.slat__content--abbr
	 *					div.slat__title I am a slat with abbreviated content.
	 *					div.slat__text Here is a really really really really really long amount of supplimentary text like lorem ipsum dolor sit amet hocus pocus dorkus malorkus hey presto kablammo bingo bango tango and cash and bat yer eyelashes cheese it it's the fuzz let's beat it scram get outta here if you're still reading this your screen is really really really wide.
	 *
	 */

	.slat{
		@include clearfix;
		display: flex;
		align-items: center;
		padding: $slat-vert-padding 0;
		border-bottom: 1px solid gc(dl-ash);

		// &:first-of-type{
			// padding-top: 0;
		// }

		&:last-of-type{
			// padding-bottom: 0;
			border-bottom: 0;
		}

		.no-flexbox &{
			display: block;
		}
	}

	.slat__content{
		display: inline-block;
		flex: 1;
		overflow: hidden;


		.no-flexbox &{
			display: block;
		}
	}

	.slat__content--abbr{

		.slat__title,
		.slat__text{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.slat__content--break{
		overflow: visible;
		word-wrap: break-word;
		word-break: break-word;
		hyphens: auto;
	}

	.slat__content--wrap{

		.slat__title,
		.slat__text{
			overflow: visible;
		}
	}

	.slat__content--visible{
		overflow: visible;
	}

	.slat__title{
		h3{
			margin: 0;
		}

		.no-flexbox &{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.slat__text{
		color: gc(dl-basalt);
		font-size: ms(0);
		font-style: italic;

		.no-flexbox &{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.slat__title + .slat__text{
		margin-top: ms(-3);
	}

	.slat__content + .slat__content{
		margin-left: ms(1);

		.slat--flex-large-up &{
			margin-left: 0;

			@media #{$large-up}{
				margin-left: ms(1);
			}
		}
	}

	.slat__spacer{ // @TODO: document this

		@media #{$large-up}{
			flex: 0 auto;
			min-width: ms(8);
			margin-right: ms(2);
		}
	}

	/**
	 *
	 *Slat actions:
	 *
	 *`.slat > .actions`
	 *
	 *It often makes sense to include an actions component at the bottom of a slat list inside the card.
	 *
	 *
	 * 	@example
	 *		div.card
	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title I am a basic slat
 	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title I am a basic slat
  	 *			div.slat
	 *				div.slat__content
	 *					div.slat__title I am a basic slat
	 *			div.actions
	 *				a.button.button--small Add another slat
	 *
	 */


	/**
	 *
	 *Icon slats:
	 *
	 *`.slat > slat__icon + .slat__content`
	 *
	 *Add a `.slat__icon` into the mix for more slatty fun.
	 *Icons can be any size but will blow out small slats.
	 *
	 * 	@example
 	 *		div.card
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				.slat__content
	 *					div.slat__title I am an icon slat
	 *					div.slat__text I have some supplimentary text too
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-document--color')
	 *				.slat__content
	 *					div.slat__title I am an icon slat
	 *					div.slat__text I have some supplimentary text too
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--medium
	 *						use(xlink:href='images/sprites.svg#icon-draft--color')
	 *				.slat__content
	 *					div.slat__title I am an icon slat
	 *					div.slat__text I have some supplimentary text too
  	 *		div.card
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				.slat__content
	 *					div.slat__title I am an icon slat
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-document--color')
	 *				.slat__content
	 *					div.slat__title I am an icon slat
	 *					div.slat__text I have some supplimentary text too
	 *			div.slat
	 *				.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-draft--color')
	 *				.slat__content
	 *					div.slat__title.slat__title--icon I am an icon slat with an icon after the title!
	 *						.slat__icon
	 *							svg.icon.icon--tiny
	 *								use(xlink:href='images/sprites.svg#icon-share')
	 *
	 */

	.slat__icon{
		display: inline-block;
		align-self: flex-start;
		margin-right: ms(1);

		.no-flexbox &{
			float: left;
		}
	}

	// .slat__icon + .slat__content{

	// 	.slat__title{

	// 		h3{
	// 			margin-top: 0;
	// 		}
	// 	}
	// }

	// If you need to throw an icon in after the title text

	.slat__title--icon{

		.slat__icon{
			margin-right: 0;
			margin-left: ms(1);
			// vertical-align: text-bottom;
		}
	}

	// .slat__icon--notebadge{
	// 	position: relative;
	// }
	//
	// .slat__notebadge{
	// 	display: inline-block;
	// 	position: absolute;
	// 	top: -(ms(-3));
	// 	right: -(ms(-3));
	// 	width: ms(2);
	// 	height: ms(2);
	// 	// margin-right: ms(0);
	// 	border-radius: 100%;
	// 	background-color: gc(dl-ruby);
	// }
	//
	// .slat__notenumber{
	// 	@include totalcenter;
	// 	color: $white;
	// 	font-size: ms(-2);
	// 	text-align: center;
	// }

	// /**
	//  *
	//  *Input slats:
	//  *
	//  *`.slat > slat__input + .slat__content`
	//  *
	//  *add a `.slat__input` to anchor a checkbox or radio input left and line up all content to its right.
	//  *
	//  * 	@example
 // 	 *		div.card
	//  *			div.slat
	//  *				.slat__input
	//  *					 input#slatinput1(type='checkbox')
	//  *				.slat__content
	//  *					label checkbox
	//  *					div.slat__text I have some supplimentary text too
	//  *		div.card
	//  *			div.slat
	//  *				.slat__input
	//  *					 input#slatinput2(type='radio')
	//  *				.slat__content
	//  *					label radio
	//  *					div.slat__text I have some supplimentary text too
	//  */
	//
	// .slat__input{
	// 	display: inline-block;
	// 	align-self: flex-start;
	// 	margin-right: ms(0);
	//
	// 	.no-flexbox &{
	// 		float: left;
	// 	}
	//
	// 	input[type='radio'],
	// 	input[type='checkbox']{
	// 		margin-left: 1px; // used to override inputs getting clipped in some browsers due to 'overflow:hidden' on slats
	// 	}
	// }

	/**
	 *
	 *Slat tools:
	 *
	 *`.slat > ... + .slat__tools`
	 *
	 *Slat tools are a little dropdown that can be placed at the right end of a slat. The dropdown
	 * includes various options for things pertaining to the slat.
	 *
	 * 	@example
  	 *		div.card
	 *			div.slat
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am a tools slat
	 *				div.slat__tools(data-dropdown='slatToolsDD1' data-options='align: left')
	 *					 svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-dotmenu')
	 *				ul.dropdown.dropdown--left#slatToolsDD1(data-dropdown-content='')
	 *					li Dropdown Item 1
	 *					li Dropdown Item 2
	 *					li Dropdown Item 3
	 *			div.slat
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am a tools slat
	 *				div.slat__tools(data-dropdown='slatToolsDD2' data-options='align: left')
	 *					 svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-dotmenu')
	 *				ul.dropdown.dropdown--left#slatToolsDD2(data-dropdown-content='')
	 *					li Dropdown Item 1
	 *					li Dropdown Item 2
	 *					li Dropdown Item 3
 	 *			div.slat
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am a tools slat too!
	 *				div.slat__tools
	 *					 svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-circlecross')
	 */

	.slat__tools{
		margin-left: ms(1);

		.no-flexbox &{
			float: right;
		}

		> .button,
		> .textbutton,
		> .splitbutton{
			margin-bottom: 0;
		}

		.form__element{
			min-width: ms(10);
		}
	}

	.slat__content + .slat__tools{

		.button{
			margin-top: ms(1);
		}
	}

	/**
	 *
	 *Draggable slat:
	 *
	 *`.slat.slat--draggable`
	 *
	 *Shows a drag handle and some hover effects that denote a slat as draggable to reorder / move.
	 *
	 * 	@example
  	 *		div.card
	 *			div.slat.slat--draggable
	 *				div.slat__dragger
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am draggable
	 *			div.slat.slat--draggable
	 *				div.slat__dragger
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am a draggable tools slat
	 *				div.slat__tools
	 *					 svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-dotmenu')
 	 *			div.slat.slat--draggable
	 *				div.slat__dragger
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am a draggable tools slat too!
	 *				div.slat__tools
	 *					 svg.icon.icon--tiny
	 *						use(xlink:href='images/sprites.svg#icon-circlecross')
   	 *		div.card
	 *			div.slat.slat--draggable.is-dragging
	 *				div.slat__dragger
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title I am draggable in dragging state
	 *
	 */

	.slat--draggable{
		position: relative;

		.slat__dragger{
			display: block;
			align-self: flex-start;
			width: ms(-3);
			height: map-get($icon-size, small);
			margin: 0 ms(0) 0 0;
			transition: all map-get($transition-duration, fast) $ease-in-out-quad;
			opacity: .3;
			background-image: linear-gradient(transparent, transparent 50%, gc(dl-basalt) 50%, gc(dl-basalt));
			background-size: 100% 4px;
			cursor: grab;

			&:hover{
				opacity: 1;
			}
		}


	}

	.slat--draggable.is-dragging{
		border: 1px solid gc(dl-ash);
		border-radius: (map-get($border-radius, small));
		background: rgba(#fff, .9);
		box-shadow: ms(-4) ms(-4) ms(1) rgba(gc(dl-black), .1);

		.slat__dragger{
			opacity: 1;
			cursor: grabbing;
		}
	}

	/**
	 *
	 *Droppable:
	 *
	 *`.slat.slat--droppable`
	 *
	 *Shows that a slat is capable of recieving draggable items. It has two states:
	 *
	 * `.is-droppable` : When an element that can be dropped onto it is being dragged.
	 *
	 *`.is-dropover` : When a droppable element is hovering above it.
	 *
	 * 	@example
	 *		div.card
	 *			div.slat.slat--droppable.is-droppable
	 *				.slat__content I am ready to have a drop
	 *		div.card
	 *			div.slat.slat--droppable.is-dropover
	 *				.slat__content Something is hovering over me!
	 *
	 */

	.slat--droppable{
		transition: all map-get($transition-duration, fast) $ease-in-out-quad;
	}

	.slat--droppable.is-droppable{
		background: rgba(gc(dl-sky), .1);
	}

	.slat--droppable.is-dropover{
		z-index: 1;
		background: rgba(gc(dl-sky), .5);
		box-shadow: ms(-6) ms(-6) ms(1) rgba(gc(dl-black), .2);
	}

	// /**
	//  *
	//  *Optionbar slats:
	//  *
	//  *`.slat.slat--optionbar` > `.slat__icon`, `.slat__options` > `.slat__option.slat__option--primary` > `.slat__option` > `.slat__optiontext`, `.slat__tools`
	//  *
	//  *Used in My Documents, an optionbar slat is used inside a card--optionbar. As with optionbar cards, this component is currently set up only for this instance in
	//  * the app, and is inflexible. See cards for more details on layout. A `.slat__option--secondary` can be assigned as a modifier to the second option to change
	//  * the flex order of the option to third place on small and medium screens.
	//  *
	//  * 	@example
	//  *		div.card.card--optionbar
	//  *			div.card__optionbar
	//  *				div.card__option.card__option--primary
	//  *					div.card__optiontitle
	//  *						span Option one!
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--asc')
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--desc')
	//  *				div.card__option.card__option
	//  *					div.card__optiontitle
	//  *						span Option two!
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--asc')
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--desc')
	//  *				div.card__option.card__option
	//  *					div.card__optiontitle
	//  *						span Option three!
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--asc')
	//  *					div.card__optionbutton
	//  *						svg.icon.icon--micro
	//  *							use(xlink:href='images/sprites.svg#icon-sort--desc')
	//  *			div.card__content
	//  *				div.slat.slat--optionbar
	//  *					div.slat__icon
	//  *						svg.icon.icon--small
	//  *							use(xlink:href='images/sprites.svg#icon-document--color')
	//  *					div.slat__options
	//  *						div.slat__option.slat__option--primary
	//  *							span Option text!
	//  *						div.slat__option.slat__option--secondary.slat__option--dropdown(data-dropdown='slat-option-dropdown')
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *							div.slat__optiontext
	//  *								span Signing
	//  *								div.slat__optionicon
	//  *									svg.icon.icon--micro
	//  *										use(xlink:href='#icon-chevrondown--menu')
	//  *							div#slat-option-dropdown.dropdown.dropdown--nopip.dropdown--slatoption
	//  *								div.slat.slat--h-spacious
	//  *									svg.icon.icon--tiny
	//  *										use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *									div.slat__optiontext
	//  *										span Signing
	//  *										div.slat__optionicon
	//  *											svg.icon.icon--micro
	//  *												use(xlink:href='#icon-chevrondown--menu')
	//  *								div.slat.slat--h-spacious.slat--invisible.slat--optionbar
	//  *									div.slat__options.slat__options--abbr.slat__options--flex-large-up
	//  *										div.slat__option.slat__option--left
	//  *											div.slat__content
	//  *												div.slat__optiontext.u-text__bold You
	//  *												div.slat__optiontext firstname@lastname.net
	//  *											svg.icon.icon--tiny
	//  *												use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *										div.slat__option.slat__option--right
	//  *											div.slat__tools
	//  *												div.button.button--primary.button--small.button--block.u-box__mt0 Sign now
	//  *								div.slat.slat--h-spacious.slat--invisible.slat--optionbar
	//  *									div.slat__options.slat__options--abbr.slat__options--flex-large-up
	//  *										div.slat__option.slat__option--left
	//  *											div.slat__content
	//  *												div.slat__optiontext.u-text__bold Firstname Lastname
	//  *											svg.icon.icon--tiny
	//  *												use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *										div.slat__option.slat__option--right
	//  *											div.slat__tools
	//  *												div.button.button--small.button--block.u-box__mt0 Add email
	//  *								div.slat.slat--h-spacious.slat--invisible.slat--optionbar
	//  *									div.slat__options.slat__options--abbr.slat__options--flex-large-up
	//  *										div.slat__option.slat__option--left
	//  *											div.slat__content
	//  *												div.slat__optiontext.u-text__bold Firstname Lastname
	//  *												div.slat__optiontext firstname@lastname.net
	//  *											svg.icon.icon--tiny
	//  *												use(xlink:href='images/sprites.svg#icon-state--Signing')
	//  *										div.slat__option.slat__option--right
	//  *											div.slat__tools
	//  *												div.button.button--small.button--block.u-box__mt0 Resend
	//  *								div.slat.slat--h-spacious.slat--invisible.slat--optionbar
	//  *									div.slat__options.slat__options--abbr.slat__options--flex-large-up
	//  *										div.slat__option.slat__option--left
	//  *											div.slat__content
	//  *												div.slat__optiontext.u-text__bold Firstname Lastname
	//  *												div.slat__optiontext firstname@lastname.net
	//  *											svg.icon.icon--tiny
	//  *												use(xlink:href='images/sprites.svg#icon-state--Signed')
	//  *										div.slat__option.slat__option--right
	//  *											div.slat__tools
	//  *						div.slat__option
	//  *							svg.icon.icon--tiny
	//  *								use(xlink:href='images/sprites.svg#icon-folder--color')
	//  *							div.slat__optiontext
	//  *								span Option text!
	//  *					div.slat__tools
	//  *						svg.icon.icon--tiny.icon--hover
	//  *							use(xlink:href='images/sprites.svg#icon-dotmenu')
	//  */

	// .slat--optionbar{
	// 	cursor: pointer;
	//
	// 	.slat__icon{
	// 		display: flex;
	// 		min-width: calc(#{ms(-3) + ms(0) + ms(2)}); // slat__icon in the optionbar reserves space for the dragger and the doc icon. since it matches up in horz width w/the card optionbar, we have to guarantee this space whether or not the dragger is there
	//
	// 		.icon{
	// 			margin-left: ms(2);
	// 		}
	//
	// 		.slat__dragger + .icon{
	// 			margin-left: 0;
	// 		}
	// 	}
	//
	// 	.slat__tools{
	// 		align-self: flex-start;
	// 	}
	//
	// 	.slat__optionbuffer{ // this is a shim for when slat__tools aren't present
	// 		width: ms(1);
	// 		margin-left: ms(1);
	// 	}
	// }
	//
	// .slat__options{
	// 	display: flex;
	// 	flex: 1;
	// 	flex-direction: column;
	// 	overflow: visible;
	//
	// 	@media #{$large-up}{
	// 		flex-direction: row;
	// 	}
	// }
	//
	// .slat__options--abbr{
	// 	overflow: hidden;
	//
	// 	.slat__optiontext{
	// 		text-overflow: ellipsis;
	// 		white-space: nowrap;
	// 		overflow: hidden;
	// 	}
	// }
	//
	// .slat__options--flex-large-up{ // IE11 gets real bent out of shape about flex-direction: column and flex: 1 on small screens ^^^
	// 	display: block;
	//
	// 	@media #{$large-up}{
	// 		display: flex;
	// 	}
	// }
	//
	// .slat__option{
	// 	display: flex;
	// 	flex: 1 auto;
	// 	margin-bottom: ms(-2);
	//
	// 	@media #{$large-up}{
	// 		width: 25%;
	// 		margin-bottom: 0;
	// 	}
	// }
	//
	// .slat__option--dropdown{
	// 	position: relative;
	//
	// 	.slat__optionicon{
	// 		display: inline-block;
	// 		margin-left: ms(0);
	// 		vertical-align: middle;
	// 	}
	// }
	//
	// .slat__option--dropdown[aria-expanded='true']{
	//
	// 	.slat__optionicon{
	// 		transform: rotate(180deg);
	// 	}
	// }
	//
	// .slat__option--primary{
	//
	// 	@media #{$large-up}{
	// 		width: 50%;
	// 	}
	// }
	//
	// .slat__option--secondary{
	// 	order: 1;
	// 	margin-top: ms(1);
	//
	// 	@media #{$large-up}{
	// 		order: 0;
	// 		margin-top: 0;
	// 	}
	// }
	//
	// .slat__option--left{
	// 	flex: 1;
	// 	width: auto;
	// }
	//
	// .slat__option--right{
	// 	flex: 0 auto;
	// 	width: auto;
	//
	// 	.slat__tools{
	// 		min-width: ms(9); // right side of slat needs to be pushed out to align middle icons
	// 		margin-left: 0;
	//
	// 		@media #{$large-up}{
	// 			margin-left: ms(2);
	// 		}
	// 	}
	// }
	//
	// .slat__optiontext{
	// 	color: gc(dl-lightgrey);
	// 	font-size: ms(0);
	// }
	//
	// svg + .slat__optiontext{
	// 	margin-left: ms(0);
	// }

	/**
	 *
	 *slat modifiers:
	 *
	 *`.slat--spacious`  adds exta padding
 	 *
	 *`.slat--compact`  tightens up padding
	 *
	 *`.slat--v-compact`  tightens vertical padding
	 *
	 *`.slat--h-compact`  tightens horizontal padding
	 *
	 *`.slat--invisible`  removes bottom border between slats
	 *
	 *`.slat--visible`  ensure bottom border between slats is visible even on the last slat
	 *
	 *`.slat--visible-top`  add top border to slat, even on the last slat
	 *
	 *`.slat--visible-top-medium-down`  add top border to slat, even on the last slat, for medium viewports and down
	 *
	 *`.slat--h-collapse` & `.slat--v-collapse`  Removes horizontal and vertical padding respectively
	 *
	 *`.slat--base` removes all padding and borders. Use `.slat--base` when you just want a flexbox
	 *
	 *`.slat--verticalcenter` aligns text, vertically centered, alongside icons.
	 *
	 *`.slat--flex-medium-up` display block on small screens.
	 *
	 *`.slat--flex-large-up` display block on small and medium screens.
	 *
	 *`.slat--footer` adds a top border to a slat at the bottom of a page when there isn't a slat before it.
	 */

	.slat--spacious{
		padding: ms(2);
	}

	.slat--h-spacious{
		padding-right: ms(4);
		padding-left: ms(4);
	}

	.slat--v-spacious{
		padding-top: ms(4);
		padding-bottom: ms(4);
	}

	.slat--compact{
		padding: ms(0) 0;
	}

	.slat--invisible{
		border: 0;
	}

	.slat.slat--visible{ // push up specificty to beast :last-child
		border-bottom: 1px solid gc(dl-ash);
	}

	.slat.slat--visible-top{
		border-top: 1px solid gc(dl-ash);
		border-bottom: 0;
	}

	.slat.slat--visible-top-medium-down{

		@media #{$large-up}{
			border: 0;
		}
	}

	.slat--h-collapse{
		padding-right: 0;
		padding-left: 0;

		// .slat__icon{
		// 	margin-right: ms(-1);
		// }
		//
		// .slat__tools{
		// 	margin-left: ms(-1);
		// }
	}

	.slat--h-collapse-medium-down{
		padding-right: 0;
		padding-left: 0;

		// .slat__icon{
		// 	margin-right: ms(-1);
		// }
		//
		// .slat__tools{
		// 	margin-left: ms(-1);
		// }

		@media #{$large-up}{
			padding-right: $slat-horz-padding;
			padding-left: $slat-horz-padding;
		}
	}

	.slat--h-compact{
		padding-right: ms(0);
		padding-left: ms(0);
	}

	.slat--v-collapse{
		padding-top: 0;
		padding-bottom: 0;

	}

	.slat--v-compact{
		padding-top: ms(0);
		padding-bottom: ms(0);
	}

	.slat--base{
		padding: 0;
		border: 0;
	}

	.slat--verticalcenter{

		.slat__content{
			vertical-align: middle;
		}
	}

	.slat--footer{ //adds a top border to a slat at the bottom of a page when there isn't a slat before it
		border-top: 1px solid gc(dl-ash);
	}

	.slat--flex-medium-up{
		display: block;

		.slat__content{
			display: block;
		}

		.slat__tools{
			margin-left: 0;
		}

		@media #{$medium-up}{
			display: flex;

			.slat__content{
				display: inline-block;
			}

			.slat__tools{
				margin-left: ms(1);
			}
		}
	}

	.slat--flex-large-up{
		display: block;

		.slat__content{
			display: block;
		}

		.slat__tools{
			margin-left: 0;
		}

		@media #{$large-up}{
			display: flex;

			.slat__content{
				display: inline-block;
			}

			.slat__tools{
				margin-left: ms(1);
			}
		}
	}

	.slat--flex-xlarge-up{
		display: block;

		.slat__content{
			display: block;
		}

		.slat__tools{
			margin-left: 0;
		}

		@media #{$xlarge-up}{
			display: flex;

			.slat__content{
				display: inline-block;
			}

			.slat__tools{
				margin-left: ms(1);
			}
		}
	}


	/**
	 *
	 *has-spinner state:
	 *
	 *`.slat.has-spinner`
	 *
	 *Shows that a slat is loading something.
	 *
	 * 	@example
	 *		div.card
 	 *			div.slat.has-spinner
 	 *				div.spinner
	 *				div.slat__icon
	 *					 svg.icon.icon--small
	 *						use(xlink:href='images/sprites.svg#icon-folder--color')
	 *				div.slat__content
	 *					div.slat__title They see me spinnin', they a hatin'
	 *				div.slat__tools
	 *					 svg.icon.icon--tiny.icon--hover
	 *						use(xlink:href='images/sprites.svg#icon-circlecross')
	 *			div.slat.has-spinner
 	 *				div.spinner
	 *				div.slat__content
	 *					div.slat__title Lets keep it simple aye?

	 *
	 */

	.slat.has-spinner{
		padding-left: ms(0) + 1rem;
		transition: padding map-get($transition-duration, fast) $ease-out-quad;

		.spinner{
			@include spinner-small;
			margin-right: ms(-2);
			margin-left: -1rem;
		}
	}

	/**
	 *'slat__description-container'
	 *
	 *Divide slat tile and bottom tags with some spaces
	 *
	 *	@example
	 *		div.slat
	 *			div.slat__content
	 *				div.slat__title Lets keep it simple aye?
	 *				div.slat__description-container
	 *					div.slat__text Slat text goes here!
	 *
	 */

	.slat__description-container{
		padding: ms(-2) 0;

		.slat__text{
			padding-bottom: .2rem;
		}

		.slat__text:last-child{
			padding-bottom: 0;
		}
	}

	/**
	 *'slat.slat--spacious'
	 *
	 *Slats looks spacious with its family
	 *
	 *	@example
	 *		div.slat.slat--spacious
	 *			Content goes here!
	 *
	 */

	.slat--spacious{
		padding: ms(3) 0;
	}

	/**
	 * 'Hide empty slat text from css'
	 */

	.slat__text:empty{
		display: none;
	}
}
