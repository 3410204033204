@include exports('components/footers'){
	/***
	 * Footers:
	 */

	/**
	 *
	 *Basic (only) footer:
	 *
	 *`.footer` > `.footer__text`
	 *
	 *App doesn't have a footer once logged in. These styles are used for login pages,
	 * to adopt the styles of the little disclaimer footer in registration. We need a class
	 * to call these guys beause their styles change for whitelabel, etc., as well.
	 *
	 * 	@example
	 *		div.card.u-text__center
	 *			h3 A header!
	 *			p Some words!
 	 *			div.actions
 	 *				div.textbutton Here is a link
 	 *				div.textbutton Also you can click this
	 *			div.footer
	 *				div.footer__text This is the part that is the actual footer. Enter your disclaimer here.
	 *
	 */

	.footer{
		padding: ms(2) ms(1);

		a{
			color: gc(dl-mediumgrey);
		}

		a:hover,
		a:focus,
		a:active{
			color: gc(dl-superblack);
		}
	}

	.footer__text{
		font-size: ms(0);
		text-align: center;
	}
}
