@include exports('vendor/react-tooltip'){

	.__react_component_tooltip::before,
	.__react_component_tooltip::after,
	.__react_component_tooltip.place-top::before,
	.__react_component_tooltip.place-top::after,
	.__react_component_tooltip.place-right::before,
	.__react_component_tooltip.place-right::after,
	.__react_component_tooltip.place-bottom::before,
	.__react_component_tooltip.place-bottom::after,
	.__react_component_tooltip.place-left::before,
	.__react_component_tooltip.place-left::after{
		display: none;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
	}
}
