// Easy to steal form animate.css if needed.
// http://daneden.github.io/animate.css/
@include exports('helpers/animations'){
	//
	//Pulse
	//

	@keyframes pulse{
		from,
		to{
			transform: scale(1);
			opacity: 1;
		}

		40% {
			transform: scale(.98);
			opacity: .2;
	  	}
	}

	//
	//Spin
	//

	@keyframes spin{
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(-360deg);
	  	}
	}



	//
	//Fade in-down
	//

	@keyframes fadeInDown{
		0% {
			transform: translateY(-20px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}

	}






	//
	//Fade out down
	//

	@keyframes fadeOutDown{
		0% {
			opacity: 1;
		}

		100% {
			transform: translateY(50px);
			opacity: 0;
		}
	}





	//
	//Fade in right
	//


	@keyframes fadeInRight{
		0% {
			transform: translateX(-20px);
			opacity: 0;
		}

		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}





	//
	//Fade in left
	//


	@keyframes fadeInLeft{
		0% {
			transform: translateX(20px);
			opacity: 0;
		}

		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}



	//
	// fade in up
	//
	@keyframes fadeInUp{
		0% {
			transform: translateY(20px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}




	//
	// fade from ruby to grey bg
	//

	@keyframes errorBg{

		0% {
			background: gc(dl-ruby);
			// box-shadow: 0 1px 1px gc(dl-ruby);
		}


		100% {
			background: rgba(gc(dl-ruby), .1);
			// box-shadow: 0 0 0 transparent;
		}
	}

}



// animation helpers that we will try to phase out with utility classes

@mixin animation-pulse{
	animation: pulse 2s $ease-in-out-cubic infinite;
}

@mixin animation-spin {
	animation: spin .7s linear;
}

@mixin animation-fadeInDown {
	animation: fadeInDown map_get($transition-duration, default) $ease-out-cubic;
}

@mixin animation-fadeOutDown {
	animation: fadeInDown map_get($transition-duration, default) $ease-in-cubic;
}

@mixin animation-fadeInRight {
	animation: fadeInRight map_get($transition-duration, default) $ease-out-cubic;
}

@mixin animation-fadeInLeft {
	animation: fadeInLeft map_get($transition-duration, default) $ease-out-cubic;
}

@mixin animation-fadeInUp{
	animation: fadeInUp  map_get($transition-duration, default) $ease-out-cubic;
}

@mixin animation-errorBg {
	animation: errorBg 1s $ease-out-cubic;
}


